import { compact } from "lodash"

import { isNoteURL } from "lib/ample-util/note-url"
import { normalizeTagText, TAG_TEXT_DELIMITER, TAGS_DELIMITER } from "lib/ample-util/tags"

// --------------------------------------------------------------------------
export const OMIT_FILTER_TAGS_CHARACTER = "~";

// --------------------------------------------------------------------------
// Separates a string into a set of tags and the remaining non-tag text, e.g.:
//
//    extractTags("blah/something/and then") => {
//      applyFilterTags: false,
//      tags: [ "blah/something" ],
//      text: "and then"
//    }
//
export default function extractTags(text) {
  if (isNoteURL(text)) return { applyFilterTags: true, tags: [], text };

  const tags = [];

  const omitFilterTags = text.startsWith(OMIT_FILTER_TAGS_CHARACTER);
  if (omitFilterTags) text = text.substring(OMIT_FILTER_TAGS_CHARACTER.length);

  const textSegments = text.split(TAG_TEXT_DELIMITER).map(textSegment => textSegment.trim());
  if (textSegments.length > 1) {
    text = textSegments[textSegments.length - 1];

    const tagsText = compact(textSegments.slice(0, -1)).join(TAG_TEXT_DELIMITER);
    if (tagsText) {
      const haveTagByTagText = {};
      tagsText.split(TAGS_DELIMITER).forEach(tagText => {
        tagText = normalizeTagText(tagText.replace(/^\/+|\/+$/g, "").trim());
        if (tagText && !haveTagByTagText[tagText]) {
          tags.push(tagText);
          haveTagByTagText[tagText] = true;
        }
      });
    }
  }

  return { applyFilterTags: !omitFilterTags, tags, text };
}
