import { Fragment, Node } from "prosemirror-model"

import markdownParser from "lib/ample-editor/lib/markdown-parser"
import markdownSerializer from "lib/ample-editor/lib/markdown-serializer"
import { parsePluginMarkdownContent } from "lib/ample-editor/lib/plugin-content-util"
import { schema } from "lib/ample-editor/schema"
import { noteUUIDFromNoteParams } from "lib/ample-util/note-uuid"
import {
  BLANK_TASK_CONTENT,
  calculateTaskValue,
  checkListItemFromTask,
  durationFromSeconds,
  flagsObjectFromFlagsString,
  flagsStringFromFlagsObject,
  REPEAT_TYPE,
  repeatTypeFromRepeat,
  secondsFromDuration,
  TASK_ATTRIBUTE_DEFAULTS,
} from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
// "plugin" here is Amplenote plugins, not ProseMirror plugins, while "plugin task" is
// a limited subset of task data that plugins can provide - note that the names of
// incoming attributes are based on user-facing names, not the internal names of
// the attributes
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
export function pluginTaskFromTask(task) {
  const { content: taskContent } = checkListItemFromTask(task)
  const noteDocument = Node.fromJSON(schema, { content: taskContent, type: "doc" });
  const content = markdownSerializer.serialize(noteDocument);

  const { completedAt, dismissedAt, due, duration, flags, repeat, startAt, uuid } = task;

  const { important, urgent } = flagsObjectFromFlagsString(flags);

  const pluginTask = {
    content,
    hideUntil: startAt || TASK_ATTRIBUTE_DEFAULTS["startAt"],
    important,
    noteUUID: noteUUIDFromNoteParams(task),
    score: calculateTaskValue(task),
    startAt: due || TASK_ATTRIBUTE_DEFAULTS["due"],
    urgent,
    uuid,
  };

  const durationSeconds = due && duration ? secondsFromDuration(duration) : null;
  if (durationSeconds) {
    pluginTask.endAt = due + durationSeconds;
  }

  // We only push RRULEs through, as they are a standard vs the non-standard relative repeat rules we implement
  if (repeatTypeFromRepeat(repeat, { allowEmptyRepeat: false }) === REPEAT_TYPE.FIXED) {
    pluginTask.repeat = repeat;
  }

  if (completedAt) pluginTask.completedAt = completedAt;
  if (dismissedAt) pluginTask.dismissedAt = dismissedAt;

  return pluginTask;
}

// --------------------------------------------------------------------------
function validCheckListItemContentFromMarkdown(markdown) {
  const doc = parsePluginMarkdownContent(markdown);

  try {
    // Ensure that the content is actually valid in a check-list-item. This will throw if it isn't, with a very
    // descriptive error message.
    markdownParser.schema.nodes.check_list_item.createChecked({}, doc.content);
  } catch (error) {
    if (error instanceof RangeError && doc.content.childCount > 1) {
      // Check list items can only contain a single paragraph or code-block, but we want to give people a break and
      // let them provide multiple lines of text that we can convert to a single paragraph by using hard breaks.
      let newParagraphContent = Fragment.empty;
      for (let i = 0; i < doc.content.childCount; i++) {
        const childNode = doc.content.child(i);
        const { type } = childNode;

        if (type !== type.schema.nodes.paragraph) {
          throw error;
        }

        if (newParagraphContent.size > 0) {
          newParagraphContent = newParagraphContent.addToEnd(type.schema.nodes.hard_break.create());
        }
        newParagraphContent = newParagraphContent.append(childNode.content);
      }

      const newContent = Fragment.from(doc.type.schema.nodes.paragraph.createAndFill(null, newParagraphContent));
      markdownParser.schema.nodes.check_list_item.createChecked({}, newContent);
      return newContent.toJSON();
    }


    throw error;
  }

  return doc.content.toJSON();
}

// --------------------------------------------------------------------------
export function validCheckListItemFromPluginTask(pluginTask, uuid) {
  const {
    content: markdownContent,
    hideUntil,
    important,
    startAt,
    urgent,
  } = pluginTask;

  let content;
  if (markdownContent) {
    content = validCheckListItemContentFromMarkdown(markdownContent);
  } else {
    content = BLANK_TASK_CONTENT;
  }

  const flags = flagsStringFromFlagsObject({ important, urgent });

  return {
    attrs: {
      createdAt: Math.floor(Date.now() / 1000),
      due: (startAt ? parseInt(startAt, 10) : null) || TASK_ATTRIBUTE_DEFAULTS["due"],
      flags: flags ? flags : TASK_ATTRIBUTE_DEFAULTS["flags"],
      startAt: (hideUntil ? parseInt(hideUntil, 10) : null) || TASK_ATTRIBUTE_DEFAULTS["startAt"],
      uuid,
    },
    content,
    type: "check_list_item",
  };
}

// --------------------------------------------------------------------------
export function validUpdatesFromPluginTaskUpdates(task, updates) {
  const validUpdates = {};

  if ("completedAt" in updates) {
    const { completedAt } = updates;
    validUpdates.completedAt = completedAt ? parseInt(completedAt, 10) : TASK_ATTRIBUTE_DEFAULTS["completedAt"];
  }

  if ("content" in updates) {
    const { content: markdownContent } = updates;

    if (markdownContent) {
      validUpdates.content = validCheckListItemContentFromMarkdown(markdownContent);
    } else {
      validUpdates.content = BLANK_TASK_CONTENT;
    }
  }

  if ("dismissedAt" in updates) {
    const { dismissedAt } = updates;
    validUpdates.dismissedAt = dismissedAt ? parseInt(dismissedAt, 10) : TASK_ATTRIBUTE_DEFAULTS["dismissedAt"];
  }

  if ("hideUntil" in updates) {
    const { hideUntil } = updates;
    validUpdates.startAt = hideUntil ? parseInt(hideUntil, 10) : TASK_ATTRIBUTE_DEFAULTS["startAt"];
  }

  if ("important" in updates || "urgent" in updates) {
    const { important, urgent } = updates;
    const flags = flagsStringFromFlagsObject({ important, urgent });
    validUpdates.flags = flags ? flags : TASK_ATTRIBUTE_DEFAULTS["flags"];
  }

  if ("noteUUID" in updates) {
    const { noteUUID } = updates;
    validUpdates.noteUUID = noteUUID;
  }

  if ("score" in updates) {
    const { score } = updates;
    const valueWithoutPoints = calculateTaskValue({ ...task, points: 0 });
    validUpdates.points = parseInt(score, 10) - valueWithoutPoints;
  }

  if ("startAt" in updates) {
    const { startAt } = updates;
    validUpdates.due = startAt ? parseInt(startAt, 10) : TASK_ATTRIBUTE_DEFAULTS["due"];
  }

  // Must be after check for "startAt"
  if ("endAt" in updates) {
    const endAt = parseInt(updates.endAt, 10);

    const due = validUpdates.due || task.due;
    if (!due) throw new Error("can't set endAt for task that isn't scheduled");
    if (endAt <= due) throw new Error("endAt must be after startAt");

    validUpdates.duration = durationFromSeconds(endAt - due);
  }

  return validUpdates;
}
