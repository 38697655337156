import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { IconButton } from "@rmwc/icon-button"

import EDITOR_TAB from "lib/ample-editor/lib/editor-tab"

// --------------------------------------------------------------------------
const CONFIG_BY_TAB = {
  completed: { icon: "check_box", name: "Completed" },
  hidden: { icon: "visibility_off", name: "Hidden" },
  references: { icon: "open_in_browser", name: "Backlinks" },
};

// --------------------------------------------------------------------------
function Tab({ count, isCurrentTab, onTabClick, tab }) {
  const { icon, name } = CONFIG_BY_TAB[tab];

  const onClick = useCallback(
    event => {
      // Prevent triggering of tab-bar click
      event.stopPropagation();

      onTabClick(tab);
    },
    [ onTabClick, tab ]
  );

  return (
    <div className={ `tab${ isCurrentTab ? " current" : "" }` } onClick={ onClick }>
      <i className="material-icons">{ icon }</i>
      { name }{ count ? <span className="count">{ count }</span> : null }
    </div>
  );
}

// --------------------------------------------------------------------------
export default function TabBar(props) {
  const { currentTab, expanded, hiddenTasksCount, referencingNotesCount, setCurrentTab, setExpanded } = props;

  const onClick = useCallback(
    () => {
      setExpanded(!expanded);
    },
    [ expanded, setExpanded ]
  );

  const onTabClick = useCallback(
    tab => {
      if (tab !== currentTab) setCurrentTab(tab);
      if (!expanded) setExpanded(true);
    },
    [ currentTab, expanded, setCurrentTab, setExpanded ]
  );

  return (
    <div className="tab-bar" onClick={ onClick }>
      <div className="tab-bar-inner">
        <Tab
          count={ hiddenTasksCount }
          isCurrentTab={ currentTab === EDITOR_TAB.HIDDEN }
          tab={ EDITOR_TAB.HIDDEN }
          onTabClick={ onTabClick }
        />
        <Tab
          isCurrentTab={ currentTab === EDITOR_TAB.COMPLETED }
          tab={ EDITOR_TAB.COMPLETED }
          onTabClick={ onTabClick }
        />
        <Tab
          count={ referencingNotesCount }
          isCurrentTab={ currentTab === EDITOR_TAB.REFERENCES }
          tab={ EDITOR_TAB.REFERENCES }
          onTabClick={ onTabClick }
        />
      </div>
      <div className="expander-container">
        <IconButton className="expander" icon={ expanded ? "keyboard_arrow_up" : "keyboard_arrow_down" } />
      </div>
    </div>
  );
}

TabBar.propTypes = {
  currentTab: PropTypes.oneOf(Object.values(EDITOR_TAB)),
  expanded: PropTypes.bool,
  hiddenTasksCount: PropTypes.number,
  referencingNotesCount: PropTypes.number,
  setCurrentTab: PropTypes.func.isRequired,
  setExpanded: PropTypes.func.isRequired,
};
