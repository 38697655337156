import { deleteSelection } from "prosemirror-commands"
import { GapCursor } from "prosemirror-gapcursor"
import { redo, undo } from "prosemirror-history"
import { NodeRange } from "prosemirror-model"
import { Selection, TextSelection } from "prosemirror-state"
import { liftTarget, findWrapping, TransformError } from "prosemirror-transform"

import { DEFAULT_ATTRIBUTES_BY_NODE_NAME } from "lib/ample-util/default-node-attributes"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"

// --------------------------------------------------------------------------
// Based on prosemirror-command's setBlockType
export function buildSetBlockType(nodeType, attrs, { liftLinkNodes } = {}) {
  return function(state, dispatch) {
    const { selection: { from, $to, empty } } = state;

    // If the trailing part of the selection is at the start of a top-level block, it's likely the user doesn't realize
    // that, because the browser doesn't render the cursor there (e.g. if the user was at the start of one block and
    // pressed shift+down to select the entire block).
    const atNodeStart = !empty && $to.pos === $to.start(1);
    const to = atNodeStart ? state.selection.to - 1 : state.selection.to;

    const $replacePositions = [];
    state.doc.nodesBetween(from, to, (node, pos) => {
      // if (applicable) return false;
      if (!node.isTextblock) return;

      if (node.type === nodeType) {
        // When a node is already the target type, we only care about the attributes supplied in `attrs` matching - there
        // may be other attributes that don't match that we haven't specified in attrs, in which case we don't want to
        // change the node.
        if (attrs && node.hasMarkup(nodeType, { ...node.attrs, ...attrs })) return;

        $replacePositions.push(state.doc.resolve(pos));
      } else {
        const $pos = state.doc.resolve(pos);
        const index = $pos.index();
        if ($pos.parent.canReplaceWith(index, index + 1, nodeType)) {
          $replacePositions.push($pos);
        }
      }
    });

    if ($replacePositions.length === 0) return false;

    if (dispatch) {
      const transform = state.tr;

      if (liftLinkNodes) {
        const linkNodes = [];
        transform.doc.nodesBetween(from, to, (node, pos) => {
          if (node.type.name === "link") linkNodes.push({ node, pos });
        });
        linkNodes.reverse().forEach(({ node, pos }) => {
          transform.insertText(node.textContent, pos, pos + node.nodeSize);
        });
      }

      $replacePositions.reverse().forEach($replacePos => {
        transform.setBlockType(
          transform.mapping.map($replacePos.pos),
          transform.mapping.map($replacePos.pos + $replacePos.nodeAfter.nodeSize),
          nodeType,
          attrs
        );
      });
      transform.scrollIntoView();
      dispatch(transform);
    }
    return true;
  }
}

// --------------------------------------------------------------------------
export function clearActiveMarks(state, dispatch) {
  const { selection: { $from, empty, from, to }, storedMarks } = state;

  let marks = [];
  if (empty) {
    marks = storedMarks || $from.marks();
  } else {
    state.doc.nodesBetween(from, to, node => {
      marks = marks.concat(node.marks);
    });
  }
  if (marks.length === 0) return false;

  if (dispatch) dispatch(state.tr.removeMark(from, to, null).setStoredMarks([]));
  return true;
}

// --------------------------------------------------------------------------
export function clearStoredMarksAtStart(state, dispatch) {
  const { doc, selection } = state;

  if (!Selection.atStart(doc).eq(selection)) return false;

  if (dispatch) dispatch(state.tr.setStoredMarks([]));
  return true;
}

// --------------------------------------------------------------------------
// Unless we focus editor, deleting a range that falls on node boundaries will appear to lose focus since
// PM can't figure out where to put cursor after deleting range
export const deleteSelectionWithRetainEditorFocus = (state, dispatch) => {
  return deleteSelection(state, wrapDispatchWithRetainEditorFocus(dispatch));
}

// --------------------------------------------------------------------------
// This will exit _any_ mark, and will only exit it if there is nothing after it to exit to (i.e. the default behavior
// for pressing right arrow would do nothing).
export function exitLastMark(state, dispatch) {
  const { doc, selection: { $from } } = state;

  // Should not have another node after the top-level ancestor (if there is, we can just move into it normally)
  if ($from.index(0) < doc.childCount - 1) return false;

  // When the cursor is at the end of the mark-ed content, nodeAt will resolve to the node _after_
  // the mark (or null if the mark is at the end of the document).
  let $markFrom = $from;
  let textNode = doc.nodeAt($markFrom.pos);

  if (!textNode) {
    $markFrom = doc.resolve($from.pos - 1);
    textNode = doc.nodeAt($markFrom.pos);
  }
  if (!textNode || !textNode.isText) return false;

  if (textNode.marks.length === 0 && (!state.storedMarks || state.storedMarks.length === 0)) return false;

  // Should be at the end of the mark
  if ($from.pos !== $markFrom.end()) return false;

  // Should be at the end of the node containing the mark
  if ($from.pos !== $markFrom.end($markFrom.depth)) return false;

  if (dispatch) {
    const transform = state.tr;
    textNode.marks.forEach(mark => { transform.removeStoredMark(mark); });
    transform.insertText(" ");
    transform.scrollIntoView();

    dispatch(transform);
  }

  return true;
}

// --------------------------------------------------------------------------
export function insertHardBreak(hardBreakNodeType) {
  return function(state, dispatch) {
    if (state.selection.$head.parent && state.selection.$head.parent.type.spec.code) return false; // Handled by CodeMirror
    if (state.selection instanceof GapCursor && (state.selection.$head.pos === state.doc.content.size || state.selection.$head.pos === 0)) {
      const transform = state.tr;

      // Avoid creating a pargraph with a hard break, which equates to two lines, when user presses enter from GapCursor
      transform.insert(state.selection.head, state.schema.nodes.paragraph.createAndFill());
      transform.setSelection(TextSelection.near(transform.doc.resolve(state.selection.head)));
      dispatch(transform.scrollIntoView());
    } else {
      dispatch(state.tr.replaceSelectionWith(hardBreakNodeType.create()).scrollIntoView());
    }
    return true;
  }
}

// --------------------------------------------------------------------------
export function hasBlock(state, type, attrs) {
  const { doc, selection: { from, to } } = state;

  let hasType = false;

  doc.nodesBetween(from, to, node => {
    if (hasType) return false;

    if (node.type !== type) return;

    // Note that the node can have more attributes than we are looking for - we only care about the attributes that
    // were explicitly provided
    if (!attrs || !Object.keys(attrs).find(key => attrs[key] !== node.attrs[key])) {
      hasType = true;
      return false;
    }
  });

  return hasType;
}

// --------------------------------------------------------------------------
// Like lift from prosemirror-transform, but will also handle lifting multiple selected nodes up a depth and can
// restrict the lifting to only certain node types (i.e. if you have multiple nodes of one type nested in a single
// node of another type and both types can be lifted).
export function liftEach(nodeType) {
  return function(state, dispatch) {
    const { $from, $to } = state.selection;

    const range = $from.blockRange($to);
    if (!range) return false;

    let target;
    try {
      target = liftTarget(range);
    } catch (_error) {
      // `Called contentMatchAt on a node with invalid content`
      return false;
    }
    if (typeof(target) !== "undefined" && target !== null && range.parent.type === nodeType) {
      // This is the original case lift handles
      if (dispatch) dispatch(state.tr.lift(range, target).scrollIntoView());
      return true;
    }

    // If we're calling this with a list item type and can't manage to lift the current node, it's possible we're deep
    // into a descendant of list item node, in which case we just want to lift the list item node ancestor
    if (nodeType.spec.isListItem) {
      const listItemRange = $from.blockRange($to, node => node.type.spec.isListItem);
      if (listItemRange && listItemRange.parent.type.spec.isListItem) {
        const listItemTarget = liftTarget(listItemRange);
        if (typeof(listItemTarget) !== "undefined" && listItemTarget !== null) {
          if (dispatch) dispatch(state.tr.lift(listItemRange, listItemTarget).scrollIntoView());
          return true;
        }
      }
    }

    const subTargets = [];

    const parentOffset = range.$from.start(range.depth);
    range.parent.nodesBetween(range.start - parentOffset, range.end - parentOffset, (node, pos, parent) => {
      const $pos = state.doc.resolve(parentOffset + pos + 1);

      if (parent.type !== nodeType) return true;

      const subRange = $pos.blockRange(state.doc.resolve(parentOffset + pos + node.nodeSize));
      const subTarget = liftTarget(subRange);

      if (typeof(subTarget) !== "undefined" && subTarget !== null) {
        subTargets.push({ subRange, subTarget });
        return false;
      }
    });

    if (subTargets.length === 0) return false;

    if (dispatch) {
      const transform = state.tr;

      subTargets.forEach(({ subRange, subTarget }) => {
        subRange = new NodeRange(
          transform.doc.resolve(transform.mapping.map(subRange.$from.pos)),
          transform.doc.resolve(transform.mapping.map(subRange.$to.pos)),
          subRange.depth
        );

        transform.lift(subRange, subTarget);
      });

      transform.scrollIntoView();

      dispatch(transform);
    }

    return true;
  }
}

// --------------------------------------------------------------------------
// Copied from lift in prosemirror-transform but adds a restriction that it will only lift a certain type, and will
// lift that type even if it's not the immediate block parent of the selection
export function liftFrom(nodeType) {
  return function(state, dispatch) {
    const { $from, $to } = state.selection;

    const range = $from.blockRange($to);
    if (!range) return false;

    let target;
    try {
      target = liftTarget(range);
    } catch (_error) {
      // Can throw `Error: Called contentMatchAt on a node with invalid content`
      return false;
    }

    if (typeof(target) !== "undefined" && target !== null && range.parent.type === nodeType) {
      // This is the original case lift handles
      if (dispatch) dispatch(state.tr.lift(range, target).scrollIntoView());
      return true;
    }

    for (let depth = $from.depth - 1; depth > 0; depth--) {
      const node = $from.node(depth);
      if (node.type !== nodeType) continue;

      const start = $from.start(depth);
      const end = $from.end(depth);

      const ancestorRange = state.doc.resolve(start).blockRange(state.doc.resolve(end));
      const ancestorTarget = liftTarget(ancestorRange);

      if (typeof(ancestorTarget) !== "undefined" && ancestorTarget !== null) {
        if (dispatch) dispatch(state.tr.lift(ancestorRange, ancestorTarget).scrollIntoView());
        return true;
      }
    }

    return false;
  }
}

// --------------------------------------------------------------------------
// When pressing backspace (the quintessential "join backward" behavior), ProseMirror won't join into the child
// content of an image, but we would like to support that as much as we can, rather than deleting the entire image
// and caption as if it were a single indivisible unit.
export function joinBackwardToImageCaption(state, dispatch, view) {
  const { schema, selection: { $cursor } } = state;
  if (!$cursor || $cursor.parent.type.spec.isolating) return false;

  // There are two cases we want to handle here:
  //  1. The cursor is at the beginning of a paragraph after the paragraph containing the image with a caption
  //  2. The cursor is at the beginning of a text node after the image node, in the same containing paragraph
  if (view ? view.endOfTextblock("backward", state) : $cursor.parentOffset === 0) {
    // Code finding `$cut` here is based on `findCutBefore` in prosemirror-commands
    let $cut = null;
    for (let depth = $cursor.depth - 1; depth >= 0; depth--) {
      if ($cursor.index(depth) > 0) {
        $cut = $cursor.doc.resolve($cursor.before(depth + 1));
        break;
      }

      if ($cursor.node(depth).type.spec.isolating) break;
    }
    if (!$cut) return false;

    // `image` nodes exist in `paragraph` nodes, so we're looking for a paragraph with the image at the end
    const before = $cut.nodeBefore;
    if (before.type !== schema.nodes.paragraph || !before.lastChild) return false;

    const imageNode = before.lastChild;
    if (imageNode.type !== schema.nodes.image || imageNode.content.size === 0) return false;

    const after = $cut.nodeAfter;
    if (!imageNode.type.validContent(after.type.contentMatch)) return false;

    if (dispatch) {
      const transform = state.tr;

      // Need to move past the end position of the paragraph wrapping the image, the end position of the image, and the
      // end position of the paragraph containing the image's content (caption)
      const insertPos = $cut.pos - 3;

      transform.delete($cut.pos, $cut.pos + after.nodeSize);
      transform.insert(insertPos, after.content);

      const selection = TextSelection.create(transform.doc, insertPos);
      transform.setSelection(selection);

      dispatch(transform.scrollIntoView());
    }

    return true;
  } else if ($cursor.textOffset === 0 && $cursor.nodeBefore) {
    const imageNode = $cursor.nodeBefore;
    if (imageNode.type !== schema.nodes.image || imageNode.content.size === 0) return false;

    const $cut = $cursor;

    // There might not be any more text in the paragraph after the cursor
    const { nodeAfter } = $cursor;
    if (nodeAfter && !imageNode.type.validContent(nodeAfter.type.contentMatch)) return false;

    if (dispatch) {
      const transform = state.tr;

      // Need to move past the end position of the image and the end position of the paragraph containing the
      // image's content (caption)
      const insertPos = $cut.pos - 2;

      if (nodeAfter) {
        transform.delete($cut.pos, $cut.pos + nodeAfter.nodeSize);
        transform.insert(insertPos, nodeAfter);
      }

      const selection = TextSelection.create(transform.doc, insertPos);
      transform.setSelection(selection);

      dispatch(transform.scrollIntoView());
    }

    return true;
  }

  return false;
}

// --------------------------------------------------------------------------
// Inlined from prosemirror-commands
function markApplies(doc, ranges, type) {
  for (let i = 0; i < ranges.length; i++) {
    const { $from, $to } = ranges[i]
    let can = $from.depth === 0 ? doc.type.allowsMarkType(type) : false
    doc.nodesBetween($from.pos, $to.pos, node => {
      if (can) return false
      can = node.inlineContent && node.type.allowsMarkType(type)
    })
    if (can) return true
  }
  return false
}

// --------------------------------------------------------------------------
export function nearestLeafPos($pos, bias) {
  return Selection.near($pos, bias).$head;
}

// --------------------------------------------------------------------------
export function redoWithRetainEditorFocus(state, dispatch) {
  return redo(state, wrapDispatchWithRetainEditorFocus(dispatch));
}

// --------------------------------------------------------------------------
// Since an image caption can only contain a single paragraph, we want to handle enter by splitting it _out_ of the
// caption (or just escaping the caption if there's a subsequent paragraph)
export function splitImageCaption(state, dispatch) {
  const { schema, selection: { $from } } = state;

  let imageNode = null;
  let imageNodeDepth = -1;
  for (let depth = $from.depth; depth > 0; depth--) {
    const node = $from.node(depth);
    if (!node) return false;

    if (node.type === schema.nodes.image) {
      imageNode = node;
      imageNodeDepth = depth;
      break;
    }
  }

  if (!imageNode || $from.depth === imageNodeDepth) return false;

  const transaction = state.tr.deleteSelection();

  const $splitPos = transaction.doc.resolve($from.pos);

  // Need to re-fetch the node in case some content was deleted by the `deleteSelection` call
  imageNode = $splitPos.node(imageNodeDepth);

  const contentRange = new NodeRange(
    $splitPos,
    transaction.doc.resolve($splitPos.end(imageNodeDepth)),
    imageNodeDepth
  );

  const targetDepth = liftTarget(contentRange);
  if (typeof(targetDepth) === "undefined") return false;

  if (dispatch) {
    // Ideally we could just `lift` out the range, since it's a valid liftTarget, but doing so deletes the image
    // node, which appears to be intended behavior as we're lifting out (some of) the last child, so instead we'll
    // delete the remainder and insert it after
    transaction.delete($splitPos.pos, $splitPos.end(imageNodeDepth + 1));

    const insertPos = transaction.mapping.map($from.after(imageNodeDepth));

    if ($splitPos.parentOffset < $splitPos.parent.content.size) {
      // The position passed here is relative to the start of the node
      const { content } = imageNode.cut($splitPos.pos - $splitPos.start(imageNodeDepth));
      transaction.insert(insertPos, content);
    } else {
      // At the end of the caption, we want to insert a new paragraph as otherwise we'll be left in the parent
      // paragraph of the image, inserting text as a sibling of the image.
      transaction.insert(insertPos, schema.nodes.paragraph.createAndFill());
    }

    // Move the selection into the text of the newly inserted paragraph
    transaction.setSelection(TextSelection.near(transaction.doc.resolve(insertPos + 1)));

    dispatch(transaction);
  }

  return true;
}

// --------------------------------------------------------------------------
// Inlined from prosemirror-commands and adjusted to handle removing marks from inline nodes, which otherwise
// doesn't work if the arrangement is <mark><inline-node>text</inline-node></mark> (it does work if the <inline-node> is
// the outer element).
export function toggleMark(markType, attrs) {
  return function(state, dispatch) {
    const { empty, $cursor, ranges } = state.selection

    if ((empty && !$cursor) || !markApplies(state.doc, ranges, markType)) return false;

    if (dispatch) {
      const transaction = state.tr;

      if ($cursor) {
        if (markType.isInSet(state.storedMarks || $cursor.marks())) {
          transaction.removeStoredMark(markType);
        } else {
          transaction.addStoredMark(markType.create(attrs));
        }
      } else {
        let hasMark = false;
        for (let i = 0; !hasMark && i < ranges.length; i++) {
          const { $from, $to } = ranges[i];
          hasMark = state.doc.rangeHasMark($from.pos, $to.pos, markType);
        }

        for (let i = 0; i < ranges.length; i++) {
          const { $from, $to } = ranges[i];

          if (hasMark) {
            const node = $from.node();

            // This isn't perfect behavior, as it will remove the mark from the start of the inline node up to the end
            // position, but it's better than not removing the mark at all. There may be some way to adapt this to target
            // the specific condition where the mark is around the inline node, instead of inside it.
            const from = (node && node.type.spec.inline) ? $from.before() : $from.pos;

            transaction.removeMark(from, $to.pos, markType);
          } else {
            transaction.addMark($from.pos, $to.pos, markType.create(attrs));
          }
        }
        transaction.scrollIntoView();
      }

      dispatch(transaction);
    }

    return true;
  }
}

// --------------------------------------------------------------------------
// Input rules commonly make two changes that we'd really like to be distinct in history:
//  - inserting some character
//  - whatever the inputrule does
// This is undoInputRule from prosemirror-inputrules, with an additional code path to truly undo selected inputrule,
// where undoInputRule would have poor behavior. The problem with undoInputRule's approach is that it adds a _new_
// transform to history, so when you undo it you get back to the replacement inputrules performed. In the past, we've
// not added this transaction to history, but that is also a poor experience, as the history gets confused without the
// transaction, so if you keep undoing it can undo in the wrong positions.
//
// The ideal - for ProseMirror's part - approach would be if we could add multiple transforms to history in a single
// dispatch. Then we could have inputrules with two part undo. Short of support for that in prosemirror-history, it may
// be possible to perform some trickery in here when the first undo happens to adjust the state without adding a new
// history entry, then _also_ handle a subsequent undo here (e.g. by setting `transform.setMeta(plugin, undoable)` in
// the code below with an additional flag so we know when to handle the subsequent undo), but as of 2/2021 it's proven
// elusive to leave the state updated such that the subsequent (real) undo has everything in the correct position.
export function undoInputRuleWithoutHistory(state, dispatch) {
  const { plugins } = state;

  for (let i = 0; i < plugins.length; i++) {
    const plugin = plugins[i];
    if (!plugin.spec.isInputRules) continue;

    const undoable = plugin.getState(state);
    if (!undoable) return false;

    if (!dispatch) return true;

    if (undoable.transform.getMeta(TRANSACTION_META_KEY.COMPLETELY_UNDO_INPUT_RULE)) {
      undo(state, dispatch);
    } else {
      const transform = state.tr;

      const toUndo = undoable.transform;
      for (let j = toUndo.steps.length - 1; j >= 0; j--) {
        transform.step(toUndo.steps[j].invert(toUndo.docs[j]));
      }

      if (undoable.text) {
        const marks = transform.doc.resolve(undoable.from).marks();
        transform.replaceWith(undoable.from, undoable.to, state.schema.text(undoable.text, marks));
      } else {
        transform.delete(undoable.from, undoable.to);
      }

      dispatch(transform);
    }

    return true;
  }

  return false;
}

// --------------------------------------------------------------------------
export function undoWithRetainEditorFocus(state, dispatch) {
  try {
    return undo(state, wrapDispatchWithRetainEditorFocus(dispatch));
  } catch (_error) {
    // Typically `RangeError: Position N is out of range`, in some unknown state
    return false;
  }
}

// --------------------------------------------------------------------------
function wrapDispatchWithRetainEditorFocus(dispatch) {
  if (dispatch) {
    return transform => {
      transform.setMeta(TRANSACTION_META_KEY.RETAIN_EDITOR_FOCUS, true);
      dispatch(transform);
    };
  }

  return dispatch;
}

// --------------------------------------------------------------------------
// Like wrapIn from prosemirror-commands, but will also handle:
//   - wrapping multiple nodes in the given wrapping if the selection spans multiple nodes that can be wrapped
//   - changing already-wrapped nodes to a different type of wrapping list node
//   - wrapping ancestor list-items when the selection is in a descendant node
export function wrapEachIn(nodeType, attrs, $atPos = null) {
  return function(state, dispatch) {
    const { schema } = state;

    const $from = $atPos || state.selection.$from;
    const $to = $atPos || state.selection.$to;

    let range = $from.blockRange($to);
    if (!range) return false;

    const wrapping = findWrapping(range, nodeType, attrs);
    if (wrapping) {
      // This is the original case wrapIn handles
      if (dispatch) dispatch(state.tr.wrap(range, wrapping).scrollIntoView());
      return true;
    }

    // Textblock nodes (e.g. headings) contain inline text nodes directly, so we can't just wrap them but need to
    // convert them to paragraphs first, which can then be used as a child of the desired node type
    if ($from.parent && $from.parent.type.isTextblock && $from.sameParent($to)) {
      let transform = state.tr;
      transform.setNodeMarkup($from.before(), schema.nodes.paragraph, { ...$from.parent.attrs }, $from.parent.marks);

      const innerRange = new NodeRange(
        transform.doc.resolve(transform.mapping.map($from.start())),
        transform.doc.resolve(transform.mapping.map($from.end())),
        $from.depth - 1,
      );

      const innerWrapping = findWrapping(innerRange, nodeType, attrs);
      if (innerWrapping) {
        transform.wrap(innerRange, innerWrapping);
      } else {
        transform = null;
      }

      if (transform) {
        if (dispatch) dispatch(transform.scrollIntoView());
        return true;
      }
    }

    // If we're calling this with a list item type and can't manage to wrap the current node, it's possible we're deep
    // into a descendant of list item node, in which case we just want to swap the list item node
    if (nodeType.spec.isListItem) {
      const listItemRange = $from.blockRange($to, node => node.type.spec.isListItem);
      if (listItemRange) {
        const listItemWrapping = findWrapping(listItemRange, nodeType, attrs);
        if (listItemWrapping) {
          if (dispatch) dispatch(state.tr.wrap(listItemRange, listItemWrapping).scrollIntoView());
          return true;
        }

        // Note that we want to use the range that was adjusted for the list item ancestor in the remainder of this
        // function, as it better captures the intention to change the list item type.
        range = listItemRange;
      }
    }

    // We either have multiple blocks selected - in which case we want to try to wrap each one individually - or we
    // have a block selected that already has a wrapping - in which case we may want to change the wrapping.
    const subWrappings = [];
    const existingWrappings = [];

    const parentOffset = range.$from.start(range.depth);
    range.parent.nodesBetween(range.start - parentOffset, range.end - parentOffset, (node, pos, parent) => {
      const $pos = state.doc.resolve(parentOffset + pos);
      const subRange = $pos.blockRange(state.doc.resolve(parentOffset + pos + node.nodeSize));
      const subWrapping = findWrapping(subRange, nodeType, attrs);
      if (subWrapping) {
        subWrappings.push({ subRange, subWrapping });
        return false;
      }

      // Allow conversion of an existing wrapping of one list item type to another list item type - note that we're
      // looking at the parent node so we can handle selections that fall entirely inside a list item wrapping, in which
      // case `range.parent` will be a list item node, but we won't iterate over it in this function.
      if (nodeType.spec.isListItem && parent.type.spec.isListItem) {
        existingWrappings.push({ node: parent, pos: $pos.pos - 1 });
        return false;
      }
    });

    if (subWrappings.length === 0 && existingWrappings.length === 0) return false;

    if (dispatch) {
      const transform = state.tr;

      subWrappings.forEach(({ subRange, subWrapping }) => {
        subRange = new NodeRange(
          transform.doc.resolve(transform.mapping.map(subRange.$from.pos)),
          transform.doc.resolve(transform.mapping.map(subRange.$to.pos)),
          subRange.depth
        );

        transform.wrap(subRange, subWrapping);
      });

      existingWrappings.forEach(({ node, pos }) => {
        const newAttrs = { ...node.attrs, ...attrs };

        if (node.type !== nodeType) {
          // When changing between types, we don't want to retain the completion-based attributes that could otherwise
          // cause the node to be transformed back into a different type of node
          delete newAttrs.completedAt;
          delete newAttrs.crossedOutAt;
          delete newAttrs.dismissedAt;

          // Translate some different node names between bullet- and check- list items
          const oldNodeTypeDefaultAttributes = DEFAULT_ATTRIBUTES_BY_NODE_NAME[node.type.name];
          const newNodeTypeDefaultAttributes = DEFAULT_ATTRIBUTES_BY_NODE_NAME[nodeType.name];

          if ("due" in oldNodeTypeDefaultAttributes && "scheduledAt" in newNodeTypeDefaultAttributes) {
            // check-list-item => bullet-list-item
            newAttrs.scheduledAt = newAttrs.due;
            delete newAttrs.due;
          } else if ("scheduledAt" in oldNodeTypeDefaultAttributes && "due" in newNodeTypeDefaultAttributes) {
            // bullet-list-item => check-list-item
            newAttrs.due = newAttrs.scheduledAt;
            delete newAttrs.scheduledAt;
          }
        }

        try {
          transform.setNodeMarkup(transform.mapping.map(pos), nodeType, newAttrs, node.marks);
        } catch (error) {
          // This can happen if we're converting a node to a type that isn't allowed in the parent, e.g when changing
          // a bullet-list-item in a blockquote to a check-list-item (which isn't allowed in a blockquote).
          if (!(error instanceof TransformError) && !(error instanceof RangeError)) throw error;
        }
      });

      transform.scrollIntoView();

      dispatch(transform);
    }

    return true;
  }
}
