import { omit, sumBy } from "lodash"
import PropTypes from "prop-types"
import { Node } from "prosemirror-model"
import React, { useCallback, useMemo, useState } from "react"
import { Button } from "@rmwc/button"
import { CircularProgress } from "@rmwc/circular-progress"
import { MenuSurface, MenuSurfaceAnchor } from "@rmwc/menu"
import Tippy from "@tippyjs/react"

import FilterMenuContent from "lib/ample-editor/components/referencing-notes/filter-menu-content"
import ReferencingNote from "lib/ample-editor/components/referencing-notes/referencing-note"
import RenameReferencesSection, {
  FILTER_GROUP_MISMATCHED_REFERENCES,
} from "lib/ample-editor/components/referencing-notes/rename-references-section"
import UnlinkedReferences from "lib/ample-editor/components/referencing-notes/unlinked-references"
import HostAppContext from "lib/ample-editor/contexts/host-app-context"
import NOTE_CONTENT_TYPE from "lib/ample-editor/lib/note-content-type"
import VECTOR_ICON_PATHS from "lib/ample-editor/lib/vector-icon-paths"
import buildMismatchedReferencesByUrl from "lib/ample-editor/util/build-mismatched-references-by-url"
import AMPLENOTE_AREA, { relativeURLFromAmplenoteParams } from "lib/ample-util/amplenote-area"
import extractNodeContent from "lib/ample-util/extract-node-content"
import {
  noteReferencesFromFilterReferences,
  taskMatchesNoteReferences,
} from "lib/ample-util/filter-references"
import { matchesTagFilters, tagFiltersFromFilterTag } from "lib/ample-util/filter-tag"
import { noteParamsFromURL } from "lib/ample-util/note-url"
import { noteUUIDFromNoteParams } from "lib/ample-util/note-uuid"

// --------------------------------------------------------------------------
function filterReferencingNotes(filterParams, referencedNote, referencesByUrl, referencingNotes) {
  const {
    group: filterGroup,
    query: filterQuery,
    references: filterReferences,
    tag: filterTag,
  } = filterParams;

  const { name: referencedNoteName } = referencedNote || {};
  const onlyMismatchedText = filterGroup === FILTER_GROUP_MISMATCHED_REFERENCES && referencedNoteName;

  const lowerCaseFilterQuery = filterQuery ? filterQuery.toLowerCase() : null;

  if (!onlyMismatchedText && !lowerCaseFilterQuery && !filterReferences && !filterTag) {
    return {
      filteredReferencesByUrl: referencesByUrl,
      filteredReferencingNotes: referencingNotes,
    };
  }

  const noteReferences = noteReferencesFromFilterReferences(filterReferences);
  const tagFilters = tagFiltersFromFilterTag(filterTag);

  // When filtering on note references, we only want to show the snippets (which are built from these "references")
  // that contain references to the selected notes
  const filteredReferencesByUrl = {};

  const filteredReferencingNotes = [];

  referencingNotes.forEach(referencingNote => {
    const tagTexts = referencingNote.tags ? referencingNote.tags.map(({ text }) => text) : [];
    if (tagFilters.length > 0 && !matchesTagFilters(tagFilters, { tags: tagTexts })) {
      return;
    }

    const { url } = referencingNote;
    const references = referencesByUrl[url] || [];

    if (noteReferences.length > 0 || lowerCaseFilterQuery || onlyMismatchedText) {
      const noteParams = noteParamsFromURL(url);

      filteredReferencesByUrl[url] = references.filter(({ node, text }) => {
        if (onlyMismatchedText && text === referencedNoteName) return false;
        if (lowerCaseFilterQuery && (!text || !text.toLowerCase().includes(lowerCaseFilterQuery))) {
          return false;
        }
        if (noteReferences.length === 0) return true;

        // We're going to convert each reference section into a fake "task", so we can determine if it matches
        // the note reference using task-based helpers
        if (node instanceof Node) node = node.toJSON();

        const { noteLinks } = extractNodeContent(node);

        const task = {
          ...noteParams,
          references: noteLinks.map(noteLinkParams => noteUUIDFromNoteParams(noteLinkParams)),
        };

        return taskMatchesNoteReferences(noteReferences, task);
      });

      if (filteredReferencesByUrl[url].length === 0) return;
    } else {
      // This allows us to get a count of how many references have been omitted (in filtered out notes)
      filteredReferencesByUrl[url] = references;
    }

    filteredReferencingNotes.push(referencingNote);
  });

  return { filteredReferencesByUrl, filteredReferencingNotes };
}

// --------------------------------------------------------------------------
function GraphViewButton({ referencedNote }) {
  const relativeURL = relativeURLFromAmplenoteParams({
    area: AMPLENOTE_AREA.GRAPH,
    filterParams: { references: noteUUIDFromNoteParams(referencedNote) },
  });

  return (
    <Tippy content="Open referencing notes in Graph mode">
      <Button
        className="graph-view-button"
        href={ relativeURL }
        icon={ (<svg viewBox="0 0 24 24"><path d={ VECTOR_ICON_PATHS["graph-view"] }/></svg>) }
        tag="a"
      />
    </Tippy>
  );
}

// --------------------------------------------------------------------------
function ReferencingNotesContent(props) {
  const {
    collapsedByUrl,
    fetchReferencedNotes,
    getNote,
    getTag,
    initialFilterParams,
    readonly,
    referencedNote,
    referencesByUrl,
    referencingNotes,
    refreshReferencingNotes,
    suggestNotes,
    suggestTags,
    toggleNoteCollapsed,
  } = props;

  const [ filterParams, setFilterParams ] = useState(initialFilterParams || {});

  const clearFilterParams = useCallback(() => { setFilterParams({}); }, []);

  const { filteredReferencesByUrl, filteredReferencingNotes } = useMemo(
    () => filterReferencingNotes(filterParams, referencedNote, referencesByUrl, referencingNotes),
    [ filterParams, referencedNote, referencesByUrl, referencingNotes ]
  );

  const mismatchedReferencesByUrl = useMemo(
    () => buildMismatchedReferencesByUrl(referencedNote, filteredReferencesByUrl),
    [ filteredReferencesByUrl, referencedNote ]
  );

  const omittedNotesCount = referencingNotes.length - filteredReferencingNotes.length;
  const omittedReferencesCount = useMemo(
    () => {
      const referencesCount = sumBy(Object.values(referencesByUrl), references => references.length);
      const filteredReferencesCount = sumBy(Object.values(filteredReferencesByUrl), references => references.length)
      return referencesCount - filteredReferencesCount;
    },
    [ filteredReferencesByUrl, referencesByUrl ]
  );

  let omittedMessage = null;
  if (omittedNotesCount > 0 || omittedReferencesCount > 0) {
    omittedMessage = `view ${ omittedReferencesCount } more reference${ omittedReferencesCount === 1 ? "" : "s" }`;

    if (omittedNotesCount > 0) {
      omittedMessage += ` in ${ omittedNotesCount } note${ omittedNotesCount === 1 ? "" : "s" }`;
    }
  }

  let content;
  let referenceCount = 0;
  if (filteredReferencingNotes.length > 0) {
    content = filteredReferencingNotes.map(({ iconName, name, tags, url }) => {
      const references = filteredReferencesByUrl[url];

      referenceCount += references ? references.length : 0;

      return (
        <ReferencingNote
          collapsed={ collapsedByUrl[url] }
          iconName={ iconName }
          key={ url }
          name={ name }
          references={ references }
          tags={ tags }
          toggleNoteCollapsed={ toggleNoteCollapsed }
          url={ url }
        />
      );
    });
  } else if (referencingNotes.length === 0) {
    content = (
      <div className="no-referencing-notes">
        There are no links to this note.
      </div>
    );
  } else {
    // Just using this for extra blank space, so we don't need an additional class/state
    content = (<div className="no-referencing-notes">&nbsp;</div>);
  }

  return (
    <React.Fragment>
      {
        referencingNotes.length > 0
          ? (
            <ReferencingNotesHeader
              fetchReferencedNotes={ fetchReferencedNotes }
              filterParams={ filterParams }
              getNote={ getNote }
              getTag={ getTag }
              noteCount={ filteredReferencingNotes.length }
              referenceCount={ referenceCount }
              referencedNote={ referencedNote }
              referencesByUrl={ filteredReferencesByUrl }
              referencingNotes={ referencingNotes }
              setFilterParams={ setFilterParams }
              suggestNotes={ suggestNotes }
              suggestTags={ suggestTags }
            />
          )
          : null
      }
      {
        !readonly && (filterParams.group || Object.keys(mismatchedReferencesByUrl).length > 0)
          ? (
            <RenameReferencesSection
              filterParams={ filterParams }
              mismatchedReferencesByUrl={ mismatchedReferencesByUrl }
              refreshReferencingNotes={ refreshReferencingNotes }
              setFilterParams={ setFilterParams}
            />
          )
          : null
      }
      { content }
      {
        omittedMessage
          ? (
            <div className="omitted-referencing-notes">
              <Button className="clear-filters-button body-text" onClick={ clearFilterParams }>Clear filters</Button> to { omittedMessage }.
            </div>
          )
          : null
      }
    </React.Fragment>
  );
}

// --------------------------------------------------------------------------
function ReferencingNotesHeader(props) {
  const {
    fetchReferencedNotes,
    filterParams,
    getNote,
    getTag,
    noteCount,
    referenceCount,
    referencedNote,
    referencesByUrl,
    referencingNotes,
    setFilterParams,
    suggestNotes,
    suggestTags,
  } = props;

  const [ menuOpen, setMenuOpen ] = useState(false);

  const closeMenu = useCallback(() => { setMenuOpen(false); }, []);
  const openMenu = useCallback(() => { setMenuOpen(true); }, []);

  const appliedFiltersCount = useMemo(
    () => {
      return noteReferencesFromFilterReferences(filterParams.references).length +
        tagFiltersFromFilterTag(filterParams.tag).length;
    },
    [ filterParams.references, filterParams.tag ]
  );

  // This is not included in the applied filters count, as it's handled through a separate section
  const groupFilterApplied = !!filterParams.group;

  return (
    <div className="referencing-notes-header">
      <span className="title">
        <span>
          { referenceCount } reference{ referenceCount === 1 ? "" : "s" } in { noteCount } note{ noteCount === 1 ? "" : "s" }
        </span>
        {
          (appliedFiltersCount > 0 || groupFilterApplied)
            ? (<span className="filter-applied-text">(filter applied)</span>)
            : null
        }
      </span>

      <MenuSurfaceAnchor className={ `filter-menu ${ menuOpen ? "open" : "" }` }>
        <MenuSurface anchorCorner="topStart" onClose={ closeMenu } open={ menuOpen }>
          {
            menuOpen
              ? (
                <FilterMenuContent
                  fetchReferencedNotes={ fetchReferencedNotes }
                  filterParams={ filterParams }
                  getNote={ getNote }
                  getTag={ getTag }
                  referencedNote={ referencedNote }
                  referencesByUrl={ referencesByUrl }
                  referencingNotes={ referencingNotes }
                  setFilterParams={ setFilterParams }
                  suggestNotes={ suggestNotes }
                  suggestTags={ suggestTags }
                />
              )
              : null
          }
        </MenuSurface>

        <Button
          className={ `filter-menu-button ${ appliedFiltersCount > 0 ? "filtered" : "" }` }
          icon="filter_alt"
          onClick={ openMenu }
          label={ appliedFiltersCount > 0 ? appliedFiltersCount.toString() : null }
        />
      </MenuSurfaceAnchor>

      <GraphViewButton referencedNote={ referencedNote } />
    </div>
  );
}

// --------------------------------------------------------------------------
export default class ReferencingNotes extends React.PureComponent {
  static contextType = HostAppContext;

  state = {
    collapsedByUrl: {},
    referencesByUrl: {},
  };

  // We don't have a way to fetch metadata on demand for notes and tags to correctly display filters, aside from
  // caching the metadata that is temporarily available when calling `suggest*` functions and in `referencingNotes`
  _cachedNoteByUUID = {};
  _cachedTagByText = {};
  _unmounted = false;

  // --------------------------------------------------------------------------
  componentDidMount() {
    const { referencingNotes } = this.props;

    this._cacheMetadata(referencingNotes);
    this._refreshSnippets();
  }

  // --------------------------------------------------------------------------
  componentDidUpdate(prevProps) {
    const { referencingNotes } = this.props;

    if (prevProps.referencingNotes !== referencingNotes) {
      this._refreshSnippets();
      this._cacheMetadata(referencingNotes);
    }
  }

  // --------------------------------------------------------------------------
  componentWillUnmount() {
    this._unmounted = true;
  }

  // --------------------------------------------------------------------------
  render() {
    const {
      initialFilterParams,
      readonly,
      referencedNote,
      referencingNotes,
      refreshReferencingNotes,
    } = this.props;
    const { collapsedByUrl, referencesByUrl } = this.state;

    return (
      <div className={ `referencing-notes ${ readonly ? "readonly" : "" }` }>
        {
          referencingNotes !== null
            ? (
              <ReferencingNotesContent
                collapsedByUrl={ collapsedByUrl }
                fetchReferencedNotes={ this._fetchReferencedNotes }
                getNote={ this._getNote }
                getTag={ this._getTag }
                initialFilterParams={ initialFilterParams }
                readonly={ readonly }
                referencedNote={ referencedNote }
                referencesByUrl={ referencesByUrl }
                referencingNotes={ referencingNotes }
                refreshReferencingNotes={ refreshReferencingNotes }
                suggestNotes={ this._suggestNotes }
                suggestTags={ this._suggestTags }
                toggleNoteCollapsed={ this._toggleNoteCollapsed }
              />
            )
            : (<div className="loader-container"><CircularProgress size="large" /></div>)
        }

        { readonly ? null : (<UnlinkedReferences refreshReferencingNotes={ refreshReferencingNotes } />) }
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _cacheMetadata = referencingNotes => {
    if (!referencingNotes) return;

    referencingNotes.forEach(referencingNote => {
      const note = {
        ...omit(referencingNote, [ "url" ]),
        ...noteParamsFromURL(referencingNote.url),
      };

      this._cachedNoteByUUID[noteUUIDFromNoteParams(note)] = note;

      const { tags } = referencingNote;
      if (tags) {
        tags.forEach(tag => {
          this._cachedTagByText[tag.text] = tag;
        });
      }
    });
  };

  // --------------------------------------------------------------------------
  _fetchReferencedNotes = async (localUUID, remoteUUID) => {
    const { fetchReferencedNotes } = this.context;

    const referencedNoteByUUID = await fetchReferencedNotes(localUUID, remoteUUID);

    Object.keys(referencedNoteByUUID).forEach(noteUUID => {
      this._cachedNoteByUUID[noteUUID] = referencedNoteByUUID[noteUUID];
    });

    return referencedNoteByUUID;
  };

  // --------------------------------------------------------------------------
  _getNote = noteUUID => {
    return this._cachedNoteByUUID[noteUUID];
  };

  // --------------------------------------------------------------------------
  _getTag = tagText => {
    return this._cachedTagByText[tagText];
  };

  // --------------------------------------------------------------------------
  _refreshSnippets = async () => {
    const { referencingNotes } = this.props;
    if (referencingNotes === null) return;

    const { fetchNoteContent } = this.context;

    for (let i = 0; i < referencingNotes.length; i++) {
      const { url } = referencingNotes[i];

      try {
        // eslint-disable-next-line no-await-in-loop
        const references = await fetchNoteContent(url, NOTE_CONTENT_TYPE.LINKED);

        // Relatively common if user navigates away while we're loading
        if (this._unmounted) return;

        this.setState({ referencesByUrl: { ...this.state.referencesByUrl, [url]: references } });
      } catch (_error) {
        // Ignore, so we may continue to load next note's references
      }
    }
  };

  // --------------------------------------------------------------------------
  _suggestNotes = async query => {
    const { suggestNotes } = this.context;

    const result = await suggestNotes(query);

    const { notes } = result;
    notes.forEach(note => {
      this._cachedNoteByUUID[noteUUIDFromNoteParams(note)] = omit(note, [ "html" ]);
    });

    return result;
  };

  // --------------------------------------------------------------------------
  _suggestTags = async query => {
    const { suggestTags } = this.context;

    const tags = await suggestTags(query);

    tags.forEach(tag => {
      this._cachedTagByText[tag.text] = omit(tag, [ "html" ]);
    });

    return tags;
  };

  // --------------------------------------------------------------------------
  _toggleNoteCollapsed = url => {
    const { collapsedByUrl } = this.state;

    if (collapsedByUrl[url]) {
      this.setState({ collapsedByUrl: omit(collapsedByUrl, [ url ]) });
    } else {
      this.setState({ collapsedByUrl: { ...collapsedByUrl, [url]: true } });
    }
  };
}

ReferencingNotes.propTypes = {
  initialFilterParams: PropTypes.object,
  readonly: PropTypes.bool,
  referencedNote: PropTypes.shape({
    localUUID: PropTypes.string,
    remoteUUID: PropTypes.string,
  }),
  referencingNotes: PropTypes.array,
  refreshReferencingNotes: PropTypes.func.isRequired,
};
