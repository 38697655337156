import { keyBy, upperFirst } from "lodash"
import PropTypes from "prop-types"
import React from "react"
import { CircularProgress } from "@rmwc/circular-progress"
import {
  List,
  ListDivider,
  ListItem,
  ListItemGraphic,
  ListItemMeta,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemText,
} from "@rmwc/list"
import scrollIntoView from "scroll-into-view-if-needed"
import Tippy from "@tippyjs/react"

import { anchorNameFromHeadingText } from "lib/ample-util/note-url"
import { normalizeTagText, TAG_TEXT_DELIMITER, tagFromTagText, TAGS_DELIMITER } from "lib/ample-util/tags"
import TagIcon from "lib/ample-editor/components/tag-icon"
import { isAndroidChrome } from "lib/ample-editor/lib/client-info"
import { hasModifierKey, preventEventDefault } from "lib/ample-editor/lib/event-util"
import extractTags, { OMIT_FILTER_TAGS_CHARACTER } from "lib/ample-editor/lib/extract-tags"
import fuzzyMatchHeadings from "lib/ample-editor/lib/fuzzy-match-headings"
import NOTE_CONTENT_TYPE from "lib/ample-editor/lib/note-content-type"
import VECTOR_ICON_PATHS from "lib/ample-editor/lib/vector-icon-paths"

// --------------------------------------------------------------------------
const MAX_SUGGESTIONS = 5;

// --------------------------------------------------------------------------
const SUGGESTION_TYPE = {
  EXISTING: "existing",
  NEW: "new",
  CANCEL: "cancel",
};

// --------------------------------------------------------------------------
const HEADING_ICON_PATHS = [
  VECTOR_ICON_PATHS["format-header-1"],
  VECTOR_ICON_PATHS["format-header-2"],
  VECTOR_ICON_PATHS["format-header-3"],
];

// --------------------------------------------------------------------------
function closedTextFromText(text) {
  const match = text.match(/^(.+)\s*]]\s*$/)
  return match ? match[1] : null;
}

// --------------------------------------------------------------------------
function haveTagWithText(tags, newTagText) {
  if (!tags) return false;

  for (let i = 0; i < tags.length; i++) {
    const { text: tagText } = tags[i];

    // If you have a tag "blah/sub-tag" then you already have the "blah" tag
    if (tagText === newTagText || tagText.startsWith(newTagText + TAG_TEXT_DELIMITER)) {
      return true;
    }
  }

  return false;
}

// --------------------------------------------------------------------------
// e.g.:
//  "//blah" => "blah"
//  "/blah/subtag//" => "blah/subtag"
//  "/blah/subtag//,what" => "what"
//  "/blah/subtag//,/what/sub//" => "what/sub"
function tagSearchTermFromText(text) {
  const tagTexts = text.split(TAGS_DELIMITER);
  return tagTexts[tagTexts.length - 1].replace(/^\/+|\/+$/g, "");
}

// --------------------------------------------------------------------------
// The text prop gets passed to this component with a trailing `]` that allows
// this menu to detect a closing `]]`, but we don't typically want to include it
// in search terms
function textWithoutTrailingBracket(text) {
  return text.replace(/]$/, "");
}

// --------------------------------------------------------------------------
export default class NoteLinkMenu extends React.Component {
  static propTypes = {
    acceptSuggestion: PropTypes.func.isRequired,
    acceptSuggestionOnTab: PropTypes.bool,
    actionDescription: PropTypes.string,
    editorView: PropTypes.object,
    // If given, matching headings in the active note suggestion will be enabled (by including `#` in the text)
    fetchNoteContent: PropTypes.func,
    // Only consumed on initial construction
    initialOptions: PropTypes.shape({
      // If true, the `sourceTags` returned by suggestNotes will be inserted so they are present in props.text and
      // get applied to any existing note being linked to (in addition to any new notes).
      insertSourceTags: PropTypes.bool,
    }),
    // The intention is to insert the content of the target note, not to link to it, which changes some text/behavior
    insertContentMode: PropTypes.bool,
    insertTagText: PropTypes.func,
    onCancel: PropTypes.func,
    placeholder: PropTypes.string,
    shouldSuggestTags: PropTypes.func,
    suggestNotes: PropTypes.func.isRequired,
    suggestTags: PropTypes.func,
    text: PropTypes.string.isRequired,
  };

  _applySourceTags = false;
  _listRef = React.createRef();
  _textLastChangedAt = 0;

  // --------------------------------------------------------------------------
  constructor(props) {
    super(props);

    const { insertSourceTags } = props.initialOptions || { insertSourceTags: false };
    this._applySourceTags = insertSourceTags;

    const { shouldSuggestTags, text } = this.props;

    const showTagSuggestions = text.endsWith(TAG_TEXT_DELIMITER) && (!shouldSuggestTags || shouldSuggestTags(text));

    this.state = {
      activeHeadingSuggestionIndex: 0,
      activeNoteSuggestionIndex: 0,
      activeTagSuggestionIndex: 0,
      dismissed: false,
      headingSuggestions: null,
      loadingHeadingSuggestions: false,
      noteSuggestions: [],
      showTagSuggestions,
      tagSuggestions: null,
    };
  }

  // --------------------------------------------------------------------------
  async componentDidMount() {
    const { insertTagText, suggestNotes } = this.props;

    if (this._applySourceTags && insertTagText) {
      const { sourceTags } = await suggestNotes("source-tags-search", []);
      if (sourceTags && sourceTags.length > 0) {
        const tagsText = sourceTags.map(({ text }) => text).join(TAGS_DELIMITER);
        insertTagText(tagsText, { nonTagText: this.props.text });
      }
    }

    this._updateSuggestions(this.state.showTagSuggestions);
  }

  // --------------------------------------------------------------------------
  componentDidUpdate(prevProps, prevState) {
    const { text } = this.props;
    const { showTagSuggestions } = this.state;

    const textChanged = text !== prevProps.text;
    if (textChanged || (showTagSuggestions !== prevState.showTagSuggestions && !showTagSuggestions)) {
      const closedText = closedTextFromText(text);
      if (closedText && !closedTextFromText(prevProps.text)) {
        this._acceptActiveSuggestion();
        return;
      }

      if (this.state.dismissed) this.setState({ dismissed: false });

      if (textChanged) {
        this._textLastChangedAt = Date.now();

        if (showTagSuggestions) {
          // When suggesting tags, there are a few cases where we want to drop back to note suggestions, under the
          // assumption that the user is no longer interested in suggestions for tags
          const stopSuggestingTags = text === "" || // If the user types `[[/` then deletes the `/`
            /^.+\/.*\s/.test(text); // Typing a space, indicating we're no longer looking for sub-tags

          if (stopSuggestingTags) {
            this.setState({ showTagSuggestions: false });
            return;
          }
        } else if (isAndroidChrome && text.endsWith(TAG_TEXT_DELIMITER)) {
          // Android Chrome doesn't send a keydown event for `/` if it happens close to composition input (i.e. via the
          // soft keyboard), likely due to some over-broad Android Chrome workarounds in ProseMirror.

          const { shouldSuggestTags } = this.props;
          if (!shouldSuggestTags || shouldSuggestTags(text)) {
            this.setState({ showTagSuggestions: true });
            return;
          }
        }
      }

      this._updateSuggestions(showTagSuggestions);
    }

    this._scrollToActiveSuggestion(prevState);
  }

  // --------------------------------------------------------------------------
  contains(element) {
    const { current: list } = this._listRef;
    if (list && list.root && list.root.ref) {
      return list.root.ref.contains(element);
    }
    return false;
  }

  // --------------------------------------------------------------------------
  // `cancel` (when `true`) indicates that the user doesn't want to see this menu here again
  dismiss(cancel = false) {
    this.setState({ dismissed: true });

    if (cancel) {
      const { onCancel } = this.props;
      if (onCancel) onCancel();
    }
  }

  // --------------------------------------------------------------------------
  handleKeyDown(event) {
    if (this.state.dismissed) return false;

    switch (event.key) {
      case "ArrowDown":
      case "ArrowUp":
        if (!hasModifierKey(event)) {
          this._moveSelection(event.key === "ArrowDown" ? 1 : -1);
          return true;
        }
        break;

      case "Escape":
        if (!hasModifierKey(event)) {
          const { headingSuggestions, loadingHeadingSuggestions, showTagSuggestions } = this.state;
          if (showTagSuggestions) {
            this._hideTagSuggestions();
          } else if (headingSuggestions || loadingHeadingSuggestions) {
            this._hideHeadingSuggestions();
          } else {
            this.dismiss(true);
          }
          return true;
        }
        break;

      case "Tab":
        if (!this.props.acceptSuggestionOnTab) return false;
        // eslint-disable-next-line no-fallthrough
      case "Enter":
        if (!hasModifierKey(event)) {
          // On iOS, pressing enter when the word the cursor is at the end of has a highlighted autocorrect suggestion
          // will result in the suggestion being accepted, replacing the text, _and_ inserting a newline. ProseMirror
          // detects this as an enter keypress (despite no actual keypress event occurring) and sends us this synthetic
          // key event. The problem is we just updated the suggestions to match the autocorrected text but the user
          // hasn't had a chance to see that yet, so it can seem as if the wrong item was selected. We'll just leave
          // the new text there so they get a chance to see it (need to return true to keep ProseMirror from keeping
          // the newline that Safari inserted).
          if ((Date.now() - this._textLastChangedAt) < 50) return true;

          this._acceptActiveSuggestion();
          return true;
        }
        break;

      case TAG_TEXT_DELIMITER: {
        const { shouldSuggestTags } = this.props;

        if (!shouldSuggestTags || shouldSuggestTags(this.props.text + TAG_TEXT_DELIMITER)) {
          const { showTagSuggestions, tagSuggestions } = this.state;

          const text = textWithoutTrailingBracket(this.props.text);

          const stateUpdates = { showTagSuggestions: true };

          // We want to let the user type out an exact match of a tag (though this relies on the tag autocomplete
          // having finished by this point) and a slash then continue typing a note name instead of continuing to
          // complete tag names. They can type another slash to come back to tag mode.
          if (showTagSuggestions && tagSuggestions && !text.endsWith(TAG_TEXT_DELIMITER)) {
            const searchTerm = tagSearchTermFromText(text);
            if (tagSuggestions.find(({ text: tagText }) => searchTerm === tagText)) {
              stateUpdates.showTagSuggestions = false;
            }
          }

          this.setState(stateUpdates);
        }

        // We don't want to swallow this keydown, just respond to it
        break;
      }

      default:
        break;
    }

    return false;
  }

  // --------------------------------------------------------------------------
  isDismissed() {
    return this.state.dismissed;
  }

  // --------------------------------------------------------------------------
  render() {
    if (this.state.dismissed) return null;

    return (
      <List className="note-link-menu popup-list" ref={ this._listRef }>
        { this._renderSuggestions() }
      </List>
    );
  }

  // --------------------------------------------------------------------------
  undismiss() {
    this.setState({ activeNoteSuggestionIndex: 0, dismissed: false });
  }

  // --------------------------------------------------------------------------
  _acceptActiveSuggestion() {
    const { headingSuggestions, tagSuggestions } = this.state;

    if (tagSuggestions !== null) {
      const tagSuggestion = this._activeTagSuggestion();
      if (tagSuggestion) {
        switch (tagSuggestion.type) {
          case SUGGESTION_TYPE.CANCEL:
            this._hideTagSuggestions();
            break;

          case SUGGESTION_TYPE.NEW:
            this._acceptNewTagSuggestion();
            break;

          default:
          case SUGGESTION_TYPE.EXISTING:
            this._acceptTagSuggestion(tagSuggestion);
            break;

        }
      } else {
        this._acceptNewTagSuggestion();
      }

      return;
    }

    if (headingSuggestions !== null) {
      const headingSuggestion = this._activeHeadingSuggestion();
      if (headingSuggestion) {
        this._acceptHeadingSuggestion(headingSuggestion);
      } else {
        this._hideHeadingSuggestions();
      }

      return;
    }

    const noteSuggestion = this._activeNoteSuggestion();
    if (noteSuggestion) {
      this._acceptNoteSuggestion(noteSuggestion);
      return;
    }

    // Special case: linking to "#" links to the current section of the current note
    if (headingSuggestions && headingSuggestions.note) {
      const { acceptSuggestion, text: fullText } = this.props;
      const { text } = extractTags(fullText);
      if (text.trim() === "#") {
        acceptSuggestion(headingSuggestions.note.name, headingSuggestions.note.url, []);
      }
    }
  }

  // --------------------------------------------------------------------------
  _acceptHeadingSuggestion(headingSuggestion) {
    const { linkText, linkURL } = headingSuggestion;

    this.props.acceptSuggestion(
      linkText,
      linkURL,
      // Since we're not displaying that we'll add tags to the note, they aren't added, but this probably should
      // be adjusted to display that we're going to add tags using the normal rules for that, then actually add them.
      []
    );
  }

  // --------------------------------------------------------------------------
  _acceptNewTagSuggestion() {
    // Use whatever they've entered (that has no matches) verbatim
    this._hideTagSuggestions();

    const { text } = this.props;
    const tagTexts = textWithoutTrailingBracket(text).split(TAGS_DELIMITER);
    const newTagText = normalizeTagText(tagSearchTermFromText(tagTexts[tagTexts.length - 1]));

    const prefix = text.startsWith(OMIT_FILTER_TAGS_CHARACTER) ? OMIT_FILTER_TAGS_CHARACTER : "";
    const newTagsText = prefix + tagTexts.slice(0, -1).concat([ newTagText ]).join(TAGS_DELIMITER);

    // This ensures that a trailing / is added and the cursor is moved appropriately
    this.props.insertTagText(newTagsText);
  }

  // --------------------------------------------------------------------------
  _acceptNoteSuggestion(noteSuggestion) {
    const { isUnpersistedDailyNote, name, url } = noteSuggestion;

    let newTags = noteSuggestion.newTags || [];

    if (isUnpersistedDailyNote && noteSuggestion.tags) {
      newTags = newTags.concat(noteSuggestion.tags);
    }

    const newTagTexts = newTags.map(({ text }) => text);

    this.props.acceptSuggestion(name, url || null, newTagTexts);
  }

  // --------------------------------------------------------------------------
  _acceptTagSuggestion(tagSuggestion) {
    const { text: newTagText } = tagSuggestion;

    const { text } = this.props;
    const tagTexts = textWithoutTrailingBracket(text).split(TAGS_DELIMITER);

    const prefix = text.startsWith(OMIT_FILTER_TAGS_CHARACTER) ? OMIT_FILTER_TAGS_CHARACTER : "";
    const newTagsText = prefix + tagTexts.slice(0, -1).concat([ newTagText ]).join(TAGS_DELIMITER);

    this.props.insertTagText(newTagsText);
    this._hideTagSuggestions();
  }

  // --------------------------------------------------------------------------
  _activeHeadingSuggestion() {
    const { activeHeadingSuggestionIndex, headingSuggestions } = this.state;
    if (headingSuggestions === null) return null;
    return headingSuggestions[activeHeadingSuggestionIndex];
  }

  // --------------------------------------------------------------------------
  _activeNoteSuggestion() {
    const { activeNoteSuggestionIndex, noteSuggestions } = this.state;
    return noteSuggestions[activeNoteSuggestionIndex];
  }

  // --------------------------------------------------------------------------
  _activeTagSuggestion() {
    const { activeTagSuggestionIndex, tagSuggestions } = this.state;
    if (tagSuggestions === null) return null;
    return tagSuggestions[activeTagSuggestionIndex];
  }

  // --------------------------------------------------------------------------
  _hideHeadingSuggestions = () => {
    this.setState({ headingSuggestions: null, loadingHeadingSuggestions: false });
  };

  // --------------------------------------------------------------------------
  _hideTagSuggestions = () => {
    this.setState({ activeTagSuggestionIndex: 0, showTagSuggestions: false, tagSuggestions: null });
  };

  // --------------------------------------------------------------------------
  _moveSelection = delta => {
    const { headingSuggestions, noteSuggestions, tagSuggestions } = this.state;

    if (headingSuggestions !== null) {
      // We keep more heading suggestions loaded than we actually show
      const shownSuggestionsCount = Math.min(MAX_SUGGESTIONS, headingSuggestions.length);

      let activeHeadingSuggestionIndex = this.state.activeHeadingSuggestionIndex + delta;
      if (activeHeadingSuggestionIndex < 0) {
        activeHeadingSuggestionIndex = Math.max(0, shownSuggestionsCount - 1);
      } else if (activeHeadingSuggestionIndex >= shownSuggestionsCount) {
        activeHeadingSuggestionIndex = 0;
      }

      this.setState({ activeHeadingSuggestionIndex });
    } else if (tagSuggestions !== null) {
      let activeTagSuggestionIndex = this.state.activeTagSuggestionIndex + delta;
      if (activeTagSuggestionIndex < 0) {
        activeTagSuggestionIndex = Math.max(0, tagSuggestions.length - 1);
      } else if (activeTagSuggestionIndex >= tagSuggestions.length) {
        activeTagSuggestionIndex = 0;
      }

      this.setState({ activeTagSuggestionIndex });
    } else {
      let activeNoteSuggestionIndex = this.state.activeNoteSuggestionIndex + delta;
      if (activeNoteSuggestionIndex < 0) {
        activeNoteSuggestionIndex = Math.max(0, noteSuggestions.length - 1);
      } else if (activeNoteSuggestionIndex >= noteSuggestions.length) {
        activeNoteSuggestionIndex = 0;
      }

      this.setState({ activeNoteSuggestionIndex });
    }
  };

  // --------------------------------------------------------------------------
  _onHeadingSuggestionClick = (headingSuggestion, event) => {
    event.preventDefault();
    event.stopPropagation();

    this._acceptHeadingSuggestion(headingSuggestion);
  };

  // --------------------------------------------------------------------------
  _onHideHeadingSuggestionsClick = event => {
    event.preventDefault();
    event.stopPropagation();

    this._hideHeadingSuggestions();
  };

  // --------------------------------------------------------------------------
  _onHideTagSuggestionsClick = event => {
    event.preventDefault();
    event.stopPropagation();

    this._hideTagSuggestions();
  };

  // --------------------------------------------------------------------------
  _onNoteSuggestionClick = (noteSuggestion, event) => {
    event.preventDefault();
    event.stopPropagation();

    this._acceptNoteSuggestion(noteSuggestion);
  };

  // --------------------------------------------------------------------------
  _onTagSuggestionClick = (tagSuggestion, event) => {
    event.preventDefault();
    event.stopPropagation();

    this._acceptTagSuggestion(tagSuggestion);
  };

  // --------------------------------------------------------------------------
  _onUseNewTagClick = event => {
    event.preventDefault();
    event.stopPropagation();

    this._acceptNewTagSuggestion();
  };

  // --------------------------------------------------------------------------
  async _performAsyncAction(action) {
    const { text } = this.props;

    const result = await action();

    // Async calls can take varying time, and if they are triggered by user input (typing) they can overlap and come
    // back out of order. We only want to use the result of this call if the user input still matches what it was
    // before awaiting it
    if (this.props.text !== text) return null;

    return result;
  }

  // --------------------------------------------------------------------------
  _renderHeadingSuggestion(headingSuggestion, index) {
    const { activeHeadingSuggestionIndex } = this.state;
    const { headingHTML, headingLevel, headingText } = headingSuggestion;

    const isSelected = index === activeHeadingSuggestionIndex;

    let icon;
    if (headingLevel < 0) {
      icon = "description";
    } else {
      const iconPath = HEADING_ICON_PATHS[headingLevel - 1];
      if (iconPath) {
        icon = (<svg viewBox="0 0 24 24"><path d={ iconPath }/></svg>);
      } else {
        icon = "title";
      }
    }

    return (
      <ListItem
        className="note-link-menu-item popup-list-item"
        key={ `heading-${ headingText }-${ index }` }
        onClick={ this._onHeadingSuggestionClick.bind(this, headingSuggestion) }
        onMouseDown={ preventEventDefault }
        selected={ isSelected }
        tabIndex="-1"
      >
        <ListItemGraphic icon={ icon } />
        <ListItemText>
          <ListItemPrimaryText>
            { headingHTML ? <span dangerouslySetInnerHTML={ { __html: headingHTML } } /> : headingText }
          </ListItemPrimaryText>
        </ListItemText>
        { isSelected ? <ListItemMeta icon="keyboard_return" /> : null }
      </ListItem>
    );
  }

  // --------------------------------------------------------------------------
  _renderHeadingSuggestions(headingSuggestions, loadingHeadingSuggestions) {
    const renderedSuggestions = [];

    const activeNoteSuggestion = this._activeNoteSuggestion();
    if (activeNoteSuggestion) {
      const { actionDescription, insertContentMode } = this.props;
      const { name } = activeNoteSuggestion;

      const noteSuggestion = {
        ...activeNoteSuggestion,
        text: (
          <span>
            {
              insertContentMode
                ? "Insert a section of"
                : `${ upperFirst(actionDescription || "link") } to a heading ${ name !== null ? "in" : "" }`
            } <span className="value">{ name }</span>
          </span>
        ),
      }
      renderedSuggestions.push(this._renderNoteSuggestion(noteSuggestion, -1));
      renderedSuggestions.push(<ListDivider key="divider"/>);
    }

    if (loadingHeadingSuggestions) {
      renderedSuggestions.push(
        <ListItem disabled key="loading-headings">
          <ListItemGraphic icon={ <CircularProgress size="xsmall"/> } />
          Loading headings.
        </ListItem>
      );
    } else {
      let omittedCount = 0;

      headingSuggestions.forEach((headingSuggestion, index) => {
        if (index < MAX_SUGGESTIONS) {
          renderedSuggestions.push(this._renderHeadingSuggestion(headingSuggestion, index));
        } else {
          omittedCount++;
        }
      });

      if (headingSuggestions.length === 0) {
        renderedSuggestions.push(
          <ListItem disabled key="no-headings">
            No matching headings found.
          </ListItem>
        );

        const { insertContentMode } = this.props;

        renderedSuggestions.push(
          <ListItem
            className="note-link-menu-item popup-list-item"
            key="headings-cancel"
            onClick={ this._onHideHeadingSuggestionsClick }
            onMouseDown={ preventEventDefault }
            selected
            tabIndex="-1"
          >
            <ListItemGraphic icon="arrow_back" />
            <ListItemText>
              <ListItemPrimaryText>
                <span>Search for a note to { insertContentMode ? "insert" : "link" }</span>
              </ListItemPrimaryText>
            </ListItemText>
            <ListItemMeta icon={ <svg viewBox="0 0 24 24"><path d={ VECTOR_ICON_PATHS["keyboard-esc"] }/></svg> } />
          </ListItem>
        );
      } else if (omittedCount > 0) {
        renderedSuggestions.push(
          <ListItem disabled key="omitted-headings">
            Continue typing to search { omittedCount } heading{ omittedCount === 1 ? "" : "s" } not listed above.
          </ListItem>
        );
      }
    }

    return renderedSuggestions;
  }

  // --------------------------------------------------------------------------
  _renderNoteSuggestion(noteSuggestion, index) {
    const { activeNoteSuggestionIndex } = this.state;
    const { html, isUnpersistedDailyNote, matchingFilterTags, name, newTags, tags, url } = noteSuggestion;

    const isSelected = index === activeNoteSuggestionIndex;
    const isDisabled = index === -1;
    const isExistingNote = isUnpersistedDailyNote || url;

    let icon;
    let text;
    if (isExistingNote) {
      icon = noteSuggestion.icon || "description";
      text = noteSuggestion.text || (html ? <span dangerouslySetInnerHTML={ { __html: html } } /> : name);
    } else {
      const { actionDescription } = this.props;

      icon = "create";
      text = noteSuggestion.text || (
        <span>
          { upperFirst(actionDescription || "link") } to a new note named <span className="value">{ name }</span>
        </span>
      );
    }

    const { shares } = newTags.find(newTag => newTag.shares) || {};

    let sharedTagsWarning = null;
    if (shares) {
      sharedTagsWarning = (
        <ListItemSecondaryText className="shared-tag-warning">
          <i className="material-icons">error</i>
          <span className="text">
            Applying this tag will share the note with { shares } user{ shares === 1 ? "" : "s" }
          </span>
        </ListItemSecondaryText>
      )
    }

    return (
      <ListItem
        className={ `note-link-menu-item popup-list-item ${ sharedTagsWarning ? "tall" : "" }` }
        disabled={ isDisabled }
        key={ url || `new-note-${ index }` }
        onClick={ isDisabled ? null : this._onNoteSuggestionClick.bind(this, noteSuggestion) }
        onMouseDown={ preventEventDefault }
        selected={ isSelected }
        tabIndex="-1"
      >
        <ListItemGraphic icon={ icon } />
        <ListItemText>
          <ListItemPrimaryText>
            { text }
          </ListItemPrimaryText>
          { this._renderTags(matchingFilterTags, newTags, tags || []) }
          { sharedTagsWarning }
        </ListItemText>
        { isSelected ? <ListItemMeta icon="keyboard_return" /> : null }
      </ListItem>
    );
  }

  // --------------------------------------------------------------------------
  _renderSuggestions() {
    const {
      headingSuggestions,
      loadingHeadingSuggestions,
      noteSuggestions,
      showTagSuggestions,
    } = this.state;

    if (showTagSuggestions) {
      return this._renderTagSuggestions();
    }

    if (noteSuggestions.length === 0 && (headingSuggestions === null || headingSuggestions.length === 0)) {
      const { insertContentMode, text } = this.props;
      const { text: noteSearchText } = extractTags(text.trim());
      const haveText = noteSearchText.length > 0 && noteSearchText !== "]";

      let { noMatchPlaceholder, placeholder } = this.props;
      if (!noMatchPlaceholder) {
        if (noteSearchText.trim() === "#") {
          noMatchPlaceholder = `Close brackets to ${ insertContentMode ? "insert" : "create a link to" } the current section.`;
        } else if (headingSuggestions !== null || loadingHeadingSuggestions) {
          noMatchPlaceholder = `Type to search for a section to ${ insertContentMode ? "insert" : "link" }.`;
        } else if (insertContentMode) {
          noMatchPlaceholder = "Type to search for a note to insert";
        } else {
          noMatchPlaceholder = "Close brackets to create a new linked note.";
        }
      }
      if (!placeholder) {
        placeholder = `Type to search for a note to ${ insertContentMode ? "insert" : "link" }.`;
      }

      return (
        <ListItem disabled>
          { haveText ? noMatchPlaceholder : placeholder }
          { haveText ? <ListItemMeta icon="keyboard_return" /> : null }
        </ListItem>
      );
    }

    if (headingSuggestions !== null || loadingHeadingSuggestions) {
      return this._renderHeadingSuggestions(headingSuggestions, loadingHeadingSuggestions);
    }

    const renderedSuggestions = [];

    noteSuggestions.forEach((noteSuggestion, index) => {
      renderedSuggestions.push(this._renderNoteSuggestion(noteSuggestion, index));

      if (index === 0 && noteSuggestions.length > 1) {
        renderedSuggestions.push(<ListDivider key="divider"/>);
      }
    });

    return renderedSuggestions;
  }

  // --------------------------------------------------------------------------
  _renderTags(matchingFilterTags, newTags, tags) {
    if (matchingFilterTags.length === 0 && newTags.length === 0 && tags.length === 0) return null;

    const existingTagByText = keyBy(tags, "text");

    const matchingFilterTagsContent = (matchingFilterTags || []).map(({ text, ...tagMetadata }) => {
      delete existingTagByText[text];

      return (
        <div className="tag existing" key={ text }>
          <TagIcon { ...tagMetadata } />
          { text }
        </div>
      );
    });

    const newTagsContent = (newTags || []).map(({ canBeOmitted, match, text, ...tagMetadata }) => {
      delete existingTagByText[text];

      const newTagContent = (
        <div className="tag" key={ text }>
          <strong>+</strong><TagIcon { ...tagMetadata } />
          { match ? <span dangerouslySetInnerHTML={ { __html: match } }/> : text }
        </div>
      );

      if (canBeOmitted) {
        return (
          <Tippy
            content="Add ~ to the beginning of the link text to omit this tag"
            delay={ 200 }
            key={ text }
            placement="bottom"
            touch={ false }
          >
            { newTagContent }
          </Tippy>
        );
      } else {
        return newTagContent;
      }
    });

    const existingTagsContent = Object.values(existingTagByText).map(({ text, ...tagMetadata }) => (
      <div className="tag existing" key={ text }>
        <TagIcon { ...tagMetadata } />
        { text }
      </div>
    ));

    return (
      <ListItemSecondaryText>
        { newTagsContent }
        { matchingFilterTagsContent }
        { existingTagsContent }
      </ListItemSecondaryText>
    );
  }

  // --------------------------------------------------------------------------
  _renderTagSuggestion = (tagSuggestion, index) => {
    const { activeTagSuggestionIndex } = this.state;
    const { html, text, ...tagMetadata } = tagSuggestion;

    const isSelected = index === activeTagSuggestionIndex;

    const { shares } = tagMetadata;

    let sharedTagWarning = null;
    if (shares) {
      sharedTagWarning = (
        <ListItemSecondaryText className="shared-tag-warning">
          <i className="material-icons">error</i>
          <span className="text">Tag is shared with { shares } user{ shares === 1 ? "" : "s" }</span>
        </ListItemSecondaryText>
      );
    }

    return (
      <ListItem
        className="note-link-menu-item popup-list-item"
        key={ `tag-${ text }-${ index }` }
        onClick={ this._onTagSuggestionClick.bind(this, tagSuggestion) }
        onMouseDown={ preventEventDefault }
        selected={ isSelected }
        tabIndex="-1"
      >
        <ListItemGraphic icon={ <TagIcon { ...tagMetadata } /> } />
        <ListItemText>
          <ListItemPrimaryText>
            { html ? <span dangerouslySetInnerHTML={ { __html: html } } /> : text }
          </ListItemPrimaryText>

          { sharedTagWarning }
        </ListItemText>
        { isSelected ? <ListItemMeta icon="keyboard_return" /> : null }
      </ListItem>
    );
  };

  // --------------------------------------------------------------------------
  _renderTagSuggestions() {
    const { activeTagSuggestionIndex, tagSuggestions } = this.state;

    if (tagSuggestions === null) {
      return (
        <ListItem disabled key="loading-tags">
          <ListItemGraphic icon={ <CircularProgress size="xsmall"/> }/>
          Loading tags.
        </ListItem>
      );
    }

    if (tagSuggestions.length === 0) {
      return (
        <ListItem disabled key="tags-placeholder">Type to search for a tag.</ListItem>
      );
    }

    return tagSuggestions.map((tagSuggestion, index) => {
      const isSelected = activeTagSuggestionIndex === index;

      switch (tagSuggestion.type) {
        case SUGGESTION_TYPE.CANCEL: {
          const { insertContentMode } = this.props;
          const icon = isSelected ? "keyboard_return" : <svg viewBox="0 0 24 24"><path d={ VECTOR_ICON_PATHS["keyboard-esc"] }/></svg>;

          return (
            <ListItem
              className="note-link-menu-item popup-list-item"
              key="tags-cancel"
              onClick={ this._onHideTagSuggestionsClick }
              onMouseDown={ preventEventDefault }
              selected={ isSelected }
              tabIndex="-1"
            >
              <ListItemGraphic icon="arrow_back" />
              <ListItemText>
                <ListItemPrimaryText>
                  <span>Search for a note to { insertContentMode ? "insert" : "link" }</span>
                </ListItemPrimaryText>
              </ListItemText>
              <ListItemMeta icon={ icon } />
            </ListItem>
          );
        }

        case SUGGESTION_TYPE.NEW: {
          const { text } = tagSuggestion;

          return (
            <ListItem
              className="note-link-menu-item popup-list-item"
              key="tags-new"
              onClick={ this._onUseNewTagClick }
              onMouseDown={ preventEventDefault }
              selected={ isSelected }
              tabIndex="-1"
            >
              <ListItemGraphic icon={ <TagIcon /> } />
              <ListItemText>
                <ListItemPrimaryText>
                  <span>Use new tag <span className="value">{ text }</span></span>
                </ListItemPrimaryText>
              </ListItemText>
              { isSelected ? <ListItemMeta icon="keyboard_return" /> : null }
            </ListItem>
          );
        }

        default:
        case SUGGESTION_TYPE.EXISTING:
          return this._renderTagSuggestion(tagSuggestion, index);
      }
    });
  }

  // --------------------------------------------------------------------------
  _scrollToActiveSuggestion(prevState) {
    const {
      activeHeadingSuggestionIndex,
      activeNoteSuggestionIndex,
      activeTagSuggestionIndex,
      headingSuggestions,
      tagSuggestions,
    } = this.state;

    if (tagSuggestions) {
      const { activeTagSuggestionsIndex: activeTagSuggestionsIndexWas } = prevState;
      if (activeTagSuggestionIndex === activeTagSuggestionsIndexWas) return;
    } else if (headingSuggestions) {
      const { activeHeadingSuggestionIndex: activeHeadingSuggestionIndexWas } = prevState;
      if (activeHeadingSuggestionIndex === activeHeadingSuggestionIndexWas) return;
    } else {
      const { activeNoteSuggestionIndex: activeNoteSuggestionIndexWas } = prevState;
      if (activeNoteSuggestionIndex === activeNoteSuggestionIndexWas) return;
    }

    const { current: list } = this._listRef;
    const element = list ? list.listElements[activeHeadingSuggestionIndex] : null;
    if (!element) return;

    // As of 6/2024, only nesting in the list-item-commands-menu has scrolling
    const scrollAncestor = element.closest(".list-item-commands-menu");
    if (!scrollAncestor) return;

    scrollIntoView(element, {
      block: "start",
      behavior: "smooth",
      boundary: scrollAncestor,
      scrollMode: "if-needed",
    });
  }

  // --------------------------------------------------------------------------
  async _updateHeadingSuggestions(noteURL, headingSearchTerm) {
    const { editorView, fetchNoteContent } = this.props;

    const stateUpdates = { loadingHeadingSuggestions: false };
    try {
      const headingsResult = await this._performAsyncAction(() => fetchNoteContent(noteURL, NOTE_CONTENT_TYPE.HEADINGS));
      if (headingsResult === null) {
        this.setState({ loadingHeadingSuggestions: false });
        return;
      }
      const { headings, note } = headingsResult;

      if (!this.state.loadingHeadingSuggestions) return;

      const searchingCurrentNote = noteURL === "#";

      let currentHeadingNode = null;
      if (searchingCurrentNote && editorView) {
        // We've got a better idea of the headings in the current note - including where the cursor currently is, which
        // may not be a section defined by a heading, but we need the `note` value from the result to correctly produce
        // links to the current note.
        headings.splice(0, headings.length);

        const { state: { doc, selection: { from } } } = editorView;

        doc.descendants((node, nodePos) => {
          if (node.type.name === "heading") {
            if (from > nodePos) {
              if (currentHeadingNode) headings.push(currentHeadingNode.toJSON());
              currentHeadingNode = node;
            } else {
              headings.push(node.toJSON());
            }
          }
        });

        // If the user enters a search term, we don't want to force the current section to be first
        if (headingSearchTerm && currentHeadingNode) {
          headings.unshift(currentHeadingNode.toJSON());
          currentHeadingNode = null;
        }
      }

      let noteName = null;
      if (note) {
        noteName = note.name;
        noteURL = note.url;
      }

      const headingSuggestions = fuzzyMatchHeadings(headings, headingSearchTerm).map(headingSuggestion => {
        const { anchorName, headingText } = headingSuggestion;
        return {
          ...headingSuggestion,
          linkText: searchingCurrentNote ? headingText : `${ noteName }#${ headingText }`,
          linkURL: `${ noteURL }#${ anchorName }`,
        };
      });

      if (currentHeadingNode) {
        // Make sure the current section is listed first
        const { attrs: { level: headingLevel }, textContent: headingText } = currentHeadingNode;
        const anchorName = anchorNameFromHeadingText(headingText);
        headingSuggestions.unshift({
          anchorName,
          headingLevel,
          headingHTML: `<span>Current section: ${ headingText }</span>`,
          headingText,
          linkText: headingText,
          linkURL: `${ noteURL }#${ anchorName }`,
        });
      } else if (searchingCurrentNote && editorView && !headingSearchTerm) {
        // Not in a heading-defined section, offer option to just link to note
        headingSuggestions.unshift({
          anchorName: null,
          headingLevel: -1,
          headingText: noteName,
          linkText: noteName,
          linkURL: noteURL,
        });
      }

      if (note) {
        headingSuggestions.note = note;
      }

      stateUpdates.headingSuggestions = headingSuggestions;
      stateUpdates.activeHeadingSuggestionIndex = Math.min(
        this.state.activeHeadingSuggestionIndex,
        Math.max(0, Math.min(MAX_SUGGESTIONS - 1, headingSuggestions.length - 1))
      );
    } catch (error) {
      this.setState({ loadingHeadingSuggestions: false });
      throw error;
    }

    this.setState(stateUpdates);
  }

  // --------------------------------------------------------------------------
  async _updateNoteSuggestions(queryText, queryTagTexts, applyFilterTags) {
    const { fetchNoteContent, insertContentMode, suggestNotes } = this.props;

    let [ noteSearchTerm, headingSearchTerm ] = queryText.split("#", 2);
    if (!fetchNoteContent) {
      // Callers can omit fetchNoteContent to disable heading searching all together (in some cases it might not
      // make sense)
      // eslint-disable-next-line no-undefined
      headingSearchTerm = undefined;
      noteSearchTerm = queryText;
    } else if (typeof(headingSearchTerm) === "undefined") {
      noteSearchTerm = queryText;
    }

    const result = await this._performAsyncAction(() => suggestNotes(noteSearchTerm, queryTagTexts));
    if (!result) return;

    const { filterTags, notes } = result;

    const noteSuggestions = insertContentMode
      ? notes.filter(({ url }) => url).slice(0, MAX_SUGGESTIONS)
      : notes.slice(0, MAX_SUGGESTIONS);

    // Tags are divided into groups that can be displayed appropriately by the note link dialog here:
    //
    //    - `filterTags` that are present in the current editing context. These are only added to new notes if the
    //      link text does not start with "~" (see the `extractTags` function) and are not added when linking to
    //      existing notes.
    //
    //    - Tags that the user typed out in such that they are included in `props.text`. These tags will be added to
    //      any note that is linked to, whether new and existing. These tags are also used to weight the ranking of the
    //      notes that are shown based on the current search text.
    //
    //    - Any other tags that the note already has, displayed to provide additional identification/disambiguation
    //
    noteSuggestions.forEach(noteSuggestion => {
      const { isSecureNote, isUnpersistedDailyNote, url } = noteSuggestion;

      const isExistingNote = isUnpersistedDailyNote || url;

      const matchingFilterTags = [];
      const newTags = [];
      const normalizedNoteName = (noteSuggestion.name || "").toLowerCase();

      const tags = (isExistingNote ? noteSuggestion.tags : null) || [];

      if (queryTagTexts && !insertContentMode) {
        queryTagTexts.forEach(queryTagText => {
          if (!haveTagWithText(tags, queryTagText) && !haveTagWithText(newTags, queryTagText)) {
            const tag = tagFromTagText(result.queryTagByText, queryTagText);

            if (normalizedNoteName.includes(queryTagText + TAG_TEXT_DELIMITER)) return;

            // Can't add shared tag to secure notes
            if (isSecureNote && tag.shares) return;

            newTags.push(tag);
          }
        });
      }

      // We want to do this _after_ examining tagsFromText in case tagsFromText includes a filter note
      if (applyFilterTags && filterTags) {
        if (isExistingNote) {
          filterTags.forEach(filterTag => {
            const { text: filterTagText } = filterTag;

            if (!haveTagWithText(tags, filterTagText)) return;
            if (haveTagWithText(newTags, filterTagText)) return;
            if (haveTagWithText(matchingFilterTags, filterTagText)) return;

            matchingFilterTags.push(filterTag);
          });
        } else if (!insertContentMode) {
          // Need to add filterTags to new notes
          filterTags.forEach(filterTag => {
            const { text: filterTagText } = filterTag;

            if (haveTagWithText(tags, filterTagText)) return;
            if (haveTagWithText(newTags, filterTagText)) return;

            // Can't add shared tag to secure notes
            if (isSecureNote && filterTag.shares) return;

            newTags.push({ ...filterTag, canBeOmitted: true });
          });
        }
      }

      noteSuggestion.matchingFilterTags = matchingFilterTags;
      noteSuggestion.newTags = newTags;
    });

    const activeNoteSuggestionIndex = Math.min(
      this.state.activeNoteSuggestionIndex,
      Math.max(0, noteSuggestions.length - 1)
    );

    let headingSearchNoteURL = null;
    if (typeof(headingSearchTerm) !== "undefined") {
      if (activeNoteSuggestionIndex < noteSuggestions.length) {
        const { headingNoteURL, url: noteURL } = noteSuggestions[activeNoteSuggestionIndex];
        if (headingNoteURL) {
          // The URL of the option may differ from the note that we want to get heading suggestions for
          headingSearchNoteURL = headingNoteURL;
        } else if (noteURL) {
          headingSearchNoteURL = noteURL;
        }
      } else if (!noteSearchTerm) {
        // Searching for headings in current note e.g. using "#blah" as the full search text
        headingSearchNoteURL = "#";
      }
    }

    this.setState({
      activeNoteSuggestionIndex,
      activeTagSuggestionIndex: 0,
      headingSuggestions: headingSearchNoteURL !== null ? this.state.headingSuggestions : null,
      loadingHeadingSuggestions: headingSearchNoteURL !== null,
      noteSuggestions,
      showTagSuggestions: false,
      tagSuggestions: null,
    });

    if (headingSearchNoteURL !== null) {
      await this._updateHeadingSuggestions(headingSearchNoteURL, headingSearchTerm);
    }
  }

  // --------------------------------------------------------------------------
  _updateSuggestions(showTagSuggestions) {
    const { suggestTags, text: fullText } = this.props;

    if (suggestTags && showTagSuggestions) {
      let text = textWithoutTrailingBracket(fullText);

      if (text.startsWith(OMIT_FILTER_TAGS_CHARACTER)) {
        text = text.substring(OMIT_FILTER_TAGS_CHARACTER.length);
      }

      this._updateTagSuggestions(text);
    } else {
      const { applyFilterTags, tags, text } = extractTags(fullText);

      this._updateNoteSuggestions(text, tags, applyFilterTags);
    }
  }

  // --------------------------------------------------------------------------
  async _updateTagSuggestions(text) {
    const { suggestTags } = this.props;

    const searchTerm = tagSearchTermFromText(text);

    if (!this.state.showTagSuggestions) this.setState({ showTagSuggestions: true });
    const stateUpdates = { tagSuggestions: [] };
    try {
      const allTagSuggestions = await this._performAsyncAction(() => suggestTags(searchTerm));
      if (!allTagSuggestions) return;

      // The user may have cancelled showing suggestions, in which case we want to bail out
      if (!this.state.showTagSuggestions) return;

      // If the user has typed the name of a tag exactly, followed by a slash, let them keep on with that tag
      const shouldUseMatchingTag = text.endsWith(TAG_TEXT_DELIMITER) &&
        // but let the user type another slash to go back to tag mode, e.g. "blah//" (with a tag named "blah")
        !text.endsWith(TAG_TEXT_DELIMITER + TAG_TEXT_DELIMITER) &&
        allTagSuggestions.find(({ text: tagText }) => searchTerm === tagText);

      if (shouldUseMatchingTag) {
        stateUpdates.showTagSuggestions = false;
        return;
      }

      const tagSuggestions = allTagSuggestions.slice(0, MAX_SUGGESTIONS);

      if (searchTerm.length > 0) {
        if (tagSuggestions.length === 0) {
          tagSuggestions.push({ type: SUGGESTION_TYPE.NEW, text: normalizeTagText(searchTerm) });
        } else if (searchTerm.includes(TAG_TEXT_DELIMITER)) {
          const newTagText = normalizeTagText(searchTerm, { allowInvalidDelimiters: true });

          // If they have typed a new subtag in - e.g. "/zbaz/a/" - we want to suggest using it even if there's a
          // match like `zbaz/subtag`
          const haveParentTagMatch = tagSuggestions.find(({ text: tagSuggestionText }) => {
            const parentTagText = tagSuggestionText.split(TAG_TEXT_DELIMITER).slice(0, -1).join(TAG_TEXT_DELIMITER);
            return parentTagText && newTagText.startsWith(parentTagText + TAG_TEXT_DELIMITER);
          });

          if (haveParentTagMatch) {
            tagSuggestions.push({ type: SUGGESTION_TYPE.NEW, text: normalizeTagText(newTagText) });
          }
        }

        // Making a suggestion entry out of the "go back" option - instead of handling it during rendering only - makes
        // keyboard interaction handling simpler
        tagSuggestions.push({ type: SUGGESTION_TYPE.CANCEL });
      }

      stateUpdates.activeTagSuggestionIndex = Math.min(
        this.state.activeTagSuggestionIndex,
        Math.max(0, tagSuggestions.length - 1)
      );
      stateUpdates.tagSuggestions = tagSuggestions;
    } finally {
      this.setState(stateUpdates);
    }
  }
}
