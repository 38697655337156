import { amplenoteParamsFromRelativeURL, amplenoteParamsFromURL } from "lib/ample-util/amplenote-area"
import { isNewNoteURL } from "lib/ample-util/note-url"

// --------------------------------------------------------------------------
export function googleSheetsParamsFromURL(url) {
  const match = url.match(/^https:\/\/docs\.google\.com\/spreadsheets\/d\/(?:e\/)?([\w_-]+)(?:\/pubchart\?oid=(\d+))?/);
  return match ? { chartId: match[2], documentId: match[1] } : null;
}

// --------------------------------------------------------------------------
// We allow users to be flexible with the href attribute of links, so they
// may enter "http://www.blah.com", "https://www.blah.com", "/blah", "#blah", etc
// but we also want them to have "www.blah.com" work like they might expect, so
// this transforms anything that doesn't start with a URL scheme (with no validation
// on the scheme name, since I want my "itms-apps://testing" links to work), a path
// separator, or a pound symbol to start with https://
export function hrefFromAttribute(hrefAttribute) {
  if (!hrefAttribute) return hrefAttribute;

  if (hrefAttribute.startsWith("#")) return hrefAttribute;

  if (hrefAttribute.startsWith("/")) {
    // iOS WebViews won't open relative URLs - not even letting the app know they were tapped, but we want to support
    // relative amplenote URLs, so we'll add the amplenote:// scheme to make them work in the mobile app
    if (window.isAmplenoteEmbedEditor && amplenoteParamsFromRelativeURL(hrefAttribute)) {
      return `amplenote:/${ hrefAttribute }`;
    }

    return hrefAttribute;
  }

  // Per https://tools.ietf.org/html/rfc3986#section-3.1 a valid scheme is:
  //   ALPHA *( ALPHA / DIGIT / "+" / "-" / "." )
  if (hrefAttribute.match(/^[a-zA-Z][\w+\-.]+:\/\//)) return hrefAttribute;

  // Also allow `mailto:`, which doesn't use a valid scheme
  if (hrefAttribute.startsWith("mailto:")) return hrefAttribute;

  return `https://${ hrefAttribute }`;
}

// --------------------------------------------------------------------------
export function imageFromLinkAttributes(attributes) {
  return imageFromMedia(attributes.media);
}

// --------------------------------------------------------------------------
export function imageFromMedia(media) {
  const { type, url } = media || {};

  if (!url) return null;
  if ((type || "image") !== "image") return null;

  return url;
}

// --------------------------------------------------------------------------
export function mediaFromURL(url, type = "image") {
  if (!url) return null;

  const media = { url };
  if (type && type !== "image") media.type = type;

  return media;
}

// --------------------------------------------------------------------------
export function pluginParamsFromURL(url) {
  if (!url) return null;

  const match = url.match(/^plugin:\/\/([0-9a-fA-F-]+)?(?:\?([^#]+))?(?:$|#)/);
  if (!match) return null;

  return {
    args: match[2] || null,
    uuid: match[1],
  };
}

// --------------------------------------------------------------------------
// Public note embeds don't support custom slugs as of 11/2020, so we only extract tokens here, and ignore URLs that
// have slugs (which use a shortened token that doesn't work in absense of the slug).
export function publicNoteParamsFromURL(url) {
  const match = url.match(/^https:\/\/public.amplenote\.com\/([0-9a-zA-Z]+)?($|\?|#)/);
  if (!match) return null;

  return {
    fragment: match[2] === "#" ? url.slice(match[0].length) : null,
    token: match[1],
  };
}

// --------------------------------------------------------------------------
export function targetFromURL(url, nonBlankTarget = "_self") {
  const defaultTarget = "_blank";
  if (!url || url.length === 0) return defaultTarget;

  // If the link is intra-page, don't open it in a new window
  const isHandledURL = url[0] === "#" ||
    amplenoteParamsFromURL(url) ||
    amplenoteParamsFromRelativeURL(url) ||
    isNewNoteURL(url) ||
    pluginParamsFromURL(url);
  if (isHandledURL) return nonBlankTarget;

  return defaultTarget;
}

// --------------------------------------------------------------------------
// e.g. https://twitter.com/amplenote/status/1324072170914488320
export function twitterTweetIdFromURL(url) {
  const twitterMatch = url.match(/(?:^|\.|:\/\/)(?:twitter|x)\.com\/.+\/status\/(\d+)\b/);
  return twitterMatch ? twitterMatch[1] : null;
}

// --------------------------------------------------------------------------
export function urlFromLinkAttributes(attributes) {
  return urlFromMedia(attributes.media);
}

// --------------------------------------------------------------------------
function urlFromMedia(media) {
  return media ? media.url : null;
}

// --------------------------------------------------------------------------
// Loosely based on https://stackoverflow.com/a/8260383 and comments
export function youtubeVideoIdFromURL(url) {
  const match = url.match(/.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/);
  if (!match) return null;

  if (url.includes("youtu.be") || url.includes("youtube.com") || url.includes("https://www.youtube-nocookie.com")) {
    return match[1];
  }

  return null;
}

// --------------------------------------------------------------------------
// Extract timestamp from a user-viewable link like youtube.com/watch?v=CH1pWqY0lPs&t=309s
export function youtubeStartTimeFromURL(url) {
  const match = url.match(/[&?]t=([\d]+)/);
  if (!match) return null;
  return match[1];
}
