import { Plugin, PluginKey, TextSelection } from "prosemirror-state"

import { transactionChangesDoc } from "lib/ample-editor/lib/transaction-util"
import { getExpandedTaskUUID } from "lib/ample-editor/plugins/check-list-item-plugin"
import LinkPluginView from "lib/ample-editor/views/link-plugin-view"

// --------------------------------------------------------------------------
const linkPluginKey = new PluginKey("link");

// --------------------------------------------------------------------------
function activeLinkFromState(state, lastState) {
  const documentUnchanged = lastState && lastState.doc.eq(state.doc);
  const selectionUnchanged = lastState && lastState.selection.eq(state.selection);

  // Don't do anything if the document/selection didn't change
  if (documentUnchanged && selectionUnchanged) {
    return { leaveExistingOpen: true };
  }

  const { schema } = state;
  const { selection: { $head } } = state;

  const linkNode = $head.node($head.depth);
  if (!linkNode || linkNode.type !== schema.nodes.link) return {};

  const $linkPos = state.doc.resolve($head.start($head.depth));

  // There's a special case when a link is in a check-list-item with the TIC expanded - in that case we don't want
  // to keep showing the SD popup _unless_ the user clicks back into the link (thus changing the selection)
  if (linkNode && !documentUnchanged && selectionUnchanged) {
    const ancestorNode = $linkPos.node(1);
    if (ancestorNode && ancestorNode.type === schema.nodes.check_list_item) {
      const expandedTaskUUID = getExpandedTaskUUID(state);
      const taskDetailIsExpanded = expandedTaskUUID !== null && expandedTaskUUID === ancestorNode.attrs.uuid;

      // Note that we only want to prevent the link being open if there was no link open already - otherwise we could
      // be closing the link's popup on the user as they try to change it.
      if (taskDetailIsExpanded && getOpenLinkPos(lastState) === null) {
        return {};
      }
    }
  }

  return { $linkPos, linkNode };
}

// --------------------------------------------------------------------------
export function getIsNewLink(state) {
  const pluginState = linkPluginKey.getState(state);
  return pluginState ? pluginState.isNewLink : false;
}

// --------------------------------------------------------------------------
export function getNoteLinkMenuOptions(state, pos) {
  const { noteLinkMenu } = linkPluginKey.getState(state) || {};
  if (!noteLinkMenu) return null;

  const { pos: expectedPos, options } = noteLinkMenu;
  return pos === expectedPos ? options : null;
}

// --------------------------------------------------------------------------
export function getOpenLinkPos(state) {
  const pluginState = linkPluginKey.getState(state);
  return pluginState ? pluginState.openLinkPos : null;
}

// --------------------------------------------------------------------------
function openLinkPosFromState(lastState, state) {
  const { $linkPos, leaveExistingOpen } = activeLinkFromState(state, lastState);
  if ($linkPos) return $linkPos.pos - 1;

  if (leaveExistingOpen) {
    const pluginState = linkPluginKey.getState(lastState);
    if (pluginState) return pluginState.openLinkPos;
  }

  return null;
}

// --------------------------------------------------------------------------
export function retainOpenLinkPos(shouldRetainOpenLinkPos, state, transform) {
  const openLinkPos = getOpenLinkPos(state);
  if (shouldRetainOpenLinkPos(openLinkPos)) {
    transform.setMeta(linkPluginKey, { openLinkPos });
  }
}

// --------------------------------------------------------------------------
export function setEditFieldName(transform, editFieldName) {
  const meta = transform.getMeta(linkPluginKey) || {};
  return transform.setMeta(linkPluginKey, { ...meta, editFieldName });
}

// --------------------------------------------------------------------------
export function setIsNewLink(transform, isNewLink) {
  const meta = transform.getMeta(linkPluginKey) || {};
  return transform.setMeta(linkPluginKey, { ...meta, isNewLink });
}

// --------------------------------------------------------------------------
// Sets options that will be passed to the NoteLinkMenu if it is opened at the given position
export function setNoteLinkMenuOptions(transform, pos, options) {
  const meta = transform.getMeta(linkPluginKey) || {};
  return transform.setMeta(linkPluginKey, { ...meta, noteLinkMenu: { pos, options } });
}

// --------------------------------------------------------------------------
export function setOpenLinkPosition(transaction, nodePos, editFieldName = null) {
  return transaction.setMeta(linkPluginKey, { editFieldName, openLinkPos: nodePos });
}

// --------------------------------------------------------------------------
export default function createLinkPlugin(pluginOptions = {}) {
  const {
    disableEditing = false,
    disableView = false,
    disableRichFootnoteSpacer = false,
  } = pluginOptions;

  // Storing a reference so we can manipulate the view from the plugin (necessary due to some timing issues)
  let linkPluginView = null;

  // --------------------------------------------------------------------------
  function handleKeyDown(view, event) {
    return linkPluginView !== null ? linkPluginView.handleKeyDown(view, event) : false;
  }

  return new Plugin({
    key: linkPluginKey,

    // --------------------------------------------------------------------------
    appendTransaction: (transactions, oldState, newState) => {
      const documentChanged = transactions.find(transactionChangesDoc);
      if (!documentChanged) return;

      const { schema } = newState;

      let transaction = null;

      // Due to some poor browser interactions with the contenteditable=false link icon, we don't want to have empty
      // links in the document, but there are a number of ways they can get created (e.g. splitting nodes, deleting the
      // last character, etc). We'll just make sure there are no empty link nodes.
      newState.doc.descendants((node, pos, parentNode) => {
        if (node.type !== schema.nodes.link) return;

        // "\ufeff" is a zero-width non-breaking space used to give the link icon some desirable behavior, but Chrome
        // duplicates it _into_ the link node's content in some unknown cases, resulting in a link that looks empty but
        // isn't actually empty
        if (node.nodeSize === 2 || node.textContent === "\ufeff") {
          // Don't mess with newly inserted links that haven't had a chance to get content yet (i.e. SD popup with text
          // input box is currently open)
          const { isNewLink, openLinkPos } = linkPluginKey.getState(newState) || {};
          if (pos === openLinkPos && isNewLink) return;

          if (!transaction) transaction = newState.tr;
          transaction.delete(transaction.mapping.map(pos), transaction.mapping.map(pos + node.nodeSize));

          return;
        }

        // To make it easier to position the cursor after a link node that is at the end the parent node, we'll auto-
        // insert a space character into the paragraph after the link
        if (node === parentNode.lastChild && parentNode.type === schema.nodes.paragraph) {
          if (!transaction) transaction = newState.tr;

          const selectionWas = transaction.selection.head;

          const afterPos = pos + node.nodeSize;
          const mappedInsertPos = transaction.mapping.map(afterPos);
          transaction.insertText(" ", mappedInsertPos);

          // If the user just deleted the space after a link, inserting a new space will push the cursor forward one,
          // which makes it seem like nothing happened, so we need to push the cursor back to where it was
          if (selectionWas === mappedInsertPos && transaction.selection.head === selectionWas + 1) {
            transaction.setSelection(TextSelection.create(transaction.doc, selectionWas));
          }
        }
      });

      if (transaction) {
        // If we are making some change, the transform we dispatch with be the last transform state.apply sees, but
        // we want to retain any state updates from the previous transaction (mainly the `isNewLink` property as of
        // 3/2019)
        if (transactions.length > 0) {
          const meta = transactions[transactions.length - 1].getMeta(linkPluginKey);
          if (meta) transaction.setMeta(linkPluginKey, { ...meta });
        }

        return transaction;
      }
    },

    // --------------------------------------------------------------------------
    props: {
      handleKeyDown,

      // These are not standard editor props, but are exposed so other areas of the editor can interact with the views
      // handled by this plugin. Can be called as:
      //    editorView.someProp("closeNoteLinkMenu", closeNoteLinkMenu => {
      //      closeNoteLinkMenu();
      //      return true;
      //    });

      closeNoteLinkMenu: () => {
        if (linkPluginView) linkPluginView.dismissNoteLinkMenu();
      },

      isNoteLinkMenuOpen: () => {
        return linkPluginView ? linkPluginView.isNoteLinkMenuOpen() : false;
      },
    },

    // --------------------------------------------------------------------------
    state: {
      init: (_config, _state) => ({
        // The user dismissed the link menu at this position, so we should endeavour to not show it there again
        cancelNoteLinkMenuPos: null,

        // Whether the link being opened (by `openLinkPos`) should initially be opened in edit mode (non-null) or view
        // mode (null), and if edit mode, the name of the field that should be focused initially ("description",
        // "media", etc). Can also be `true` to edit whatever field is the default (based on attributes)
        editFieldName: null,

        // Whether the openLinkPos last set to a non-null value was for a newly created link. For newly created links, we
        // want to autofocus the RF popup when we show it, and turn them back to non-links when canceling out of the RF
        // popup
        isNewLink: false,

        // If present, shaped as:
        //  { pos: <integer>, options: {} }
        // if the NoteLinkMenu is opened at the position matching `pos` then `options` will be passed to it
        noteLinkMenu: null,

        // Allows the popup to be forcibly opened for a link node at a specific document position
        // May be set to -1 to indicate that it's open for a position outside the document, in which case the opener will
        // be responsible for positioning the popup.
        openLinkPos: null,
      }),

      apply: (tr, pluginState, lastState, state) => {
        let { isNewLink } = pluginState;

        const meta = tr.getMeta(linkPluginKey) || {};

        let { cancelNoteLinkMenuPos } = pluginState;
        if ("cancelNoteLinkMenuPos" in meta) {
          cancelNoteLinkMenuPos = meta.cancelNoteLinkMenuPos;
        } else if (cancelNoteLinkMenuPos !== null) {
          // If the opening character of the link is deleted, we want to let the user re-type it to open the menu again
          const mapResult = tr.mapping.mapResult(cancelNoteLinkMenuPos, 1);
          cancelNoteLinkMenuPos = mapResult.deletedAfter ? null : mapResult.pos;
        }

        let editFieldName = null;
        if ("editFieldName" in meta) {
          editFieldName = meta.editFieldName;
        }

        let noteLinkMenu = null;
        if ("noteLinkMenu" in meta) {
          noteLinkMenu = meta.noteLinkMenu;
        }

        let openLinkPos;
        if ("openLinkPos" in meta) {
          openLinkPos = meta.openLinkPos;
        } else {
          openLinkPos = openLinkPosFromState(lastState, state);

          // When opening a link, the Rich Footnote may auto-focus the description, which will apply a transaction
          // immediately after opening the RF, but we want to retain any settings we had when we opened the link
          if (openLinkPos !== null && openLinkPos === pluginState.openLinkPos) {
            if (!("editFieldName" in meta)) editFieldName = pluginState.editFieldName
            if (!("isNewLink" in meta)) isNewLink = pluginState.isNewLink;
          } else {
            isNewLink = false;
          }
        }

        if ("isNewLink" in meta) {
          isNewLink = meta.isNewLink;
        }

        return {
          cancelNoteLinkMenuPos,
          editFieldName,
          isNewLink,
          noteLinkMenu,
          openLinkPos,
        };
      },

      toJSON: ({ editFieldName, isNewLink, openLinkPos }) => ({ editFieldName, isNewLink, openLinkPos }),
      fromJSON: (_config, { editFieldName, isNewLink, openLinkPos }) => ({ editFieldName, isNewLink, openLinkPos }),
    },

    // --------------------------------------------------------------------------
    view: disableView ? null : editorView => {
      const linkPluginInterface = {
        getCancelNoteLinkMenuPos(state) {
          const pluginState = linkPluginKey.getState(state);
          return pluginState ? pluginState.cancelNoteLinkMenuPos : null;
        },
        getEditFieldName(state) {
          const pluginState = linkPluginKey.getState(state);
          return pluginState ? pluginState.editFieldName : null;
        },
        getIsNewLink,
        getNoteLinkMenuOptions,
        getOpenLinkPos,
        setCancelNoteLinkMenuPos(transaction, cancelNoteLinkMenuPos) {
          return transaction.setMeta(linkPluginKey, { cancelNoteLinkMenuPos });
        },
        setIsNewLink,
        setOpenLinkPosition,
      };
      linkPluginView = new LinkPluginView(editorView, disableEditing, disableRichFootnoteSpacer, linkPluginInterface);
      return linkPluginView;
    },
  });
}
