/* eslint-disable */

import React, { useCallback, useRef, useState } from "react"
import { hot } from "react-hot-loader/root"
import { SnackbarQueue } from "@rmwc/snackbar"

import AmpleEditorDemo from "ample-editor-demo"
import DescriptionEditorDemo from "description-editor-demo"
import MarkdownDemo from "markdown-demo"
import RenderedDemo from "rendered-demo"
import snackbarQueue from "snackbar-queue"
import TaskEditorDemo from "task-editor-demo"
import TasksEditorDemo from "tasks-editor-demo"

import "./App.scss"

// --------------------------------------------------------------------------
// In order they appear (left-to-right)
const TAB = {
  AMPLE_EDITOR: 0,
  DESCRIPTION_EDITOR: 1,
  TASKS_EDITOR: 2,
  TASK_EDITOR: 3,
};

// --------------------------------------------------------------------------
const PREVIEW_MODE = {
  HTML: 0,
  MARKDOWN: 1,
};

// --------------------------------------------------------------------------
const COMPONENT_BY_TAB = {
  [TAB.AMPLE_EDITOR]: AmpleEditorDemo,
  [TAB.DESCRIPTION_EDITOR]: DescriptionEditorDemo,
  [TAB.TASK_EDITOR]: TaskEditorDemo,
  [TAB.TASKS_EDITOR]: TasksEditorDemo,
};

// --------------------------------------------------------------------------
const CLASS_NAME_BY_TAB = {
  [TAB.AMPLE_EDITOR]: "tab-main-editor",
  [TAB.DESCRIPTION_EDITOR]: "tab-description-editor",
  [TAB.TASK_EDITOR]: "tab-task-editor",
  [TAB.TASKS_EDITOR]: "tab-tasks-editor",
};

// --------------------------------------------------------------------------
const TITLE_BY_PREVIEW_MODE = {
  [PREVIEW_MODE.HTML]: "HTML",
  [PREVIEW_MODE.MARKDOWN]: "Markdown",
};

// --------------------------------------------------------------------------
const TITLE_BY_TAB = {
  [TAB.AMPLE_EDITOR]: "Editor",
  [TAB.DESCRIPTION_EDITOR]: "Description",
  [TAB.TASK_EDITOR]: "Task",
  [TAB.TASKS_EDITOR]: "Tasks",
};

// --------------------------------------------------------------------------
function DemoTab({ currentTab, setCurrentTab, tab, title }) {
  const onClick = useCallback(
    () => {
      setCurrentTab(tab)
    },
    [ setCurrentTab, tab ]
  );

  return (
    <a className={ `demo-tab${ currentTab === tab ? " current" : "" }` } onClick={ onClick }>
      { title }
    </a>
  );
}

// --------------------------------------------------------------------------
function PreviewContainer({ document, replaceDocument }) {
  const [ previewMode, setPreviewMode ] = useState(PREVIEW_MODE.HTML);

  let preview = null;
  if (previewMode === PREVIEW_MODE.HTML) {
    preview = (<RenderedDemo document={ document } />);
  } else if (previewMode === PREVIEW_MODE.MARKDOWN) {
    preview = (<MarkdownDemo document={ document } replaceDocument={ replaceDocument } />);
  }

  return (
    <div>

      <div className="demo-tabs">
        {
          Object.values(PREVIEW_MODE).map(tab => (
            <DemoTab
              currentTab={ previewMode }
              key={ tab }
              setCurrentTab={ setPreviewMode }
              tab={ tab }
              title={ TITLE_BY_PREVIEW_MODE[tab] }
            />
          ))
        }
      </div>

      { preview }
    </div>
  );
}

// --------------------------------------------------------------------------
function App() {
  const [ currentTab, setCurrentTab ] = useState(parseInt(localStorage.getItem("demoTab") || "0"));
  const [ document, setDocument ] = useState(null);
  const [ fullWidth, setFullWidth ] = useState(false);
  const [ showPreview, setShowPreview ] = useState(false);

  const demoComponentRef = useRef();
  const replaceDocument = useCallback(
    newDocument => {
      const { current: demoComponent } = demoComponentRef;
      if (demoComponent && demoComponent.replaceDocument) {
        demoComponent.replaceDocument(newDocument);
      }
    },
    []
  );

  const setCurrentTabWithStorage = useCallback(
    newCurrentTab => {
      localStorage.setItem("demoTab", newCurrentTab.toString());
      setCurrentTab(newCurrentTab);
    },
    [ setCurrentTab ]
  );

  const onToggleFullWidthClick = useCallback(() => { setFullWidth(fullWidthWas => !fullWidthWas); }, []);
  const onTogglePreviewClick = useCallback(() => { setShowPreview(showPreviewWas => !showPreviewWas); }, []);

  const DemoComponent = COMPONENT_BY_TAB[currentTab];
  const className = CLASS_NAME_BY_TAB[currentTab];

  return (
    <div
      className={ `ample-demo ${ className } ${ fullWidth ? " full-width" : "" }` }
    >
      <div className={ `side-by-side-container ${ showPreview ? "enabled" : "" }` }>
        <div>
          <div className="demo-tabs">
            {
              Object.values(TAB).map(tab => (
                <DemoTab
                  currentTab={ currentTab }
                  key={ tab }
                  setCurrentTab={ setCurrentTabWithStorage }
                  tab={ tab }
                  title={ TITLE_BY_TAB[tab] }
                />
              ))
            }
          </div>

          <DemoComponent
            key={ currentTab }
            onDocumentChange={ setDocument }
            ref={ demoComponentRef }
          />

          <div className="debug-actions-row">
            <div>
              <a onClick={ onToggleFullWidthClick }>Toggle full width</a> |&nbsp;
              <a onClick={ onTogglePreviewClick }>Toggle HTML/markdown preview</a>
            </div>
            <span>
              &nbsp;
            </span>
          </div>
        </div>
        { showPreview ? (<PreviewContainer document={ document } replaceDocument={ replaceDocument } />) : null }
      </div>
      <SnackbarQueue dismissIcon messages={ snackbarQueue.messages } timeout={ 5000 }/>
    </div>
  );
}

export default hot(App);
