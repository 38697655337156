import AttachmentView from "lib/ample-editor/views/attachment-view"
import BulletListItemView from "lib/ample-editor/views/bullet-list-item-view"
import CodeBlockView from "lib/ample-editor/views/code-block-view"
import CheckListItemView from "lib/ample-editor/views/check-list-item-view"
import EmbedView from "lib/ample-editor/views/embed-view"
import HeadingView from "lib/ample-editor/views/heading-view"
import ImageView from "lib/ample-editor/views/image-view"
import LinkView from "lib/ample-editor/views/link-view"
import NumberListItemView from "lib/ample-editor/views/number-list-item-view"
import TableCellView from "lib/ample-editor/views/table-cell-view"
import TableView from "lib/ample-editor/views/table-view"
import VideoView from "lib/ample-editor/views/video-view"

// --------------------------------------------------------------------------
// This is a function to avoid issues with circular imports that can cause these to be undefined (e.g. CheckListItemView
// imports a *-commands.js that imports a *-plugin.js that imports a *-plugin-view.js that imports this file)
export default function nodeViews() {
  return {
    attachment: (node, editorView, getPos, decorations) => new AttachmentView(node, editorView, getPos, decorations),
    bullet_list_item: (node, editorView, getPos) => new BulletListItemView(editorView, getPos, node),
    check_list_item: (node, editorView, getPos) => new CheckListItemView(editorView, getPos, node),
    code_block: (node, editorView, getPos) => new CodeBlockView(node, editorView, getPos),
    embed: (node, editorView, getPos) => new EmbedView(node, editorView, getPos),
    heading: (node, editorView, getPos) => new HeadingView(node, editorView, getPos),
    image: (node, editorView, getPos, decorations) => new ImageView(node, editorView, getPos, decorations),
    link: (node, editorView, getPos, decorations) => new LinkView(editorView, getPos, node, decorations),
    number_list_item: (node, editorView, getPos) => new NumberListItemView(editorView, getPos, node),
    table: (node, editorView, getPos) => new TableView(node, editorView, getPos),
    table_cell: (node, editorView, getPos) => new TableCellView(node, editorView, getPos),
    video: (node, editorView, getPos) => new VideoView(node, editorView, getPos),
  };
}

// --------------------------------------------------------------------------
export function nodeViewsForRender() {
  // code_block's view loads in asynchronous components that aren't accommodated by renderer as of Nov 2022
  // eslint-disable-next-line camelcase
  const { code_block, ...viewsForRender } = nodeViews();
  return viewsForRender;
}
