import PropTypes from "prop-types"
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react"
import { Button } from "@rmwc/button"
import { Chip, ChipSet } from "@rmwc/chip"
import { MenuSurface, MenuSurfaceAnchor } from "@rmwc/menu"
import Tippy from "@tippyjs/react"

import { isApplePlatform } from "lib/ample-editor/lib/client-info"
import { durationFromMinutes, minutesFromDuration } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
const SHORTCUT_KEY_COMBO = isApplePlatform ? "ctrl" : "alt+shift";
const STANDARD_DURATION_MINUTES = [ 15, 30, 60, 90 ];
const TOOLTIP_DELAY = [ 1000, 150 ];

// --------------------------------------------------------------------------
// If there's a custom duration, we want to retain it until the control is unmounted, so the user can switch to a
// standard duration without losing the option (i.e. if they want to switch back to the non-standard duration).
function useNonStandardDurationMinutes(duration) {
  const lastNonStandardDurationMinutesRef = useRef(null);

  const durationMinutes = duration ? minutesFromDuration(duration) : null;

  useEffect(
    () => {
      if (durationMinutes && !STANDARD_DURATION_MINUTES.includes(durationMinutes)) {
        lastNonStandardDurationMinutesRef.current = durationMinutes;
      }
    },
    [ durationMinutes ]
  );

  if (durationMinutes && !STANDARD_DURATION_MINUTES.includes(durationMinutes)) {
    return durationMinutes;
  } else {
    return lastNonStandardDurationMinutesRef.current;
  }
}

// --------------------------------------------------------------------------
function CustomDurationChip({ disabled, duration, durationMinutes, onFocus, onKeyDown, setDuration }) {
  const nonStandardDurationMinutes = useNonStandardDurationMinutes(duration);

  const [ menuOpen, setMenuOpen ] = useState(false);

  const closeMenu = useCallback(() => { setMenuOpen(false); }, []);

  const isSelected = durationMinutes !== null && durationMinutes === nonStandardDurationMinutes;
  let label;
  if (nonStandardDurationMinutes) {
    let durationDescription;
    if (nonStandardDurationMinutes === 1440) {
      durationDescription = "All day";
    } else if (nonStandardDurationMinutes % 60 === 0) {
      const hours = Math.floor(nonStandardDurationMinutes / 60);
      durationDescription = `${ hours } hour${ hours === 1 ? "" : "s" }`;
    } else {
      durationDescription = `${ nonStandardDurationMinutes } min`;
    }

    label = `Custom: ${ durationDescription }`;
  } else {
    label = "Custom...";
  }

  const onClick = useCallback(
    () => {
      if (menuOpen) {
        setMenuOpen(false);
      } else if (durationMinutes && durationMinutes === nonStandardDurationMinutes) {
        setMenuOpen(true);
      } else if (nonStandardDurationMinutes) {
        setDuration(durationFromMinutes(nonStandardDurationMinutes));
      } else {
        setMenuOpen(true);
      }
    },
    [ isSelected, menuOpen, nonStandardDurationMinutes ]
  );

  const customDurationMenuRef = useRef(null);
  const onOpen = useCallback(
    () => {
      const { current: customDurationMenu } = customDurationMenuRef;
      if (customDurationMenu) customDurationMenu.focus();
    },
    []
  );

  return (
    <MenuSurfaceAnchor>
      <MenuSurface
        anchorCorner="bottomStart"
        onClose={ closeMenu }
        onOpen={ onOpen }
        open={ menuOpen }
      >
        {
          menuOpen
            ? (
              <CustomDurationMenu
                close={ closeMenu }
                durationMinutes={ durationMinutes }
                ref={ customDurationMenuRef }
                setDuration={ setDuration }
              />
            )
            : null
        }
      </MenuSurface>
      <Chip
        className="custom-duration"
        disabled={ disabled }
        label={ label }
        onClick={ onClick }
        onFocus={ onFocus }
        onKeyDown={ onKeyDown }
        selected={ isSelected }
      />
    </MenuSurfaceAnchor>
  );
}

// --------------------------------------------------------------------------
function CustomDurationMenu({ close, durationMinutes, setDuration }, ref) {
  const [ pendingDurationMinutes, setPendingDurationMinutes ] = useState(durationMinutes);

  const onApplyClick = useCallback(
    () => {
      setDuration(durationFromMinutes(pendingDurationMinutes));
      close();
    },
    [ durationMinutes, pendingDurationMinutes ]
  );

  const onClickAllDay = useCallback(
    () => {
      setDuration("P1D");
      close();
    },
    []
  );

  const onClick2Hours = useCallback(
    () => {
      setDuration("PT2H");
      close();
    },
    []
  );

  const onClick4Hours = useCallback(
    () => {
      setDuration("PT4H");
      close();
    },
    []
  );

  const onChange = useCallback(
    event => {
      const newDurationMinutes = parseInt(event.target.value, 10);
      setPendingDurationMinutes(Number.isNaN(newDurationMinutes) ? null : newDurationMinutes);
    },
    []
  );

  const onFocus = useCallback(event => { event.target.select(); }, []);

  const onKeyDown = useCallback(
    event => {
      // eslint-disable-next-line default-case
      switch (event.key) {
        case "Enter":
          if (durationMinutes !== pendingDurationMinutes) {
            setDuration(durationFromMinutes(pendingDurationMinutes));
          }
          close();
          break;

        case "Escape":
          close();
          break;
      }
    },
    [ durationMinutes, pendingDurationMinutes ]
  );

  const inputRef = useRef();
  useImperativeHandle(ref, () => ({
    focus: () => {
      const { current: input } = inputRef;
      if (input) input.focus();
    },
  }));

  const value = pendingDurationMinutes !== null ? pendingDurationMinutes.toString() : "";

  let inputClassName = "text-input numeric";
  if (value !== "") inputClassName += Number.isNaN(value) ? " invalid" : " valid";

  return (
    <div className="custom-duration-menu">
      <div className="input-row">
        <input
          autoFocus
          className={ inputClassName }
          inputMode="numeric"
          maxLength={ 4 }
          onChange={ onChange }
          onFocus={ onFocus }
          onKeyDown={ onKeyDown }
          ref={ inputRef }
          type="text"
          size={ Math.max(1, value.length) }
          value={ value }
        />
        <div className="label">minutes</div>

        <Button
          className="apply-button"
          disabled={ pendingDurationMinutes === durationMinutes }
          label="Apply"
          onClick={ onApplyClick }
        />
      </div>

      <div className="preset-durations">
        <Chip
          className="custom-duration"
          label="2 hours"
          onClick={ onClick2Hours }
          onKeyDown={ onKeyDown }
        />
        <Chip
          className="custom-duration"
          label="4 hours"
          onClick={ onClick4Hours }
          onKeyDown={ onKeyDown }
        />
        <Chip
          className="custom-duration"
          label="All day"
          onClick={ onClickAllDay }
          onKeyDown={ onKeyDown }
        />
      </div>
    </div>
  );
}
// eslint-disable-next-line no-func-assign
CustomDurationMenu = forwardRef(CustomDurationMenu);

// --------------------------------------------------------------------------
function DurationChip(props) {
  const { className, disabled, durationMinutes, isSelected, label, onFocus, onKeyDown, setDuration, tooltip } = props;

  const onClick = useCallback(
    () => {
      const duration = durationFromMinutes(durationMinutes);
      setDuration(duration);
    },
    [ durationMinutes, setDuration ]
  );

  const chip = (
    <Chip
      className={ className }
      disabled={ disabled }
      label={ label }
      onClick={ onClick }
      onFocus={ onFocus }
      onKeyDown={ onKeyDown }
      selected={ isSelected }
    />
  );

  if (tooltip) {
    return (
      <Tippy content={ tooltip } delay={ TOOLTIP_DELAY } touch={ false }>
        <span>
          { chip }
        </span>
      </Tippy>
    );
  } else {
    return chip;
  }
}

// --------------------------------------------------------------------------
export default function DurationControls(props) {
  const { duration, listItemType, onFocus, onLastControlKeyDown, readonly, setDuration } = props;

  const durationMinutes = duration ? minutesFromDuration(duration) : null;

  const tooltip = `How long will this ${ listItemType === "bullet_list_item" ? "event" : "task" } take?`;

  return (
    <div className="control duration">
      <Tippy arrow={ false } content={ tooltip } delay={ TOOLTIP_DELAY }>
        <div className="control-label">
          <span className="material-icons">timer</span><span className="text">Duration</span>
        </div>
      </Tippy>

      <ChipSet choice>
        <DurationChip
          disabled={ readonly }
          durationMinutes={ 15 }
          isSelected={ durationMinutes === 15 }
          label="15 min"
          onFocus={ onFocus }
          setDuration={ setDuration }
          tooltip={ `Shortcut: ${ SHORTCUT_KEY_COMBO }+1` }
        />

        <DurationChip
          disabled={ readonly }
          durationMinutes={ 30 }
          isSelected={ durationMinutes === 30 }
          label="30 min"
          onFocus={ onFocus }
          setDuration={ setDuration }
          tooltip={ `Shortcut: ${ SHORTCUT_KEY_COMBO }+3` }
        />

        <DurationChip
          disabled={ readonly }
          durationMinutes={ 60 }
          isSelected={ durationMinutes === 60 }
          label="60 min"
          onFocus={ onFocus }
          setDuration={ setDuration }
          tooltip={ `Shortcut: ${ SHORTCUT_KEY_COMBO }+6` }
        />

        <CustomDurationChip
          disabled={ readonly }
          duration={ duration }
          durationMinutes={ durationMinutes }
          onFocus={ onFocus }
          onKeyDown={ onLastControlKeyDown }
          setDuration={ setDuration }
        />
      </ChipSet>
    </div>
  );
}

DurationControls.propTypes = {
  duration: PropTypes.string,
  listItemType: PropTypes.oneOf([ "bullet_list_item", "check_list_item" ]).isRequired,
  onFocus: PropTypes.func,
  onLastControlKeyDown: PropTypes.func,
  readonly: PropTypes.bool,
  setDuration: PropTypes.func.isRequired,
};
