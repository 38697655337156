import { filterParamsFromSearch, searchFromFilterParams } from "lib/ample-util/filter-params"
import { fragmentFromNoteParams, noteParamsFromURL, pathFromNote, searchFromNoteParams } from "lib/ample-util/note-url"
import { pathFromTask } from "lib/ample-util/task-url"

// --------------------------------------------------------------------------
// These are the high-level "areas" of the apps, corresponding to the
// phases of the idea execution funnel and top-level navigation in-app
const AMPLENOTE_AREA = {
  CALENDAR: "calendar",
  GRAPH: "graph",
  JOTS: "jots",
  NOTES: "notes",
  TASKS: "tasks",
};

// --------------------------------------------------------------------------
export const ROOT_CALENDAR_PATH = "/notes/calendar";
export const ROOT_GRAPH_PATH = "/notes/graph";
export const ROOT_JOTS_PATH = "/notes/jots";
export const ROOT_NOTES_PATH = "/notes";
export const ROOT_TASKS_PATH = "/notes/tasks";

// --------------------------------------------------------------------------
// All areas start with /notes (that's the root of the service worker, so all URLs need to fall under it)
const AREA_URL_PATTERN = /^https:\/\/(?:www|edge)\.amplenote\.com\/notes(\/[^?/]+|\/tasks\/([^?/]+))?\/?(?:$|\?)/i;

const BASE_URL = "https://www.amplenote.com";

// --------------------------------------------------------------------------
function addSearchParam(search, key, value) {
  return `${ search }${ search.length > 0 ? "&" : "?" }${ key }=${ encodeURIComponent(value) }`;
}

// --------------------------------------------------------------------------
function amplenoteAreaFromMatch(match) {
  const pathMatch = match[1];
  switch (pathMatch) {
    case "/calendar": return AMPLENOTE_AREA.CALENDAR;
    case "/graph": return AMPLENOTE_AREA.GRAPH;
    case "/jots": return AMPLENOTE_AREA.JOTS;
    case "/tasks": return AMPLENOTE_AREA.TASKS;

    default: {
      // Allowing for `/notes/tasks/1`
      if (pathMatch && pathMatch.startsWith("/tasks/")) {
        return AMPLENOTE_AREA.TASKS;
      }

      return AMPLENOTE_AREA.NOTES;
    }
  }
}

// --------------------------------------------------------------------------
export function amplenoteAreaFromURL(url) {
  const match = url ? url.match(AREA_URL_PATTERN) : null;
  return match ? amplenoteAreaFromMatch(match) : null;
}

// --------------------------------------------------------------------------
// Can handle whatever value a href handles (i.e. can be relative or absolute)
export function amplenoteParamsFromHref(href) {
  if (!href) return null;

  return href.startsWith("/") ? amplenoteParamsFromRelativeURL(href) : amplenoteParamsFromURL(href);
}

// --------------------------------------------------------------------------
export function amplenoteParamsFromRelativeURL(relativeURL) {
  return amplenoteParamsFromURL(BASE_URL + relativeURL);
}

// --------------------------------------------------------------------------
export function amplenoteParamsFromURL(url) {
  const match = url ? url.match(AREA_URL_PATTERN) : null;
  if (!match) return null;

  const search = `?${ url.slice(match[0].length) }`

  const filterParams = filterParamsFromSearch(search);

  const area = amplenoteAreaFromMatch(match);

  const result = { area, filterParams };

  if (area === AMPLENOTE_AREA.CALENDAR) {
    result.calendarParams = calendarParamsFromSearch(search);
  } else if (area === AMPLENOTE_AREA.GRAPH) {
    result.graphParams = graphParamsFromSearch(search);
  } else if (area === AMPLENOTE_AREA.NOTES) {
    const noteParams = noteParamsFromURL(url);
    if (noteParams) result.note = noteParams;
  } else if (area === AMPLENOTE_AREA.TASKS) {
    const taskUUID = match[2];
    if (taskUUID) result.task = { uuid: taskUUID };
  }

  return result;
}

// --------------------------------------------------------------------------
function calendarParamsFromSearch(search) {
  const calendarParams = {};

  const dayMatch = search.match(/[&?]day=(\d+)(?:&|\?|$|#)/i);
  if (dayMatch) calendarParams.day = parseInt(dayMatch[1], 10);

  const taskDomainUUIDMatch = search.match(/[&?]domain=([A-F\d-]+)(?:&|\?|$|#)/i);
  if (taskDomainUUIDMatch) calendarParams.taskDomainUUID = taskDomainUUIDMatch[1];

  const viewModeMatch = search.match(/[&?]viewMode=(\w+)(?:&|\?|$|#)/i);
  if (viewModeMatch) calendarParams.viewMode = viewModeMatch[1];

  return calendarParams;
}

// --------------------------------------------------------------------------
function graphParamsFromSearch(search) {
  const graphParams = {};

  const noteMatch = search.match(/[&?]note=([A-F\d-]+)(?:&|\?|$|#)/i);
  if (noteMatch) graphParams.noteUUID = noteMatch[1];

  const updatedAfterDaysMatch = search.match(/[&?]updatedAfterDays=(\d+)(?:&|\?|$|#)/i);
  if (updatedAfterDaysMatch) graphParams.updatedDayCount = parseInt(updatedAfterDaysMatch[1], 10);

  const updatedAfterEndMatch = search.match(/[&?]updatedAfterEnd=(\d+)(?:&|\?|$|#)/i);
  if (updatedAfterEndMatch) graphParams.updatedEndDate = parseInt(updatedAfterEndMatch[1], 10);

  return graphParams;
}

// --------------------------------------------------------------------------
// Does not contain protocol and domain, just path, query string, and fragment (when applicable)
export function relativeURLFromAmplenoteParams(amplenoteParams) {
  const { area, calendarParams, filterParams, graphParams, note, task } = amplenoteParams;

  let fragment = "";
  let path;
  let search = searchFromFilterParams(filterParams);

  switch (area) {
    case AMPLENOTE_AREA.CALENDAR:
      path = ROOT_CALENDAR_PATH;
      if (calendarParams) search = searchFromCalendarParams(calendarParams, search);
      break;

    case AMPLENOTE_AREA.GRAPH:
      path = ROOT_GRAPH_PATH;
      if (graphParams) search = searchFromGraphParams(graphParams, search);
      break;

    case AMPLENOTE_AREA.JOTS:
      path = ROOT_JOTS_PATH;
      break;

    default:
    case AMPLENOTE_AREA.NOTES:
      if (note) {
        fragment = fragmentFromNoteParams(note);
        path = pathFromNote(note);
        search = searchFromNoteParams(note, search);
      } else {
        path = ROOT_NOTES_PATH;
      }
      break;

    case AMPLENOTE_AREA.TASKS:
      if (task) {
        path = pathFromTask(task);
      } else {
        path = ROOT_TASKS_PATH;
      }
      break;
  }

  return `${ path }${ search }${ fragment }`;
}

// --------------------------------------------------------------------------
function searchFromCalendarParams(calendarParams, search = "") {
  const { day = null, taskDomainUUID = null, viewMode = null } = calendarParams || {};

  if (day) search = addSearchParam(search, "day", day);
  if (taskDomainUUID) search = addSearchParam(search, "domain", taskDomainUUID);
  if (viewMode) search = addSearchParam(search, "viewMode", viewMode);

  return search;
}

// --------------------------------------------------------------------------
function searchFromGraphParams(graphParams, search = "") {
  const {
    noteUUID = null,
    updatedDayCount = null,
    updatedEndDate = null,
  } = graphParams || {};

  if (noteUUID) search = addSearchParam(search, "note", noteUUID);

  if (updatedDayCount !== null && typeof(updatedDayCount) !== "undefined") {
    search = addSearchParam(search, "updatedAfterDays", updatedDayCount);
  }

  if (updatedEndDate !== null && typeof(updatedEndDate) !== "undefined") {
    let updatedAfterEnd;
    if (updatedEndDate instanceof Date) {
      updatedAfterEnd = Math.floor(updatedEndDate.getTime() / 1000);
    } else {
      updatedAfterEnd = updatedEndDate;
    }

    search = addSearchParam(search, "updatedAfterEnd", updatedAfterEnd);
  }

  return search;
}

// --------------------------------------------------------------------------
export function urlFromAmplenoteParams(amplenoteParams) {
  return BASE_URL + relativeURLFromAmplenoteParams(amplenoteParams);
}

// --------------------------------------------------------------------------
export default AMPLENOTE_AREA;
