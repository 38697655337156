import { compact } from "lodash"

// --------------------------------------------------------------------------
export default function resolvePluginActionPromises(pluginActionPromises, options = {}) {
  const {
    // eslint-disable-next-line no-empty-function
    eachPluginAction = _pluginAction => {},
    // eslint-disable-next-line no-empty-function
    setPluginActions = _pluginActions => {},
    shouldCancel = () => false,
  } = options;

  if (!pluginActionPromises) return;

  const resolvedPluginActions = new Array(pluginActionPromises.length).fill(null);

  pluginActionPromises.forEach((pluginActionPromise, pluginActionIndex) => {
    pluginActionPromise.then(pluginAction => {
      resolvedPluginActions[pluginActionIndex] = pluginAction;

      if (shouldCancel() || !pluginAction) return;

      eachPluginAction(pluginAction);
      setPluginActions(compact(resolvedPluginActions));
    });
  });
}
