
// --------------------------------------------------------------------------
const FAILURE_REASON = {
  NO_MOUNT_PLUGIN_EMBED: "no-mount-plugin-embed",
  NO_PLUGIN_RENDERED: "no-plugin-rendered",
};

// --------------------------------------------------------------------------
function classNameFromFailureReason(failureReason) {
  return failureReason ? failureReason : "";
}

// --------------------------------------------------------------------------
export default class EmbedView {
  _destroyPluginEmbed = null;
  _editorView = null;
  _failureReason = null;
  _iframe = null;
  _rendered = false;

  // --------------------------------------------------------------------------
  constructor(node, editorView, _getPos) {
    this._editorView = editorView;

    this.dom = document.createElement("div");
    this.dom.className = "embed";

    this._iframe = document.createElement("iframe");
    this._iframe.setAttribute("frameborder", "0");
    this.dom.appendChild(this._iframe);

    this.update(node);
  }

  // --------------------------------------------------------------------------
  destroy() {
    if (this._destroyPluginEmbed) {
      this._destroyPluginEmbed();
      this._destroyPluginEmbed = null;
    }
  }

  // --------------------------------------------------------------------------
  update(node) {
    const { attrs: { aspectRatio, src } } = node;

    if (!this._rendered) {
      this._failureReason = null;
      this._rendered = true;

      const { props: { hostApp: { mountPluginEmbed } } } = this._editorView;
      if (mountPluginEmbed) {
        const pluginMatch = src.match(/^plugin:\/\/([a-fA-F0-9-]+)(?:$|\/.+)?(\?[^#]+)?/);

        const pluginUUID = pluginMatch ? pluginMatch[1] : null;
        const query = pluginMatch && pluginMatch[2] ? pluginMatch[2].slice(1) : null;
        const args = query ? [ query ] : [];

        mountPluginEmbed(pluginUUID, args, this._iframe).then(destroyPluginEmbed => {
          if (destroyPluginEmbed) {
            this._destroyPluginEmbed = destroyPluginEmbed;
          } else {
            this._failureReason = FAILURE_REASON.NO_PLUGIN_RENDERED;
            this._updateClassName();
          }
        });
      } else {
        this._failureReason = FAILURE_REASON.NO_MOUNT_PLUGIN_EMBED;
      }
    }

    this._updateClassName();

    this._iframe.setAttribute("data-aspect-ratio", aspectRatio);
    this.dom.style.paddingBottom = `${ (1 / aspectRatio) * 100 }%`;
  }

  // --------------------------------------------------------------------------
  _updateClassName() {
    this.dom.className = `embed ${ classNameFromFailureReason(this._failureReason) }`;
  }
}
