import PropTypes from "prop-types"
import React from "react"
import { TextField } from "@rmwc/textfield"

import NoteLinkMenu from "lib/ample-editor/components/note-link-menu"
import HostAppContext from "lib/ample-editor/contexts/host-app-context"
import { hasModifierKey } from "lib/ample-editor/lib/event-util"
import { urlFromNewNoteParams, noteParamsFromURL, urlFromNoteParams } from "lib/ample-util/note-url"
import { TAG_TEXT_DELIMITER, tagFromTagText } from "lib/ample-util/tags"

// --------------------------------------------------------------------------
// Allows disambiguation between using a raw value input by the user as the URL and linking to a note's URL
const VALUE_URL_PREFIX = "value://";

// --------------------------------------------------------------------------
export default class HrefInput extends React.PureComponent {
  static contextType = HostAppContext;
  static propTypes = {
    autoFocus: PropTypes.bool,
    close: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    icon: PropTypes.element,
    inputRef: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    onKeyPress: PropTypes.func,
    placeholder: PropTypes.string,
    suggestNotes: PropTypes.func,
    value: PropTypes.string.isRequired,
  };

  _blurTimeout = null;
  _closeTimeout = null;
  _noteLinkMenu = null;

  state = {
    focused: false,
    valueOnFocus: null,
  };

  // --------------------------------------------------------------------------
  componentWillUnmount() {
    clearTimeout(this._blurTimeout);
    clearTimeout(this._closeTimeout);
  }

  // --------------------------------------------------------------------------
  render() {
    const {
      autoFocus,
      disabled,
      icon,
      inputRef,
      onKeyPress,
      placeholder,
      value,
    } = this.props;

    return (
      <React.Fragment>
        <TextField
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          autoFocus={ autoFocus }
          className="href-input"
          disabled={ disabled }
          fullwidth
          icon={ icon }
          inputRef={ inputRef }
          onBlur={ this._onBlur }
          onFocus={ this._onFocus }
          onKeyDown={ this._onKeyDown }
          onKeyPress={ onKeyPress }
          onChange={ this._onChange }
          placeholder={ placeholder }
          spellCheck="false"
          value={ value }
        />
        { this._renderSuggestions() }
      </React.Fragment>
    )
  }

  // --------------------------------------------------------------------------
  _fetchNoteContent = (noteURL, referenceType) => {
    const { fetchNoteContent } = this.context;

    // If the user types just "#", the note URL will be "value://#" because they are searching for headings in the
    // currently selected suggestion, which by default is "Link to #". We want to let them search for headings in the
    // current note to link to in that case, even though that's not really what the selected top-level item is.
    if (noteURL.startsWith(VALUE_URL_PREFIX)) {
      noteURL = noteURL.slice(VALUE_URL_PREFIX.length);

      // If they've typed something like "blah#" then the default suggestion is "Link to blah#", which we can't suggest
      // headings for (it's not really the current note - they probably want to move the selection down to a note).
      if (!noteURL.startsWith("#")) return null;
    }

    return fetchNoteContent(noteURL, referenceType);
  };

  // --------------------------------------------------------------------------
  _insertTagText = (tagText, { nonTagText = "" } = {}) => {
    this.setState({ valueOnFocus: null });

    const textWithDelimiter = tagText.endsWith(TAG_TEXT_DELIMITER) ? tagText : (tagText + TAG_TEXT_DELIMITER);
    this.props.onChange(textWithDelimiter + nonTagText);
  }

  // --------------------------------------------------------------------------
  _onBlur = () => {
    clearTimeout(this._blurTimeout);

    const tryBlur = () => {
      clearTimeout(this._blurTimeout);

      if (this._noteLinkMenu && document.activeElement && this._noteLinkMenu.contains(document.activeElement)) {
        this._blurTimeout = setTimeout(tryBlur, 10);
        return;
      }

      this.setState({ focused: false });
    };
    this._blurTimeout = setTimeout(tryBlur, 1);
  };

  // --------------------------------------------------------------------------
  _onChange = event => {
    this.setState({ valueOnFocus: null });

    this.props.onChange(event.target.value);
  };

  // --------------------------------------------------------------------------
  _onFocus = () => {
    this.setState({ focused: true, valueOnFocus: this.props.value });
  };

  // --------------------------------------------------------------------------
  _onKeyDown = event => {
    if (this._noteLinkMenu && this._noteLinkMenu.handleKeyDown(event)) {
      event.preventDefault();
      return;
    } else if (event.key === "ArrowDown" && !hasModifierKey(event)) {
      // Allow the down key to open the suggestions menu if we're suppressing it
      if (this._noteLinkMenu && this._noteLinkMenu.isDismissed()) {
        this._noteLinkMenu.undismiss();
      }

      const { focused, valueOnFocus } = this.state;
      if (focused && this.props.value === valueOnFocus) {
        event.preventDefault();
        this.setState({ valueOnFocus: null });
        return;
      }
    }

    const { onKeyDown } = this.props;
    if (onKeyDown) onKeyDown(event);
  };

  // --------------------------------------------------------------------------
  _renderSuggestions() {
    const { focused, valueOnFocus } = this.state;
    if (!focused) return null;

    const { value } = this.props;
    if (!value || value === valueOnFocus) return null;

    const trimmedValue = value.trim();
    if (trimmedValue.length < 3 && !trimmedValue.startsWith(TAG_TEXT_DELIMITER) && !trimmedValue.startsWith("#")) {
      return null;
    }

    const { suggestTags } = this.context;

    return (
      <NoteLinkMenu
        acceptSuggestion={ this._selectSuggestion }
        fetchNoteContent={ this._fetchNoteContent }
        insertTagText={ this._insertTagText }
        ref={ this._setNoteLinkMenu }
        shouldSuggestTags={ this._shouldSuggestTags }
        suggestNotes={ this._suggestNotes }
        suggestTags={ suggestTags }
        text={ value }
      />
    );
  }

  // --------------------------------------------------------------------------
  _selectSuggestion = async (name, url, tagTexts) => {
    const { linkNote } = this.context;

    if (url && url.startsWith(VALUE_URL_PREFIX)) {
      url = url.slice(VALUE_URL_PREFIX.length);
    } else {
      url = linkNote ? await linkNote(url || urlFromNewNoteParams({ name }), { tagTexts }) : "";
    }
    if (url) this.props.onChange(url);

    // MDC wants to add a ripple on click if this was instigated by a mouse event, which will throw an exception if
    // we unmount immediately
    this._closeTimeout = setTimeout(this.props.close, 1);
  };

  // --------------------------------------------------------------------------
  _setNoteLinkMenu = noteLinkMenu => {
    this._noteLinkMenu = noteLinkMenu;
  };

  // --------------------------------------------------------------------------
  // We want users to be able to type in something like `gitclear.com/blah` without forcing it into a tag-autocomplete,
  // which would change "gitclear.com" to a valid tag, i.e. "gitclear-com".
  _shouldSuggestTags = text => {
    // Full protocol (sans second slash, as first slash will trigger tag completion)
    // Per https://tools.ietf.org/html/rfc3986#section-3.1 a valid scheme is:
    //   ALPHA *( ALPHA / DIGIT / "+" / "-" / "." )
    if (text.match(/^[a-zA-Z][\w+\-.]+:\//)) return false;

    // Probably typing a URL sans protocol (tag names don't allow ".")
    return !text.match(/^[^/]+\.[^/]/);
  };

  // --------------------------------------------------------------------------
  _suggestNotes = async (queryText, queryTagTexts) => {
    const { suggestNotes, value } = this.props;

    const result = await suggestNotes(queryText, queryTagTexts);

    // There's a specific case where the URL is a note URL (because the user previously linked to a note) and they
    // type # to link to a heading in the note, where we want to suggest the note corresponding to the URL as the
    // first result (so the headings in that note are searched)
    const queryNoteParams = noteParamsFromURL(queryText);
    if (queryNoteParams && result.notes.length > 0 && result.notes[0].url === urlFromNoteParams(queryNoteParams)) {
      return result;
    }

    result.notes.unshift({
      // For the purposes of finding headings, we want to treat this as the current note - i.e. if they typed `[[#` to
      // initiate the note link menu
      headingNoteURL: "#",
      icon: "link",
      // So this can be tested in NoteLinkMenu as "not having a note name"
      name: null,
      // This prevents the option showing that `tags` will be added when it is selected (since they're already present)
      tags: queryTagTexts.map(tagText => tagFromTagText(result.queryTagByText, tagText)),
      text: <span>Link to <span className="value">{ value }</span></span>,
      url: VALUE_URL_PREFIX + value,
    });

    return result;
  };
}
