import tippy from "tippy.js"
import React from "react"
import ReactDOM from "react-dom"

import BulletMenu from "lib/ample-editor/components/bullet-list-item/bullet-menu"
import EventDetail from "lib/ample-editor/components/bullet-list-item/event-detail"
import applyListItemCommand from "lib/ample-editor/lib/apply-list-item-command"
import { deviceSupportsHover } from "lib/ample-editor/lib/client-info"
import closePopups from "lib/ample-editor/lib/close-popups"
import { VISIBLE_LIST_ITEM_CLASS } from "lib/ample-editor/lib/collapsible-defines"
import {
  buildCompleteListItems,
  buildDeleteListItem,
  buildToggleBulletItemCheckListItem,
  buildUpdateListItemAttributes,
  listItemDepthFromSchema,
} from "lib/ample-editor/lib/list-item-commands"
import { listItemClassName, onListItemClick, onListItemTouchStart } from "lib/ample-editor/lib/list-item-view-util"
import {
  getExpandedTaskUUID,
  getHighlightedTaskUUID,
  setExpandedTaskUUID
} from "lib/ample-editor/plugins/check-list-item-plugin"
import { isPosCollapsibleNode } from "lib/ample-editor/plugins/collapsible-nodes-plugin"
import { formatDateTime } from "lib/ample-util/date"
import { minutesFromDuration, TASK_COMPLETION_MODE } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
const DEFAULT_TOOLTIP_CONTENT = "Click for bullet options";

// --------------------------------------------------------------------------
function getBulletListItem(editorView, getPos) {
  const { state: { doc, schema } } = editorView;

  const nodePos = getPos();
  if (typeof(nodePos) === "undefined") return {};

  let node = null;
  try {
    node = doc.nodeAt(nodePos);
  } catch (_error) {
    // RangeError: Position N outside of fragment
  }
  if (!node || node.type !== schema.nodes.bullet_list_item) return {};

  return { node, nodePos };
}

// --------------------------------------------------------------------------
export default class BulletListItemView {
  _bulletButton = null;
  _bulletMenuContainer = null;
  _bulletMenuIsOpen = false;
  _eventDetailContainer = null;
  _eventDetailExpander = null;
  _focusEditorOnEventDetailClose = false;
  _wasExpanded = false;
  _uuid = null;

  // --------------------------------------------------------------------------
  constructor(editorView, getPos, node) {
    this._uuid = node.attrs.uuid;

    this._closeEventDetail = this._closeEventDetail.bind(this, editorView, getPos);
    this._convertToTask = this._convertToTask.bind(this, editorView, getPos);
    this._crossOut = this._crossOut.bind(this, editorView, getPos);
    this._deleteNode = this._deleteNode.bind(this, editorView, getPos);
    this._updateAttributes = this._updateAttributes.bind(this, editorView, getPos);
    this.update = this.update.bind(this, editorView, getPos);

    // The full DOM structure of a bullet:
    // Outer wrapper: .bullet-list-item.indent-0.has-children this div defines the indent level
    // for the bullet, and dictates whether to expander icon (its before element). It possesses the full width of
    // the bullet element so it can receive a click event from the area left of the visible bullet
    // Inner wrapper: .visible-list-item this div houses the :before element whose ::marker is the visible bullet
    this.dom = document.createElement("div");

    this.dom.addEventListener("click", onListItemClick.bind(null, editorView, getPos));
    // WBH once saw Chrome recommend adding { passive: true } to this event, but testing that approach showed that it
    // allowed the cursor to move to the touched location, whereas this version keeps cursor in place (and keyboard
    // from popping up), as desired
    this.dom.addEventListener("touchstart", onListItemTouchStart.bind(null, editorView, getPos));

    this.contentDOM = document.createElement("div");
    this.contentDOM.className = VISIBLE_LIST_ITEM_CLASS;
    this.dom.appendChild(this.contentDOM);

    if (editorView.editable) {
      this._bulletButton = document.createElement("div");
      this._bulletButton.className = "bullet-button";
      // Necessary so cursor can't get placed before this element visually
      this._bulletButton.contentEditable = "false";
      this._bulletButton.addEventListener("click", this._onBulletButtonClick.bind(this, editorView, getPos));
      this.dom.appendChild(this._bulletButton);

      if (deviceSupportsHover) {
        tippy(this._bulletButton, {
          allowHTML: true,
          content: DEFAULT_TOOLTIP_CONTENT,
          delay: [ 1000, 100 ],
          touch: false,
        });
      }

      this._bulletMenuContainer = document.createElement("div");
      this._bulletMenuContainer.className = "bullet-menu-container";
      this._bulletMenuContainer.contentEditable = "false";
      this.dom.appendChild(this._bulletMenuContainer);

      this._eventDetailExpander = document.createElement("i");
      this._eventDetailExpander.className = "event-detail-expander material-icons";
      this._eventDetailExpander.contentEditable = "false";
      // This is necessary for ProseMirror/browsers to understand how to move the cursor from after the check list item
      // back into the item (e.g. using the left arrow key), informing them that this element is effectively zero-width
      this._eventDetailExpander.innerHTML = "&#65279;";
      this._eventDetailExpander.addEventListener(
        "click", this._onEventDetailExpanderClick.bind(this, editorView, getPos)
      );

      this.dom.appendChild(this._eventDetailExpander);

      this._eventDetailContainer = document.createElement("div");
      this._eventDetailContainer.className = "event-detail-container";
      this._eventDetailContainer.contentEditable = "false";
      this.dom.appendChild(this._eventDetailContainer);
    }

    this.update(node);
  }

  // --------------------------------------------------------------------------
  destroy() {
    if (this._bulletButton && this._bulletButton._tippy) {
      this._bulletButton._tippy.destroy();
    }

    if (this._bulletMenuContainer) ReactDOM.unmountComponentAtNode(this._bulletMenuContainer);
    if (this._eventDetailContainer) ReactDOM.unmountComponentAtNode(this._eventDetailContainer);
  }

  // --------------------------------------------------------------------------
  ignoreMutation(event) {
    // Need to ignore what happens in .bullet-button (tippy tooltip and click/menu events)
    if (!event.target || !this.contentDOM || !this.contentDOM.contains(event.target)) {
      // In Chrome, when there's a bullet list item at the end of the document, pressing the down arrow from it will
      // put the cursor in a strange position where it's actually in the bullet-menu-container. This is the only
      // selection event that gets passed here in that case, so we can tell ProseMirror _not_ to ignore it to get
      // ProseMirror to try and resolve the selection again and put the cursor somewhere reasonable.
      return event.type !== "selection";
    }

    return false;
  }

  // --------------------------------------------------------------------------
  stopEvent(event) {
    // Allow keyboard events in the menu to stay in the menu and not affect the document
    if (this._bulletMenuContainer && this._bulletMenuContainer.contains(event.target)) {
      return true;
    }

    if (this._eventDetailContainer && this._eventDetailContainer.contains(event.target)) {
      return true;
    }

    // Prevents a slight flicker of the node being selected on rapid expander clicks
    const isEventDetailExpanderClick = this._eventDetailExpander && (
      event.target === this._eventDetailExpander || this._eventDetailExpander.contains(event.target)
    );
    if (isEventDetailExpanderClick) {
      // Need to prevent default on mousedown so prosemirror doesn't set the selection and focus the editor
      if (event.type === "mousedown") event.preventDefault();

      return true;
    }
  }

  // --------------------------------------------------------------------------
  update(editorView, getPos, node) {
    if (node.type.name !== "bullet_list_item") return false;

    // ProseMirror's view will repurpose a previous HTMLElement during render for optimization; this causes
    // undesired animation of the old bullet's margin-left to new bullet's margin-left, so is prevented
    const { attrs: { scheduledAt, uuid } } = node;
    if (this._uuid !== uuid) return false;
    this.dom.setAttribute("data-uuid", uuid);

    const { state } = editorView;

    // Scheduled bullets piggyback on the expanded task UUID state
    const expandedTaskUUID = scheduledAt ? getExpandedTaskUUID(state) : null;
    const isExpanded = expandedTaskUUID && expandedTaskUUID === uuid;
    if (!this._wasExpanded && isExpanded) this._focusEditorOnEventDetailClose = editorView.hasFocus();
    this._wasExpanded = isExpanded;

    let className = `bullet-list-item${ listItemClassName(state, node, getPos) }`;
    if (isExpanded) className += " expanded";
    if (uuid && getHighlightedTaskUUID(state) === uuid) className += " highlighted";

    if (this._bulletButton) {
      if (scheduledAt) {
        const { attrs: { duration } } = node;

        className += " scheduled";

        let endsAt = scheduledAt;

        const durationMinutes = minutesFromDuration(duration)
        if (durationMinutes) endsAt += durationMinutes * 60;

        if (endsAt < Math.floor(Date.now() / 1000)) {
          className += " over";
        }

        if (this._bulletButton._tippy) {
          this._bulletButton._tippy.setContent(`Event scheduled for<br/>${ formatDateTime(scheduledAt * 1000) }`);
        }
      } else if (this._bulletButton._tippy) {
        this._bulletButton._tippy.setContent(DEFAULT_TOOLTIP_CONTENT);
      }
    }

    this.dom.className = className;

    if (this._eventDetailContainer) {
      if (isExpanded) {
        const { attrs: { duration, notify, repeat } } = node;

        ReactDOM.render(
          <EventDetail
            autoFocus
            close={ this._closeEventDetail }
            convertToTask={ this._convertToTask }
            crossOut={ this._crossOut }
            deleteListItem={ this._deleteNode }
            duration={ duration }
            notify={ notify }
            repeat={ repeat }
            scheduledAt={ scheduledAt }
            updateAttributes={ this._updateAttributes }
          />,
          this._eventDetailContainer
        );
      } else {
        ReactDOM.unmountComponentAtNode(this._eventDetailContainer);
      }
    }

    return true;
  }

  // --------------------------------------------------------------------------
  _applyListItemCommand = (editorView, menuPos, attributes, listItemType, commandName, commandOptions) => {
    this._closeBulletMenu();

    applyListItemCommand(editorView, menuPos, attributes, listItemType, commandName, commandOptions);
  };

  // --------------------------------------------------------------------------
  _closeBulletMenu = () => {
    this._bulletMenuIsOpen = false;
    this._bulletButton.classList.remove("open");
    this._bulletMenuContainer.classList.remove("open");
    ReactDOM.unmountComponentAtNode(this._bulletMenuContainer);
  };

  // --------------------------------------------------------------------------
  _closeEventDetail = (editorView, _getPos) => {
    const { dispatch, state } = editorView;

    const transaction = setExpandedTaskUUID(state.tr, null);
    dispatch(transaction);

    if (this._focusEditorOnEventDetailClose) editorView.focus();
  };

  // --------------------------------------------------------------------------
  _convertToTask = (editorView, getPos) => {
    const { dispatch, state } = editorView;

    const { node } = getBulletListItem(editorView, getPos);

    // Needs to be first position in the node
    const $pos = state.doc.resolve(getPos() + 1);

    buildToggleBulletItemCheckListItem($pos)(state, transaction => {
      if (node) {
        const { attrs: { uuid } } = node;
        setExpandedTaskUUID(transaction, uuid);
      }

      dispatch(transaction);
    });
  };

  // --------------------------------------------------------------------------
  _crossOut = (editorView, getPos) => {
    const { dispatch, state } = editorView;

    // Needs to be first position in the node
    const $pos = state.doc.resolve(getPos() + 1);

    const completeListItems = buildCompleteListItems({
      listItemPos: $pos.before(listItemDepthFromSchema(state.schema)),
      taskCompletionMode: TASK_COMPLETION_MODE.CROSS_OUT
    });

    completeListItems(state, dispatch);
  };

  // --------------------------------------------------------------------------
  _deleteNode = (editorView, getPos) => {
    const { node } = getBulletListItem(editorView, getPos);
    if (node) {
      const { dispatch, state } = editorView;
      const { attrs: { uuid } } = node;
      buildDeleteListItem(uuid)(state, dispatch);
    }
  };

  // --------------------------------------------------------------------------
  _onBulletButtonClick = (editorView, getPos, event) => {
    event.preventDefault();

    const { node: bulletListItem, nodePos } = getBulletListItem(editorView, getPos);

    if (bulletListItem.attrs.scheduledAt) {
      this._closeBulletMenu();
      this._onEventDetailExpanderClick(editorView, getPos, event);
    } else {
      this._bulletMenuIsOpen = !this._bulletMenuIsOpen;

      if (this._bulletMenuIsOpen) {
        closePopups(editorView);

        this._bulletButton.classList.add("open");
        this._bulletMenuContainer.classList.add("open");

        const containerRef = { current: this._bulletMenuContainer };

        ReactDOM.render(
          <BulletMenu
            // Some commands expect the position to be _in_ the node, not at the opening position, nor the opening
            // position of the paragraph child
            applyCommand={ this._applyListItemCommand.bind(this, editorView, nodePos + 2) }
            bulletListItem={ bulletListItem }
            close={ this._closeBulletMenu }
            containerRef={ containerRef }
            editorView={ editorView }
            isCollapsible={ isPosCollapsibleNode(editorView.state, nodePos) }
          />,
          this._bulletMenuContainer
        );
      } else {
        this._closeBulletMenu();
      }
    }
  };

  // --------------------------------------------------------------------------
  _onEventDetailExpanderClick = (editorView, getPos, event) => {
    event.preventDefault();

    const { node } = getBulletListItem(editorView, getPos);
    if (!node) return;

    const { attrs: { uuid } } = node;
    const { dispatch, state } = editorView;

    const expandedTaskUUID = getExpandedTaskUUID(state);
    const wasExpanded = expandedTaskUUID && expandedTaskUUID === uuid;

    const transaction = setExpandedTaskUUID(state.tr, wasExpanded ? null : uuid);
    dispatch(transaction);

    if (wasExpanded && this._focusEditorOnEventDetailClose) editorView.focus();
  };

  // --------------------------------------------------------------------------
  _updateAttributes = (editorView, getPos, updates) => {
    const { node } = getBulletListItem(editorView, getPos);
    if (!node) return;

    const { attrs: { uuid } } = node;

    const { dispatch, state } = editorView;
    buildUpdateListItemAttributes(uuid, updates)(state, dispatch);
  };
}
