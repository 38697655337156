import { listItemNodeFromPos } from "lib/ample-editor/lib/list-item-util"
import { pluginTaskFromTask } from "lib/ample-editor/lib/plugin-task-util"
import buildEditorContext from "lib/ample-editor/util/build-editor-context"
import PLUGIN_ACTION_TYPE from "lib/ample-util/plugin-action-type"

// --------------------------------------------------------------------------
export default async function getTaskOptionPluginActions(editorView, listItemCommandsMenuPos) {
  const { props: { hostApp: { getPluginActions } } } = editorView;

  let pluginActionPromises = null;

  const overrideSelection = {
    from: listItemCommandsMenuPos,
    head: listItemCommandsMenuPos,
    to: listItemCommandsMenuPos,
  };

  if (getPluginActions) {
    const { state: { doc } } = editorView;

    const $listItemCommandsMenuPos = doc.resolve(listItemCommandsMenuPos);
    const { attrs, content } = listItemNodeFromPos($listItemCommandsMenuPos);

    const checkEditorContext = buildEditorContext(editorView, null, overrideSelection);
    const checkPluginTask = pluginTaskFromTask({ ...attrs, content: content.toJSON() });

    pluginActionPromises = await getPluginActions(
      [ PLUGIN_ACTION_TYPE.TASK_OPTION ],
      checkEditorContext,
      checkPluginTask,
    );
  }

  return (pluginActionPromises || []).map(pluginActionPromise => pluginActionPromise.then(pluginAction => {
    if (!pluginAction) return null;

    const { run } = pluginAction;
    return {
      ...pluginAction,
      run: () => {
        const { state: { doc } } = editorView;

        const $listItemCommandsMenuPos = doc.resolve(listItemCommandsMenuPos);
        const { attrs, content } = listItemNodeFromPos($listItemCommandsMenuPos);

        const runEditorContext = buildEditorContext(editorView, null, overrideSelection);
        const runPluginTask = pluginTaskFromTask({ ...attrs, content: content.toJSON() });

        return run(runEditorContext, runPluginTask);
      },
    };
  }));
}
