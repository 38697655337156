import { dequal } from "dequal/lite"
import PropTypes from "prop-types"
import { chainCommands } from "prosemirror-commands"
import React, { createRef, useCallback, useContext, useEffect, useMemo, useRef } from "react"

import CompletedTasks from "lib/ample-editor/components/completed-tasks"
import TabBar from "lib/ample-editor/components/editor-tabs/tab-bar"
import HiddenTasks from "lib/ample-editor/components/hidden-tasks"
import ReferencingNotes from "lib/ample-editor/components/referencing-notes"
import EditorViewContext from "lib/ample-editor/contexts/editor-view-context"
import HostAppContext from "lib/ample-editor/contexts/host-app-context"
import { isApplePlatform } from "lib/ample-editor/lib/client-info"
import { buildRestoreCompletedTask } from "lib/ample-editor/commands/completed-task-commands"
import {
  redoWithRetainEditorFocus,
  undoInputRuleWithoutHistory,
  undoWithRetainEditorFocus,
} from "lib/ample-editor/lib/commands"
import EDITOR_TAB from "lib/ample-editor/lib/editor-tab"
import { deleteTasks, updateTaskAttributes, updateTaskContent } from "lib/ample-editor/lib/task-commands"
import TransactionMerger from "lib/ample-editor/lib/transaction-merger"
import {
  getExpandedTaskUUID,
  getTransactionSetsExpandedTaskUUID,
  setExpandedTaskUUID,
} from "lib/ample-editor/plugins/check-list-item-plugin"
import { setOpenLinkPosition } from "lib/ample-editor/plugins/link-plugin"
import { uuidFromDerivedUUID } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
function TasksSection(props) {
  const {
    checkListItemPluginState,
    Component,
    filePluginState,
    findPluginState,
    editorHasFocus,
    editorViewHadFocusOnMouseDownRef,
    readonly,
    tasks,
  } = props;

  const editorView = useContext(EditorViewContext);

  const componentRef = useRef();
  const haveMountedRef = useRef(false);
  useEffect(
    () => {
      // Skip initial mount
      if (!haveMountedRef.current) {
        haveMountedRef.current = true;
        return;
      }

      // When the editor (i.e. not the tasks section) gets focus, we want to close any rich footnotes that
      // are open in the tasks section
      if (editorHasFocus) {
        const { current: component } = componentRef;
        if (component) component.closeRichFootnote();
      }
    },
    [ editorHasFocus ]
  );

  // Cached so it doesn't cause re-renders
  const editorProps = useMemo(
    () => {
      const { props: { completedTasksNow, handleScrollToSelection, hostApp } } = editorView;

      return {
        completedTasksNow,
        handleScrollToSelection,
        hostApp,
        scrollMargin: 50,
        scrollThreshold: 50,
      };
    },
    []
  );

  const dispatchChanges = useCallback(
    (changesByUUID, meta, changesFromGroupId) => {
      const { dispatch, state } = editorView;

      const uuids = Object.keys(changesByUUID);

      const transaction = Object.keys(meta).reduce(
        (tr, key) => tr.setMeta(key, meta[key]),
        // We want to close any RF in the main editor, or dispatching a transaction may open it
        editorView.hasFocus() ? state.tr : setOpenLinkPosition(state.tr, null)
      );

      // If we're making changes to hidden/completed tasks, the entire outer document will re-render (because we've
      // changed doc.attrs, which is considered a change worthy of a full redraw in Chrome) and any check list items with
      // open task details will re-render from scratch. This can be relatively slow (30 ms or more), resulting in
      // very slow typing in hidden tasks if any TaskDetails are expanded.
      if (uuids.length > 0 && !getTransactionSetsExpandedTaskUUID(transaction)) {
        // Keep the task detail open if the user is changing the expanded task
        const expandedTaskUUID = getExpandedTaskUUID(state);
        if (expandedTaskUUID && !(expandedTaskUUID in changesByUUID)) {
          setExpandedTaskUUID(transaction, null);
        }
      }

      // There's a category of changes that can be made while the cursor is in the outer editorView (e.g. checking off
      // an item), in which case we'll be stealing the focus, but will want to restore it after
      let canReFocusEditorView = false;

      const transactionMerger = new TransactionMerger(state, transaction);

      uuids.forEach(uuid => {
        let { added, content, deleted, groupId, ...changes } = changesByUUID[uuid];

        if (added) {
          // Tasks that are added just correspond to tasks being dragged from the main editor, in which case the UUID
          // will have been rotated (by the drag+drop code from the main editor), so we want to rotate the UUID back to
          // match the source task that was dragged
          uuid = uuidFromDerivedUUID(uuid);

          groupId = added.groupId;
        }

        if (deleted) {
          transactionMerger.apply(deleteTasks([ uuid ]));
          return;
        }

        if (typeof(groupId) !== "undefined") {
          changes = { ...changes, ...changesFromGroupId(uuid, groupId) };
        }

        if (typeof(content) !== "undefined") {
          transactionMerger.apply(updateTaskContent(uuid, content));
        }

        if (changes.completedAt === null || changes.crossedOutAt === null || changes.dismissedAt === null) {
          transactionMerger.apply(buildRestoreCompletedTask(uuid));
          canReFocusEditorView = true;

          delete changes.completedAt;
          delete changes.crossedOutAt;
          delete changes.dismissedAt;
        }

        if (Object.keys(changes).length === 0) return;

        transactionMerger.apply(updateTaskAttributes(uuid, changes));
        canReFocusEditorView = true;
      });

      // Note that we always want to dispatch a transaction to the outer editor, even if there are no changes, as this
      // will be called when the selection in the TasksEditor changes, and we want the outer editor to update toolbar
      // state when that happens
      dispatch(transactionMerger.transaction);

      if (canReFocusEditorView && editorViewHadFocusOnMouseDownRef.current && !editorView.hasFocus()) {
        editorView.focus();
      }
    },
    []
  );

  return (
    <Component
      checkListItemPluginState={ checkListItemPluginState }
      dispatchChanges={ dispatchChanges }
      editorProps={ editorProps }
      filePluginState={ filePluginState }
      findPluginState={ findPluginState }
      parentEditorView={ editorView }
      readonly={ readonly }
      ref={ componentRef }
      tasks={ tasks }
    />
  );
}

// --------------------------------------------------------------------------
export default class EditorTabs extends React.Component {
  static contextType = EditorViewContext;

  // --------------------------------------------------------------------------
  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  _editorViewHadFocusOnMouseDownRef = createRef();
  _haveUnmounted = false;

  state = {
    hasError: false,
    referencedNote: null,
    referencingNotes: null,
  };

  // --------------------------------------------------------------------------
  componentDidCatch(error, _errorInfo) {
    if (window.Sentry) {
      window.Sentry.captureException(error);
    }
  }

  // --------------------------------------------------------------------------
  componentDidMount() {
    this._refreshReferencingNotes();
  }

  // --------------------------------------------------------------------------
  componentDidUpdate(prevProps) {
    const { currentTab } = this.props;

    if (prevProps.currentTab !== currentTab && currentTab === EDITOR_TAB.REFERENCES) {
      this._refreshReferencingNotes();
    }
  }

  // --------------------------------------------------------------------------
  componentWillUnmount() {
    this._haveUnmounted = true;
  }

  // --------------------------------------------------------------------------
  render() {
    const { currentTab, expanded, hiddenTasks, setCurrentTab, setExpanded } = this.props;
    const { hasError, referencingNotes } = this.state;

    if (hasError) {
      return (
        <div className="editor-tabs">
          <div className="error-message">
            Failed to load content
          </div>
        </div>
      );
    }

    return (
      <div
        className={ `editor-tabs${ expanded ? "" : " collapsed" }` }
        onKeyDown={ this._onKeyDown }
        onMouseDown={ this._onMouseDown }
      >
        <TabBar
          currentTab={ currentTab }
          expanded={ expanded }
          hiddenTasksCount={ hiddenTasks.length }
          referencingNotesCount={ referencingNotes ? referencingNotes.length : 0 }
          setCurrentTab={ setCurrentTab }
          setExpanded={ setExpanded }
        />

        { expanded ? this._renderTabContent() : null }
      </div>
    );
  }

  // --------------------------------------------------------------------------
  shouldComponentUpdate(nextProps, nextState) {
    const {
      checkListItemPluginState,
      completedTasks,
      currentTab,
      editorHasFocus,
      expanded,
      filePluginState,
      findPluginState,
      hiddenTasks,
      readonly,
    } = this.props;
    const { referencingNotes } = this.state;

    return nextProps.completedTasks !== completedTasks ||
      nextProps.currentTab !== currentTab ||
      nextProps.editorHasFocus !== editorHasFocus ||
      nextProps.expanded !== expanded ||
      nextProps.filePluginState !== filePluginState ||
      nextProps.findPluginState !== findPluginState ||
      nextProps.hiddenTasks !== hiddenTasks ||
      nextProps.readonly !== readonly ||
      // `referencedNote` is assumed to change only if `referencingNotes` changes
      nextState.referencingNotes !== referencingNotes ||
      (checkListItemPluginState !== nextProps.checkListItemPluginState &&
        !dequal(checkListItemPluginState, nextProps.checkListItemPluginState));
  }

  // --------------------------------------------------------------------------
  _onKeyDown = event => {
    // We want to intercept undo/redo before the tasks editor can handle it so we can redirect to the top-level editor
    // view instead, because the tasks-editor's history state doesn't include changes made to the outer editor, e.g.
    // when checking a completed task to un-complete it).
    // Note this covers all the keys mapped in tasks-keymap-plugin.
    if (event.key === "z" || event.key === "Z") {
      if (isApplePlatform ? event.metaKey : event.ctrlKey) {
        event.preventDefault();

        const command = event.shiftKey
          ? redoWithRetainEditorFocus
          : chainCommands(
            undoInputRuleWithoutHistory,
            undoWithRetainEditorFocus,
          );

        const { dispatch, state } = this.context;
        command(state, dispatch);
      }
    } else if (event.key === "y" && !isApplePlatform && event.ctrlKey) {
      event.preventDefault();

      const { dispatch, state } = this.context;
      redoWithRetainEditorFocus(state, dispatch);
    }
  };

  // --------------------------------------------------------------------------
  _onMouseDown = () => {
    this._editorViewHadFocusOnMouseDownRef.current = this.context.hasFocus();
  };

  // --------------------------------------------------------------------------
  _refreshReferencingNotes = async () => {
    const { setHaveReferencingNotes } = this.props;

    const { props: { hostApp: { fetchReferencingNotes } } } = this.context;

    // In test, this function might be mocked (returning `undefined`)
    const { referencedNote, referencingNotes } = (await fetchReferencingNotes("#")) || {};

    // This is mainly something that happens in test, but if the editor immediately unmounts, we don't want to
    // try to update the state.
    if (this._haveUnmounted) return;

    this.setState({ referencedNote, referencingNotes });

    setHaveReferencingNotes(referencingNotes && referencingNotes.length > 0);
  };

  // --------------------------------------------------------------------------
  _renderTabContent() {
    const {
      checkListItemPluginState,
      completedTasks,
      currentTab,
      filePluginState,
      findPluginState,
      editorHasFocus,
      hiddenTasks,
      readonly,
    } = this.props;

    switch (currentTab) {
      case EDITOR_TAB.COMPLETED: {
        if (completedTasks.length === 0) {
          return (<div className="no-tasks-message">You have no completed tasks.</div>);
        }

        return (
          <TasksSection
            checkListItemPluginState={ checkListItemPluginState }
            Component={ CompletedTasks }
            editorHasFocus= { editorHasFocus }
            editorViewHadFocusOnMouseDownRef={ this._editorViewHadFocusOnMouseDownRef }
            filePluginState={ filePluginState }
            findPluginState={ findPluginState }
            readonly={ readonly }
            tasks={ completedTasks }
          />
        );
      }

      case EDITOR_TAB.HIDDEN: {
        if (hiddenTasks.length === 0) {
          return (<div className="no-tasks-message">You have no hidden tasks.</div>);
        }

        return (
          <TasksSection
            checkListItemPluginState={ checkListItemPluginState }
            Component={ HiddenTasks }
            editorHasFocus= { editorHasFocus }
            editorViewHadFocusOnMouseDownRef={ this._editorViewHadFocusOnMouseDownRef }
            filePluginState={ filePluginState }
            findPluginState={ findPluginState }
            readonly={ readonly }
            tasks={ hiddenTasks }
          />
        );
      }

      case EDITOR_TAB.REFERENCES: {
        const { props: { hostApp, initialEditorTab } } = this.context;
        const { referencedNote, referencingNotes } = this.state;

        const initialFilterParams = initialEditorTab && initialEditorTab.name === EDITOR_TAB.REFERENCES
          ? initialEditorTab.filterParams : null;

        return (
          <HostAppContext.Provider value={ hostApp }>
            <ReferencingNotes
              initialFilterParams={ initialFilterParams }
              referencedNote={ referencedNote }
              referencingNotes={ referencingNotes }
              refreshReferencingNotes={ this._refreshReferencingNotes }
            />
          </HostAppContext.Provider>
        );
      }

      default:
        return null;
    }
  }
}

EditorTabs.propTypes = {
  checkListItemPluginState: PropTypes.object,
  completedTasks: PropTypes.array,
  currentTab: PropTypes.oneOf(Object.values(EDITOR_TAB)),
  editorHasFocus: PropTypes.bool,
  expanded: PropTypes.bool,
  filePluginState: PropTypes.object,
  findPluginState: PropTypes.object,
  hiddenTasks: PropTypes.array,
  readonly: PropTypes.bool,
  setCurrentTab: PropTypes.func.isRequired,
  setExpanded: PropTypes.func.isRequired,
  setHaveReferencingNotes: PropTypes.func.isRequired,
};
