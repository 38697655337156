import { clamp, compact } from "lodash"
import memoize from "memoize-one"
import PropTypes from "prop-types"
import { history } from "prosemirror-history"
import { dropPoint } from "prosemirror-transform"
import { EditorState, TextSelection } from "prosemirror-state"
import { EditorView } from "prosemirror-view"
import React from "react"
import { v4 as uuidv4 } from "uuid"

import EditorViewWrapper from "lib/ample-editor/components/editor-view-wrapper"
import taskUpdatesFromDocChanges from "lib/ample-editor/components/tasks-editor/task-updates-from-doc-changes"
import tasksCompletionPlugin from "lib/ample-editor/components/tasks-editor/tasks-completion-plugin"
import tasksKeymapPlugin from "lib/ample-editor/components/tasks-editor/tasks-keymap-plugin"
import tasksReadonlyContentPlugin from "lib/ample-editor/components/tasks-editor/tasks-readonly-content-plugin"
import tasksSchema from "lib/ample-editor/components/tasks-editor/tasks-schema"
import closePopups from "lib/ample-editor/lib/close-popups"
import { updateLocalFileURLs } from "lib/ample-editor/lib/file-commands"
import findCheckListItem from "lib/ample-editor/lib/find-check-list-item"
import findProseMirrorAncestor from "lib/ample-editor/lib/find-prose-mirror-ancestor"
import handlePasteFiles from "lib/ample-editor/lib/handle-paste-files"
import { buildHighlightListItem } from "lib/ample-editor/lib/list-item-commands"
import shouldSkipIosEnterTransaction from "lib/ample-editor/lib/should-skip-ios-enter-transaction"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"
import checkListItemPlugin, {
  getCheckListItemPluginState,
  setSelectedTaskUUID,
} from "lib/ample-editor/plugins/check-list-item-plugin"
import createCodePlugin from "lib/ample-editor/plugins/code-plugin"
import createCompletedCheckListItemsPlugin from "lib/ample-editor/plugins/completed-check-list-items-plugin"
import createDateSuggestionPlugin from "lib/ample-editor/plugins/date-suggestion-plugin"
import createEmojiPlugin from "lib/ample-editor/plugins/emoji-popper"
import createExpressionPlugin from "lib/ample-editor/plugins/expression-plugin"
import filePlugin, { findLocalFileNodes } from "lib/ample-editor/plugins/file-plugin"
import {
  createFindPlugin,
  domElementForFindMatch,
  setFindPluginIndexDelta,
  setFindPluginQuery,
} from "lib/ample-editor/plugins/find-plugin"
import createInputRulesPlugin from "lib/ample-editor/plugins/input-rules-plugin"
import createLinkPlugin, { getOpenLinkPos, setOpenLinkPosition } from "lib/ample-editor/plugins/link-plugin"
import createListItemCommandsMenuPlugin from "lib/ample-editor/plugins/list-item-commands-menu-plugin"
import createSelectionMenuPlugin from "lib/ample-editor/plugins/selection-menu-plugin"
import targetViewPlugin, {
  registerTargetView,
  TARGET_VIEW_TYPE_TASKS_EDITOR,
} from "lib/ample-editor/plugins/target-view-plugin"
import createToolbarPlugin, { getAvailableCommands } from "lib/ample-editor/plugins/toolbar-plugin"
import nodeViews from "lib/ample-editor/views"
import TasksGroupView from "lib/ample-editor/views/tasks-group-view"
import { isDoneFromTask } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
// (0) doc -> (1) tasks_group -> (2) check_list_item
const TASKS_GROUP_DEPTH = 1;
const CHECK_LIST_ITEM_DEPTH = 2;

// --------------------------------------------------------------------------
function applyTaskSelectionState(editorState, taskSelectionState) {
  const { anchorOffset, bookmark, headOffset, taskUUID } = taskSelectionState;

  if (taskUUID) {
    const { doc } = editorState;

    const { node, nodePos } = findCheckListItem(doc, taskUUID) || {};
    if (node && nodePos) {
      // We want the opening position of the check list item here, rather than the position before the check list item,
      // which is what is returned by `findCheckListItem`
      const checkListItemPos = nodePos + 1;

      try {
        const anchor = checkListItemPos + anchorOffset;
        const $anchor = doc.resolve(anchor);

        // It's weird if the task gets moved to the top of the group when the user had the head in the task above the
        // anchor's task and the head is placed in the last task of the previous group
        const head = clamp(checkListItemPos + headOffset, $anchor.start(TASKS_GROUP_DEPTH), $anchor.end(TASKS_GROUP_DEPTH));

        editorState.selection = TextSelection.between(doc.resolve(anchor), doc.resolve(head), 1);
        return;
      } catch (_error) {
        // Out of bounds (doc too short) or no longer valid
      }
    }
  }

  if (bookmark) {
    // Falling back to trying to maintain the same position in the overall document
    try {
      editorState.selection = bookmark.resolve(editorState.doc);
    } catch (_error) {
      // Likely just a node being removed while selected, we'll leave the selection at start
    }
  }
}

// --------------------------------------------------------------------------
function createEditorState(checkListItemPluginState, doc, plugins, taskSelectionState = null) {
  const config = { plugins, schema: tasksSchema };

  // ProseMirror will complain (warning) if the text selection is not in a valid text position (e.g. the opening
  // position of the paragraph in the first check-list-item, i.e. one deeper than the check-list-item itself) but
  // will hard-fail if the selection is out of range for an empty document
  let selectionPos = CHECK_LIST_ITEM_DEPTH + 1;
  if (doc.content.every(tasksGroup => tasksGroup.content.length === 0)) {
    selectionPos = 0;
  }

  const json = { doc, selection: { anchor: selectionPos, head: selectionPos, type: "text" } };
  const pluginFields = {};

  if (checkListItemPluginState) {
    json.checkListItemPluginState = checkListItemPluginState;
    pluginFields.checkListItemPluginState = checkListItemPlugin;
  }

  const editorState = EditorState.fromJSON(config, json, pluginFields);

  if (taskSelectionState) {
    applyTaskSelectionState(editorState, taskSelectionState);
  }

  // ProseMirror will re-create plugin views if the plugin array changes between EditorStates (when calling
  // EditorView.updateState), but creating the editor state creates a new array, so we want to ensure it stays as
  // the same reference.
  editorState.config.plugins = plugins;

  return editorState;
}

// --------------------------------------------------------------------------
function docFromTasksWithGroup(tasksWithGroup, completedTasks = []) {
  const tasksGroups = [];

  const noteByTaskUUID = {};

  tasksWithGroup.forEach(({ groupClassName, groupHref, groupIcon, groupId, groupName, tasks }) => {
    tasksGroups.push({
      type: "tasks_group",
      attrs: {
        className: groupClassName,
        empty: tasks.length === 0,
        href: groupHref,
        icon: groupIcon,
        id: groupId,
        name: groupName,
      },
      content: tasks.map(({ attrs, content, note }) => {
        if (note) noteByTaskUUID[attrs.uuid] = note;

        return { attrs, content, type: "check_list_item" };
      }),
    });
  });

  return {
    doc: { type: "doc", attrs: { completedTasks }, content: tasksGroups },
    noteByTaskUUID,
  };
}

// --------------------------------------------------------------------------
// When the document changes, we want to keep the selection in the same place relative to the task the selection was
// in. The task may have changed position in the document, so we'll create a selection state that we can later use to
// place the selection in the correct task, wherever it may be.
function getTaskSelectionState(state) {
  const { selection, selection: { $anchor, head } } = state;

  // If the user has intentionally selected across a group boundary, we'll leave it their selection in the same location
  // in the document, as we don't really know what they would desire the resulting selection to be (and it's weird if
  // we shift a task-specific selection to cross a group boundary when it didn't before).
  if ($anchor.sharedDepth(head) >= TASKS_GROUP_DEPTH) {
    try {
      const checkListItemNode = $anchor.node(CHECK_LIST_ITEM_DEPTH);
      if (checkListItemNode && checkListItemNode.type === tasksSchema.nodes.check_list_item) {
        const { attrs: { uuid } } = checkListItemNode;

        const nodePos = $anchor.start(CHECK_LIST_ITEM_DEPTH);

        return {
          anchorOffset: $anchor.pos - nodePos,
          bookmark: selection.getBookmark(),
          headOffset: head - nodePos,
          taskUUID: uuid,
        };
      }
    } catch (_error) {
      // There are a number of reasons the position might be invalid (out of date/etc)
    }
  }

  return { bookmark: selection.getBookmark() };
}

// --------------------------------------------------------------------------
function groupOrderChanged(tasksWithGroup, tasksWithGroupWas) {
  const groupIds = tasksWithGroup.map(({ groupId }) => groupId);
  const groupIdsWas = tasksWithGroupWas.map(({ groupId }) => groupId);

  return !!groupIds.find((groupId, index) => groupIdsWas[index] !== groupId);
}

// --------------------------------------------------------------------------
export default class TasksEditor extends React.PureComponent {
  static propTypes = {
    checkListItemPluginState: PropTypes.object,
    disableTaskCompletion: PropTypes.bool,
    dispatchChanges: PropTypes.func.isRequired,
    editorProps: PropTypes.shape({
      hostApp: PropTypes.shape({
        cloneNodes: PropTypes.func,
        fetchNoteContent: PropTypes.func,
        fetchTask: PropTypes.func,
        linkNote: PropTypes.func,
        mountPluginEmbed: PropTypes.func,
        openAttachment: PropTypes.func,
        openNoteLink: PropTypes.func,
        startAttachmentUpload: PropTypes.func,
        startMediaUpload: PropTypes.func,
        suggestNotes: PropTypes.func,
      }).isRequired,
      inlineCheckListItemDetailsType: PropTypes.string,
      onPopupPositioned: PropTypes.func,
      onTaskGroupHeadingClick: PropTypes.func,
      overrideListItemCommandMenuListItemType: PropTypes.string,
      renderTaskExpander: PropTypes.func,
    }).isRequired,
    filePluginState: PropTypes.object,
    includeSourceNotes: PropTypes.bool,
    // If provided, this editor is understood to be embedded in a parent editor, so the parent editor may have some
    // sources of truth for various plugins.
    parentEditorView: PropTypes.instanceOf(EditorView),
  };

  state = {
    dragging: null,
  };

  _completedCheckListItemsPlugin = null;
  _draggingResetTimeout = null;
  _editorState = null;
  _editorView = null;
  _emojiPlugin = null;
  _historyPlugin = null;
  _inDispatch = false;
  _linkPlugin = null;
  _nodeViews = null;
  _noteByTaskUUID = {};
  _toolbarPlugin = null;
  _unmounted = false;

  // --------------------------------------------------------------------------
  constructor(props) {
    super(props);

    const {
      checkListItemPluginState,
      hideSelectionMenu,
      parentEditorView,
      readonlyContent,
      tasksWithGroup,
    } = props;

    this._completedCheckListItemsPlugin = createCompletedCheckListItemsPlugin();
    this._emojiPlugin = createEmojiPlugin();
    this._findPlugin = createFindPlugin();
    this._historyPlugin = history();
    this._linkPlugin = createLinkPlugin({ disableEditing: readonlyContent });
    this._nodeViews = {
      ...nodeViews(),
      tasks_group: (node, editorView, getPos) => new TasksGroupView(editorView, getPos, node),
    };

    // This can either be used in the context of an outer editor (i.e. editing hidden/completed tasks) or as a fully-
    // standalone editor. In the latter case, we need to handle all plugin state internally, rather than relying on
    // some outer editor for common ample-editor functionality.

    const plugins = compact([
      createInputRulesPlugin(tasksSchema),
      createCodePlugin(tasksSchema),
      this._emojiPlugin, // Needs to be before keymap so it can handle keys first
      this._historyPlugin,
      this._linkPlugin, // Needs to be before keymap so it can handle keys first
      createListItemCommandsMenuPlugin(), // Needs to be before keymap so it can handle keys first
      createDateSuggestionPlugin(), // Needs to be before keymap so it can handle keys first
      // Needs to be before keymap so it can handle keys first
      createSelectionMenuPlugin({
        // In non-standalone mode, we don't have a toolbar plugin to calculate state for the selection menu to consume,
        // so need to get it from the parent editor. Note that we can still dispatch toolbar commands to our editor
        // view, as the relevant metadata from the transactions get passed up to the parent editor view to handle.
        getAvailableToolbarCommands: parentEditorView ? () => getAvailableCommands(parentEditorView.state) : null,
        hideSelectionMenu,
      }),
      createExpressionPlugin(),
      tasksKeymapPlugin(tasksSchema),
      // Should be after linkPlugin so it can get state from it, but before toolbar and checkListItem plugin so it can
      // set state for them
      parentEditorView ? null : targetViewPlugin,
      checkListItemPlugin,
      this._completedCheckListItemsPlugin,
      parentEditorView || props.disableTaskCompletion ? null : tasksCompletionPlugin,
      filePlugin,
      this._findPlugin,
      // Should be last, since it filters transactions from other plugins
      readonlyContent ? tasksReadonlyContentPlugin : null,
    ]);

    if (!parentEditorView) {
      this._toolbarPlugin = createToolbarPlugin({ hideToolbar: props.hideToolbar });
      plugins.push(this._toolbarPlugin); // Should be last so it can reflect latest state from other plugins
    }

    const { doc, noteByTaskUUID } = docFromTasksWithGroup(tasksWithGroup);
    this._editorState = createEditorState(checkListItemPluginState, doc, plugins);
    this._noteByTaskUUID = noteByTaskUUID;
  }

  // --------------------------------------------------------------------------
  closeRichFootnote() {
    if (!this._editorView) return;

    const { dispatch, state } = this._editorView;

    // Note we need to check that this tasks-editor has an open RF, as the transaction meta will get passed to an
    // outer editor (in non-standalone tasks-editors) such that it will close any open rich footnotes in the outer
    // editor, which is not desirable.
    if (getOpenLinkPos(state) !== null) {
      dispatch(setOpenLinkPosition(state.tr, null).setMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY, false));
    }
  }

  // --------------------------------------------------------------------------
  closePopups() {
    if (this._editorView) closePopups(this._editorView);
  }

  // --------------------------------------------------------------------------
  componentDidUpdate(prevProps, prevState) {
    let { checkListItemPluginState, filePluginState } = this.props;
    const { tasksWithGroup } = this.props;
    const {
      checkListItemPluginState: checkListItemPluginStateWas,
      filePluginState: filePluginStateWas,
      tasksWithGroup: tasksWithGroupWas,
    } = prevProps;

    const tasksWithGroupChanged = tasksWithGroup !== tasksWithGroupWas;
    if (tasksWithGroupChanged || checkListItemPluginState !== checkListItemPluginStateWas || filePluginState !== filePluginStateWas) {
      // TODO (-ish): the ideal way to handle this would be to create a transform that changes the old tasksWithGroup
      //  into the new tasksWithGroup, then apply that (with "addToHistory" set to false), allowing history to work
      //  across external changes. The transaction would also need a flag so _dispatchTransaction ignores it (handling
      //  the EditorState.apply + internal state updates here). That would allow undo/redo to work more seamlessly,
      //  even if the order/presence of groups changes.

      if (checkListItemPluginState && this._editorView && this._editorView.hasFocus()) {
        // While much of the checkListItemPlugin state allows reference to checkListItems that aren't in the document,
        // the selectedTaskUUID is calculated based on the document, so we can't use the outer editor's version as is
        const { selectedTaskUUID } = getCheckListItemPluginState(this._editorState);
        checkListItemPluginState = { ...checkListItemPluginState, selectedTaskUUID };
      }

      const { doc, noteByTaskUUID } = docFromTasksWithGroup(tasksWithGroup, this._editorState.doc.attrs.completedTasks);

      let editorState = createEditorState(
        checkListItemPluginState,
        doc,
        this._editorState.plugins,
        getTaskSelectionState(this._editorState),
      );

      // A task can be highlighted in the outer editor, but we need to reflect that highlight inside this editor if
      // the task is present, so it can be highlighted + scrolled into view.
      if (checkListItemPluginState && checkListItemPluginStateWas && this._editorView) {
        const { highlightedTaskUUID } = checkListItemPluginState;
        if (highlightedTaskUUID && highlightedTaskUUID !== checkListItemPluginStateWas.highlightedTaskUUID) {
          buildHighlightListItem(highlightedTaskUUID)(editorState, transaction => {
            editorState = editorState.apply(transaction);
          });
        }
      }

      // We want to retain plugin state even though we now have a new EditorState. While some plugins provide a
      // toJSON/fromJSON, not all do, and code this is basically what toJSON/pluginFields/fromJSON ends up doing anyway,
      // but this retains some additional state (e.g. decorations in linkPlugin) that we'd like to retain, which doesn't
      // survive a JSON round-trip (and some plugins don't even have the JSON functions defined).
      editorState.plugins.forEach(plugin => {
        const pluginKey = plugin.spec.key;
        if (!pluginKey) return;

        // We want to update the toolbar plugin state last, as it examines the state of other plugins
        if (plugin === this._toolbarPlugin) return;

        // Plugin state came from props, so we don't want to retain the editor's plugin state
        if (plugin === checkListItemPlugin && checkListItemPluginState) return;

        if (plugin === filePlugin && filePluginState) {
          editorState[pluginKey.key] = filePluginState;
          return;
        }

        if (plugin === this._historyPlugin) {
          if (this.props.parentEditorView) {
            // We can't mess with the history plugin if this editor is passing off history to a parent editor, as there
            // are some cases where undo/redo can be double-applied if history state is maintained in multiple plugins.
            return;
          } else {
            // There's a special case when the order of the task groups changes. We could _maybe_ handle this by
            // generating step maps to describe the series of deletions and insertions required to move the groups
            // around, but it's unclear if that would need to be applied to the history plugin or to the editorState
            // as a separate transaction, or... something else - and it's a pretty complex approach. We'll take the
            // less ideal approach of clearing history when that happens
            if (tasksWithGroupChanged && groupOrderChanged(tasksWithGroup, tasksWithGroupWas)) {
              editorState[pluginKey.key] = this._historyPlugin.spec.state.init();
              return;
            }
          }
        }

        editorState[pluginKey.key] = this._editorState[pluginKey.key];
      });

      if (this._toolbarPlugin) {
        const pluginKey = this._toolbarPlugin.spec.key;
        editorState[pluginKey.key] = this._toolbarPlugin.spec.state.apply(
          editorState.tr,
          this._editorState[pluginKey.key],
          this._editorState,
          editorState
        );
      } else {
        // TODO: copy state from parentEditorView
      }

      // This needs to be in place before updating the EditorView, so the check-list-item view can access it
      this._noteByTaskUUID = noteByTaskUUID;

      if (this._editorView !== null) {
        this._editorView.updateState(editorState);
      }

      this._editorState = editorState;
    }

    // EditorViewWrapper doesn't ever re-render, so we need to update the prosemirror editor's className manually
    if (this.state.dragging !== prevState.dragging || this.props.readonly !== prevProps.readonly) {
      this._editorView.setProps({ attributes: { class: this._className() } });
    }
  }

  // --------------------------------------------------------------------------
  componentWillUnmount() {
    this._unmounted = true;
    clearTimeout(this._draggingResetTimeout);
  }

  // --------------------------------------------------------------------------
  deleteDraggedSlice() {
    if (this._editorView === null) return;
    if (!this._editorView.dragging || !this._editorView.dragging.slice) return;

    const { dispatch, state } = this._editorView;
    dispatch(state.tr.deleteSelection());
  }

  // --------------------------------------------------------------------------
  // Given `callback` will be called when the current match index or total matches count change
  find(query, callback = null, { currentIndex = null } = {}) {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      dispatch(setFindPluginQuery(state.tr, callback, query, { currentIndex }));
      return true;
    }

    return false;
  }

  // --------------------------------------------------------------------------
  findNext() {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      dispatch(setFindPluginIndexDelta(state.tr, 1));
      return true;
    }

    return false;
  }

  // --------------------------------------------------------------------------
  findPrevious() {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      dispatch(setFindPluginIndexDelta(state.tr, -1));
      return true;
    }

    return false;
  }

  // --------------------------------------------------------------------------
  getDOMElementForFindMatch() {
    return this._editorView ? domElementForFindMatch(this._editorView) : null;
  }

  // --------------------------------------------------------------------------
  hasLocalFileReference(uuid) {
    const nodes = findLocalFileNodes(this._editorState, uuid);
    return nodes.length > 0;
  }

  // --------------------------------------------------------------------------
  highlightListItem(uuid) {
    if (this._editorView === null) return false;

    // The editor needs to be focused for scrollIntoView to work (called in `highlightListItem`)
    this._editorView.focus();

    const { dispatch, state } = this._editorView;
    buildHighlightListItem(uuid)(state, dispatch);

    return true;
  }

  // --------------------------------------------------------------------------
  render() {
   const { editorProps, includeSourceNotes } = this.props;

    return (
      <EditorViewWrapper
        className={ this._className() }
        dispatchTransaction={ this._dispatchTransaction }
        editorProps={ this._wrapEditorProps(editorProps, includeSourceNotes) }
        editorState={ this._editorState }
        isEditable={ this._isEditable }
        nodeViews={ this._nodeViews }
        onBlur={ this._onBlur }
        onFocus={ this._onFocus }
        onPaste={ this._onPaste }
        setEditorView={ this._setEditorView }
      />
    );
  }

  // --------------------------------------------------------------------------
  replaceLocalFileURLs(urlByUUID) {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      updateLocalFileURLs(urlByUUID)(state, dispatch);
    }
  }

  // --------------------------------------------------------------------------
  taskUUIDAtCursor() {
    const { selection: { head } } = this._editorState;
    return this.taskUUIDAtPos(head);
  }

  // --------------------------------------------------------------------------
  taskUUIDAtPos(pos) {
    const { doc } = this._editorState;

    try {
      const $pos = doc.resolve(pos);
      const node = $pos.node(CHECK_LIST_ITEM_DEPTH);
      if (!node || node.type !== tasksSchema.nodes.check_list_item) return null;

      const { attrs: { uuid } } = node;
      return uuid;
    } catch (_error) {
      // There are a number of reasons the position might be invalid (out of date/etc)
      return null;
    }
  }

  // --------------------------------------------------------------------------
  _className() {
    const { readonly } = this.props;
    const { dragging } = this.state;

    return `tasks-editor${ readonly ? " readonly" : "" }${ dragging ? " dragging" : "" }`;
  }

  // --------------------------------------------------------------------------
  _dispatchTransaction = transaction => {
    const { doc: docWas } = this._editorState;

    // If `dispatchChanges` removes the last task in this editor, the outer component may unmount it entirely, which
    // - depending on the timing vs user input - prosemirror may read as a DOM mutation when the check-list-item view
    // runs `destroy` and removes the dom node, resulting in a transaction being dispatched incorrectly.
    if (this._unmounted) return;

    // Handling for iOS 14+ enter key issue
    if (shouldSkipIosEnterTransaction(this._editorView, transaction)) return;

    const hasFocus = this._editorView ? this._editorView.hasFocus() : false;
    transaction.setMeta(TRANSACTION_META_KEY.HAS_FOCUS, hasFocus);

    if (this.props.readonly) transaction.setMeta(TRANSACTION_META_KEY.READONLY, true);

    // In some cases, we may want to dispatch a transaction from an onBlur handler, but that blur itself might be
    // in response to a click event (or some other event), which would result in the blur potentially happening in
    // the view's updateState below. We'll simply drop any transactions dispatched in those cases, as the user
    // _probably_ doesn't want them (loosely indicated by interacting with something else that causes a transaction to
    // cause the blur).
    if (this._inDispatch) {
      transaction.setMeta(TRANSACTION_META_KEY.REJECTED, true);
      return;
    }
    this._inDispatch = true;

    const { beforeDispatchTransaction, parentEditorView } = this.props;

    try {
      if (beforeDispatchTransaction) beforeDispatchTransaction(transaction);

      const editorStateWas = this._editorState;

      const editorState = this._editorState.apply(transaction);

      // This is necessary so the state stored in the view is up-to-date when dispatchChanges runs, potentially
      // dispatching to an outer editor that may want to inspect the state of the tasksEditor (e.g. to update toolbar
      // state). This also allows state - like linkPluginState - that isn't reflected through the outer editor to
      // immediately take effect in the tasks editor view.
      if (this._editorView !== null) this._editorView.updateState(editorState);

      this._editorState = editorState;

      if (parentEditorView) {
        // We don't want to send any of this on to the outer editor, as it has a separate plugins that this would
        // interfere with
        const findPluginMetaKey = this._findPlugin.spec.key.key;
        if (findPluginMetaKey in transaction.meta) delete transaction.meta[findPluginMetaKey];

        if (this._emojiPlugin) {
          const emojiPluginMetaKey = this._emojiPlugin.spec.key.key;
          if (emojiPluginMetaKey in transaction.meta) delete transaction.meta[emojiPluginMetaKey];
        }

        const linkPluginKey = this._linkPlugin.spec.key;
        const linkPluginMetaKey = linkPluginKey.key;
        if (linkPluginMetaKey in transaction.meta) delete transaction.meta[linkPluginMetaKey];

        const { selectedTaskUUID } = getCheckListItemPluginState(this._editorState);
        if (hasFocus) setSelectedTaskUUID(transaction, selectedTaskUUID);
      } else {
        const noteLink = transaction.getMeta(TRANSACTION_META_KEY.OPEN_NOTE_LINK);
        if (noteLink) {
          const { hostApp: { openNoteLink } } = this.props.editorProps || { hostApp: {} };
          if (openNoteLink) openNoteLink(noteLink);
        }
      }

      const changesByTaskUUID = taskUpdatesFromDocChanges(editorState.doc, docWas);

      if (parentEditorView) {
        // In the non-standalone editor, the completedCheckListItemsPlugin in the outer editor won't know the position
        // of the node within this editor, so it won't animate it. The transaction that triggers that animation will
        // only be applied in the outer editor, not here, so the completedCheckListItemsPlugin instance in this editor
        // won't know about it either, unless we manually tell it to start the animation.
        Object.keys(changesByTaskUUID).forEach(taskUUID => {
          if (isDoneFromTask(changesByTaskUUID[taskUUID])) {
            const result = findCheckListItem(editorState.doc, taskUUID);
            if (result) this._completedCheckListItemsPlugin.startAnimation(result.nodePos, result);
          }
        });
      }

      const result = this.props.dispatchChanges(
        changesByTaskUUID,
        transaction.meta,
        // We only care to provide the selection when the transaction has specifically updated it, as an indication
        // that it is actually where the editor should be focused (i.e. make visible)
        transaction.selectionSet ? transaction.selection : null,
      );

      // Allows for rollback in cases where the change would be undesirable (and is not otherwise easy to prevent,
      // e.g. deleting a check-list-item when we don't want to allow that).
      if (typeof(result) !== "undefined" && !result) {
        if (this._editorView !== null) this._editorView.updateState(editorStateWas);
        this._editorState = editorStateWas;
      }
    } finally {
      this._inDispatch = false;
    }

    // This is a workaround for what appears to be a Chrome 80+ bug, in non-standalone editors this is handled in
    // the AmpleEditor component's dispatch function.
    if (!parentEditorView && hasFocus && transaction.getMeta(TRANSACTION_META_KEY.RETAIN_EDITOR_FOCUS)) {
      if (this._editorView && !this._editorView.hasFocus()) this._editorView.focus();
    }

    // Calling dispatchChanges can make it so there are no more tasks in this editor, which the parent component may
    // use as a reason to stop rendering it (unmounting it), in which case calling setState (and especially, waiting
    // to call setState via setTimeout) will trigger a warning in React
    if (!this._unmounted) {
      clearTimeout(this._draggingResetTimeout);

      // When dragging starts, a transaction is dispatched, but view.dragging isn't set until after the dispatch, so we
      // need to check later
      this._draggingResetTimeout = setTimeout(this._updateDragging, 1);
    }
  };

  // --------------------------------------------------------------------------
  _getTaskNote = uuid => {
    return this._noteByTaskUUID[uuid];
  };

  // --------------------------------------------------------------------------
  _handleDrop = (editorView, event, slice, _moved, _transaction) => {
    const { dispatchChanges, parentEditorView } = this.props;

    // We want prose-mirror to handle the drop in non-standalone editors
    if (parentEditorView) return;

    // The main intention here is to better handle the drop of check-list-item nodes, so we can pass them
    // directly to `dispatchChanges` without an awkward round-trip through the editor, since the insertion position
    // is not necessarily where the task would really be placed

    const { state: { doc, schema } } = editorView;

    const checkListItems = [];
    let haveNonCheckListItem = false;

    slice.content.forEach(node => {
      if (node.type === schema.nodes.check_list_item) {
        checkListItems.push(node);
      } else {
        haveNonCheckListItem = true;
      }
    });

    if (haveNonCheckListItem) return;

    let groupId = null;
    try {
      // This matches how `editHandlers.drop` calculates the `insertPos`, which isn't passed to this handler
      const eventPos = editorView.posAtCoords({ left: event.clientX, top: event.clientY });
      const $mouse = doc.resolve(eventPos.pos);
      let insertPos = slice ? dropPoint(doc, $mouse.pos, slice) : $mouse.pos;
      if (insertPos === null) insertPos = $mouse.pos;

      const $insertPos = doc.resolve(insertPos);
      const tasksGroupNode = $insertPos.node(TASKS_GROUP_DEPTH);
      if (tasksGroupNode && tasksGroupNode.type === schema.nodes.tasks_group && tasksGroupNode.attrs) {
        groupId = tasksGroupNode.attrs.id;
      }
    } catch (_error) {
      // The position resolution here is very sensitive to document changes, but we don't want that to entirely
      // prevent drop, as this is really just a slight convenience for the outer editor (which should already be
      // able to pick a reasonable group for the added tasks, absent one calculated here).
    }

    const changesByTaskUUID = {};
    checkListItems.forEach(checkListItem => {
      const existingCheckListItem = findCheckListItem(doc, checkListItem.attrs.uuid);

      if (existingCheckListItem) {
        // Matching the format of `taskUpdatesFromDocChanges` entries for changed items - likely the change being
        // made here is dragging the item between groups
        changesByTaskUUID[checkListItem.attrs.uuid] = { groupId };
      } else {
        // Matching the format of `taskUpdatesFromDocChanges` entries for added items
        const added = {
          ...checkListItem.attrs,
          content: checkListItem.content.toJSON(),
          groupId,
        };

        let { uuid } = added;
        if (!uuid) {
          added.uuid = uuid = uuidv4();
        }

        changesByTaskUUID[uuid] = { added };
      }
    });
    dispatchChanges(changesByTaskUUID, {}, null);

    return true; // Override default drop handling
  };

  // --------------------------------------------------------------------------
  _isEditable = () => {
    return !this.props.readonly;
  };

  // --------------------------------------------------------------------------
  _onBlur = event => {
    const { hideSelectionMenu, onBlur } = this.props;

    // The selection menu needs to know when the editor is un-focused (so it can hide)
    if (!hideSelectionMenu && this._editorView) {
      this._editorView.dispatch(this._editorState.tr.setMeta(TRANSACTION_META_KEY.HAS_FOCUS, false));
    }

    if (onBlur) onBlur(event);
  };

  // --------------------------------------------------------------------------
  _onFocus = event => {
    const { hideSelectionMenu, onFocus } = this.props;

    // The selection menu needs to know when the editor is focused (so it can show if there's a selection)
    if (!hideSelectionMenu && this._editorView) {
      this._editorView.dispatch(this._editorState.tr.setMeta(TRANSACTION_META_KEY.HAS_FOCUS, false));
    }

    if (onFocus) onFocus(event);
  };

  // --------------------------------------------------------------------------
  _onPaste = event => {
    if (event.isPropagationStopped()) return;

    if (event.target) {
      // See longer discussion in AmpleEditor._onPaste
      if (findProseMirrorAncestor(event.target) !== this._editorView.dom) return;
    }

    handlePasteFiles(this._editorView, event);
  };

  // --------------------------------------------------------------------------
  _setEditorView = editorView => {
    if (editorView) {
      const { onEditorViewCreated, onTaskGroupHeadingClick } = this.props;
      if (onTaskGroupHeadingClick) editorView.setProps({ onTaskGroupHeadingClick });
      if (onEditorViewCreated) onEditorViewCreated(editorView);
    }
    this._editorView = editorView;

    if (editorView) {
      const { checkListItemPluginState, parentEditorView } = this.props;

      if (parentEditorView) {
        const { dispatch, state } = parentEditorView;
        registerTargetView(parentEditorView, editorView, TARGET_VIEW_TYPE_TASKS_EDITOR)(state, dispatch);
      }

      // In addition to handling task highlighting in componentDidUpdate, we need to handle already being highlighted
      // upon mount here, so we can actually scroll the view (the constructor is too soon, as there's no view yet)
      if (checkListItemPluginState) {
        const { highlightedTaskUUID } = checkListItemPluginState;
        if (highlightedTaskUUID) this.highlightListItem(highlightedTaskUUID);
      }
    }
  };

  // --------------------------------------------------------------------------
  _updateDragging = () => {
    const dragging = this._editorView ? !!this._editorView.dragging : false;
    if (dragging !== this.state.dragging) this.setState({ dragging });
  };

  // --------------------------------------------------------------------------
  _wrapEditorProps = memoize((editorProps, includeSourceNotes) => {
    return {
      ...(editorProps || {}),

      handleDrop: this._handleDrop,
      hostApp: {
        ...((editorProps || {}).hostApp || {}),

        getTaskNote: includeSourceNotes ? this._getTaskNote : null,
      },
    };
  });
}
