import { escape } from "lodash"
import { StreamLanguage } from "@codemirror/language"
import { RangeSetBuilder, Text } from "@codemirror/state"
import { Decoration } from "@codemirror/view"
import { highlightTree } from "@lezer/highlight"

import {
  CODE_BLOCK_COPY_ICON_CLASS,
  copyIconTitle,
  LANGUAGE,
  LEGACY_MODE_LANGUAGES
} from "lib/ample-editor/lib/code-block/code-block-util"
import { highlightStyle } from "lib/ample-editor/lib/code-block/code-mirror-editor"
import languagesWithData from "lib/ample-editor/lib/code-block/languages-with-data"

// --------------------------------------------------------------------------
// Load sufficient CM dependencies to be able to static render the entirety of a code block with CM formatting.
// It was originally inspired by https://github.com/jamischarles/codemirror-server-render. Originally WBH attempted
// to reuse the CodeMirror view instance from our CodeBlock, but along with having numerous expectations about dom
// methods that don't exist in SSR, it also only progressively renders inner content as it detects necessary, whereas
// the method below renders the entire code block upon init.
export default class CodeBlockRenderedView {
  // --------------------------------------------------------------------------
  constructor(node, _view, _getPos) {
    this._languageEm = node.attrs.language || LANGUAGE.JAVASCRIPT;
    const languageModule = this._loadLanguageModule(this._languageEm);

    const textContent = node.textContent;
    let parser;
    if (typeof languageModule.language === "undefined") {
      parser = languageModule.parser;
    } else {
      parser = languageModule.language.parser;
    }
    const tree = parser.parse(textContent);
    const markCache = {};
    const builder = new RangeSetBuilder();

    highlightTree(tree, highlightStyle, (from, to, classes) => {
      builder.add(from, to, markCache[classes] || (markCache[classes] = Decoration.mark({ class: classes })));
    });
    const decorationRangeSet = builder.finish();

    this.dom = document.createElement("div"); // Placeholder until CodeMirror is loaded
    this.dom.className = this._classNameWithLanguage(this._languageEm);
    const cmEditor = document.createElement("div");
    cmEditor.className = "cm-editor ͼ1 ͼ2 ͼ4";
    this.dom.appendChild(cmEditor);
    const cmScroller = document.createElement("div");
    cmScroller.className = "cm-scroller";
    cmEditor.appendChild(cmScroller);
    const cmContentDiv = document.createElement("div");
    cmContentDiv.className = "cm-content";
    cmContentDiv.setAttribute("data-language", this._languageEm);
    cmScroller.appendChild(cmContentDiv);

    const codeText = Text.of(textContent.split("\n"));
    let lineStart;
    let pos = 0;
    let html = "";
    for (let row = 1; row <= codeText.lines; row++) {
      const line = codeText.line(row);
      pos = lineStart = line.from;
      const cursor = decorationRangeSet.iter(line.from);
      html += '<div class="cm-line">';
      let lineContent = "";

      // As long as the iterator has a value, and we haven't reached the end of the current line, keep working
      if (line.text && line.text.trim().length) {
        while (cursor.value && pos < line.to) {
          // If the next token is after the current position, add the non-tokenized text to the string
          if (Math.max(cursor.from, lineStart) > pos) {
            lineContent += codeText.sliceString(pos, Math.max(cursor.from, lineStart));
          }

          // Get token value from the current cursorPos to end of token, and not past the end of the current line
          const codeTextContent = codeText.sliceString(Math.max(cursor.from, line.from), Math.min(cursor.to, line.to));

          // Note that we need to escape any HTML characters in `codeTextContent` or they will be interepreted as HTML
          lineContent += `<${ cursor.value.tagName } class="${ cursor.value.class }">${ escape(codeTextContent) }</${ cursor.value.tagName }>`;

          pos = cursor.to;

          cursor.next();
        }

        // Catch up to end of line
        lineContent += codeText.sliceString(pos, line.to);
      }

      if (!lineContent.length) lineContent = "&nbsp;"; // If the line is empty, adding at least a space is necessary to preserve its height. Used to use a <br> here but that added doubled new lines to copied text
      html += `${ lineContent }</div>`;
      pos = line.to; // set pos to end of line...
    }

    cmContentDiv.innerHTML = html;

    const copyIcon = document.createElement("div");
    copyIcon.className = CODE_BLOCK_COPY_ICON_CLASS;
    copyIcon.setAttribute("title", copyIconTitle(this._languageEm));
    cmContentDiv.appendChild(copyIcon);
  }

  // --------------------------------------------------------------------------
  _classNameWithLanguage(language) {
    return `code-block code-block-rendered language-${ language || "unknown" }`
  }

  // --------------------------------------------------------------------------
  _loadLanguageModule(languageEm) {
    if (languageEm in LEGACY_MODE_LANGUAGES) {
      const { file, extract } = (LEGACY_MODE_LANGUAGES[languageEm] || {});

      // eslint-disable-next-line global-require
      const languageFunctions = require(`lib/ample-editor/lib/code-block/codemirror/${ file }`);
      const exportedLanguage = languageFunctions[extract];
      return StreamLanguage.define(exportedLanguage);
    } else {
      try {
        return languagesWithData(languageEm || LANGUAGE.JAVASCRIPT);
      } catch (_error) {
        // "Language X not supported"
        return languagesWithData(LANGUAGE.JAVASCRIPT);
      }
    }
  }
}
