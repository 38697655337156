import { TextSelection } from "prosemirror-state"
import tippy from "tippy.js"

import { buildToggleNodeCollapsed } from "lib/ample-editor/commands/toggle-node-collapsed"
import {
  CHILDREN_COLLAPSED_CLASS,
  HAS_CHILDREN_CLASS,
  HIDDEN_BY_COLLAPSE_CLASS
} from "lib/ample-editor/lib/collapsible-defines"
import { isPosCollapsibleNode, isPosHiddenNode } from "lib/ample-editor/plugins/collapsible-nodes-plugin"

// --------------------------------------------------------------------------
function isClickOnChildExpander(clickX, container) {
  if (!container || !container.classList || !container.classList.contains(HAS_CHILDREN_CLASS)) return false;

  // Area that will accept clicks is the width of the expander (22px) plus a couple pixels for padding
  const expanderWidth = Math.abs(parseInt(window.getComputedStyle(container, "::before").width, 10)) + 2;
  const containerX = container.getBoundingClientRect().x;
  return (clickX >= containerX && (clickX <= containerX + expanderWidth));
}

// --------------------------------------------------------------------------
export function onListItemClick(view, getPos, event) {
  if (isClickOnChildExpander(event.x, event.currentTarget)) {
    const { dispatch, state } = view;
    return buildToggleNodeCollapsed(getPos())(state, dispatch);
  }
}

// --------------------------------------------------------------------------
// On iOS Safari, the TouchStart event ensures that the cursor selection will be modified, even if
// `stopEvent` prevents the mousedown and returns true. Thus, we need to intercept and stop the touch event
// when it taps on the child expander. This will automatically suppress the mousedown event (along w/ suppressing
// cursor movement that would otherwise be triggered by this event).
export function onListItemTouchStart(view, getPos, event) {
  const touch = event.touches[0];
  const { clientX, target } = touch;
  if (isClickOnChildExpander(clientX, target)) {
    // Allow time for note to adjust in response to user's cursor movement before showing tooltip
    // e.g., if a user moves cursor from a checklist higher in note to a check list later, the expander area above will contract
    setTimeout(positionedTooltip.bind(null, target, getPos()), 50);

    // Area that will accept clicks is the width of the expander (22px) plus a couple pixels for padding
    event.preventDefault();

    // Need to ensure cursor is on the line so that expander toolbar button is actually visible (and could be used to expand/collapse the content they clicked)
    return view.dispatch(view.state.tr.setSelection(TextSelection.create(view.state.doc, getPos() + 2)));
  }
}

// --------------------------------------------------------------------------
function positionedTooltip(target, pos) {
  let { bottom, left, top } = target.getBoundingClientRect();
  if (isNaN(left)) return;
  const width = Math.abs(parseInt(window.getComputedStyle(target, "::before").width, 10));
  if (isNaN(width)) return; // Minimize likelihood of creating a tippy on elements that can't be found following timeout
  if (pos === 0) {
    top = bottom;
  }

  tippy(target, {
    allowHTML: true,
    arrow: pos > 0,
    content: "<div class='flex-centered-tooltip'>Use the <i class='material-icons'>unfold_less</i> icon in the toolbar to expand content</div>",
    getReferenceClientRect: () => ({
      bottom: top,
      height: 1,
      left,
      right: left + width,
      top,
      width,
    }),
    onCreate: instance => instance.show(),
    onShow: instance => setTimeout(() => { instance.destroy() }, 2000),
  });
}

// --------------------------------------------------------------------------
export function listItemClassName(state, node, getPos) {
  const { attrs: { collapsed, indent } } = node;

  let className = ` indent-${ indent }`;

  if (collapsed) className += ` ${ CHILDREN_COLLAPSED_CLASS }`;

  const pos = getPos();
  if (isPosCollapsibleNode(state, pos)) className += " " + HAS_CHILDREN_CLASS;
  if (isPosHiddenNode(state, pos)) className += " " + HIDDEN_BY_COLLAPSE_CLASS;

  return className;
}
