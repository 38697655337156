import { compact, defer } from "lodash"
import PropTypes from "prop-types"
import { gapCursor } from "prosemirror-gapcursor"
import { history } from "prosemirror-history"
import { Node } from "prosemirror-model"
import { EditorState, Selection, TextSelection } from "prosemirror-state"
import { insertPoint } from "prosemirror-transform"
import { EditorView } from "prosemirror-view"
import React from "react"

import buildUpdateImage from "lib/ample-editor/commands/update-image"
import buildInsertNodes from "lib/ample-editor/commands/insert-nodes"
import buildRemoveNodes from "lib/ample-editor/commands/remove-nodes"
import buildReplaceNodes from "lib/ample-editor/commands/replace-nodes"
import { isFirefox, isIOS } from "lib/ample-editor/lib/client-info"
import {
  buildClipboardTextParser,
  clipboardParser,
  clipboardSerializer,
  handleDrop,
  handlePaste,
  transformPastedHTML,
  transformPastedText,
} from "lib/ample-editor/lib/clipboard"
import closePopups from "lib/ample-editor/lib/close-popups"
import EDITOR_TAB from "lib/ample-editor/lib/editor-tab"
import { updateLocalFileURLs } from "lib/ample-editor/lib/file-commands"
import findProseMirrorAncestor from "lib/ample-editor/lib/find-prose-mirror-ancestor"
import handleDropFiles from "lib/ample-editor/lib/handle-drop-files"
import handlePasteFiles from "lib/ample-editor/lib/handle-paste-files"
import { updateNoteURLs } from "lib/ample-editor/lib/link-commands"
import { buildHighlightListItem } from "lib/ample-editor/lib/list-item-commands"
import replaceDocument from "lib/ample-editor/lib/replace-document"
import shouldSkipIosEnterTransaction from "lib/ample-editor/lib/should-skip-ios-enter-transaction"
import { deleteTasks, updateTask } from "lib/ample-editor/lib/task-commands"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"

import checkListItemPlugin from "lib/ample-editor/plugins/check-list-item-plugin"
import codeBlockPlugin from "lib/ample-editor/plugins/code-block-plugin"
import createCodePlugin from "lib/ample-editor/plugins/code-plugin"
import createCompletedCheckListItemsPlugin from "lib/ample-editor/plugins/completed-check-list-items-plugin"
import collapsibleNodesPlugin from "lib/ample-editor/plugins/collapsible-nodes-plugin"
import createDateSuggestionPlugin from "lib/ample-editor/plugins/date-suggestion-plugin"
import createEditorTabsPlugin from "lib/ample-editor/plugins/editor-tabs-plugin"
import emojiPlugin from "lib/ample-editor/plugins/emoji-popper"
import createExpressionPlugin from "lib/ample-editor/plugins/expression-plugin"
import filePlugin from "lib/ample-editor/plugins/file-plugin"
import {
  createFindPlugin,
  domElementForFindMatch,
  setFindPluginIndex,
  setFindPluginIndexDelta,
  setFindPluginQuery,
} from "lib/ample-editor/plugins/find-plugin"
import createInputRulesPlugin from "lib/ample-editor/plugins/input-rules-plugin"
import { createKeymapPlugin } from "lib/ample-editor/plugins/keymap-plugin"
import createLinkPlugin from "lib/ample-editor/plugins/link-plugin"
import createListItemCommandsMenuPlugin from "lib/ample-editor/plugins/list-item-commands-menu-plugin"
import createListItemPlugin from "lib/ample-editor/plugins/list-item-plugin"
import listItemSelectionPlugin, { dispatchDropTransform, editorDragStart } from "lib/ample-editor/plugins/list-item-selection-plugin"
import { placeholderPlugin } from "lib/ample-editor/plugins/placeholder"
import createSelectionMenuPlugin from "lib/ample-editor/plugins/selection-menu-plugin"
import targetViewPlugin from "lib/ample-editor/plugins/target-view-plugin"
import createTablePlugin from "lib/ample-editor/plugins/table-plugin"
import createToolbarPlugin from "lib/ample-editor/plugins/toolbar-plugin"
import { schema } from "lib/ample-editor/schema"
import SetDocumentAttrsStep from "lib/ample-editor/steps/set-document-attrs-step"
import { setDocumentStorageTransform } from "lib/ample-editor/steps/update-document-storage-step"
import nodeViews from "lib/ample-editor/views"
import { anchorNameFromHeadingText } from "lib/ample-util/note-url"
import { TASK_COMPLETION_EFFECT_LEVEL_EM } from "lib/ample-util/tasks"

import "../styles/ample-editor.scss"

// --------------------------------------------------------------------------
const DEFAULT_SCROLL_MARGIN = 50;

// --------------------------------------------------------------------------
function buildPlugins(props) {
  const {
    disableRichFootnoteSpacer,
    hideSelectionMenu,
    hideToolbar,
    initialEditorTab,
    noEditorTabs,
    onEditorTabChange,
    tableOfContentsEnabled,
  } = props;

  return compact([
    createInputRulesPlugin(schema),
    createCodePlugin(schema),
    emojiPlugin(), // Needs to be before keymap so it can handle keys first
    createLinkPlugin({ disableRichFootnoteSpacer }), // Needs to be before keymap so it can handle keys first
    // Should be after linkPlugin so it can get state from it, but before toolbar and checkListItem plugin so it can
    // set state for them
    targetViewPlugin,
    createListItemCommandsMenuPlugin(), // Needs to be before keymap so it can handle keydown events first
    createDateSuggestionPlugin(), // Needs to be before keymap so it can handle keydown events before keymap
    createSelectionMenuPlugin({ hideSelectionMenu }), // Needs to be before keymap so it can handle keydown events before keymap
    createExpressionPlugin({ tableOfContentsEnabled }), // Needs to be before keymap so it can handle keydown events before keymap
    createKeymapPlugin(schema),
    listItemSelectionPlugin,
    gapCursor(),
    collapsibleNodesPlugin,
    history(),
    createListItemPlugin(),
    checkListItemPlugin, // Needs to be after listItemPlugin so state.apply sees changes from that plugin
    createCompletedCheckListItemsPlugin(),
    noEditorTabs ? null : createEditorTabsPlugin({ initialTab: initialEditorTab, onTabChange: onEditorTabChange }),
    placeholderPlugin,
    filePlugin,
    createFindPlugin({ noEditorTabs }),
    createTablePlugin(),
    codeBlockPlugin,
    createToolbarPlugin({ hideToolbar }), // Should be last so it has latest state from other plugins
  ]);
}

// --------------------------------------------------------------------------
function currentTimestamp() {
  return Math.floor(Date.now() / 1000);
}

// --------------------------------------------------------------------------------
export default class AmpleEditor extends React.Component {
  static propTypes = {
    // If given, will be used as "now" when showing completed tasks, showing tasks as if the note were being
    // viewed at this unix timestamp
    completedTasksNow: PropTypes.number,
    disableRichFootnoteSpacer: PropTypes.bool,
    document: PropTypes.object,
    experimentalFeaturesEnabled: PropTypes.bool,
    handleScrollToSelection: PropTypes.func,
    hideSelectionMenu: PropTypes.bool,
    hideToolbar: PropTypes.bool,
    initialAdditionalTopScrollMargin: PropTypes.number,
    initialClassName: PropTypes.string,
    initialEditorTab: PropTypes.shape({
      expanded: PropTypes.bool,
      filterParams: PropTypes.shape({
        group: PropTypes.string,
        references: PropTypes.string,
        tag: PropTypes.string,
      }),
      name: PropTypes.oneOf(Object.values(EDITOR_TAB)),
    }),
    // If true, hovering on heading nodes will show an icon linking to that heading
    interactiveHeadingAnchors: PropTypes.bool,
    noEditorTabs: PropTypes.bool,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onDocumentChange: PropTypes.func,
    onEditorDrop: PropTypes.func,
    onEditorTabChange: PropTypes.func,
    onEditorViewCreated: PropTypes.func,
    onFocus: PropTypes.func,
    // If given, called with this component instance after mount
    onInitialized: PropTypes.func,
    onSelectionChange: PropTypes.func,
    onTransactionDispatched: PropTypes.func,
    readonly: PropTypes.bool,
    taskCompletionEffectLevelEm: PropTypes.oneOf(Object.values(TASK_COMPLETION_EFFECT_LEVEL_EM)),
    tableOfContentsEnabled: PropTypes.bool,

    // Callbacks that allow interaction with the hosting application - these are passed in to EditorView as props so
    // they are accessible throughout the editor (as `editorView.props.hostApp.*`).
    hostApp: PropTypes.shape({
      applyNoteContentActions: PropTypes.func,
      cloneNodes: PropTypes.func,
      fetchNoteContent: PropTypes.func,
      fetchReferencedNotes: PropTypes.func,
      fetchReferencingNotes: PropTypes.func,
      fetchTask: PropTypes.func,
      getIndexingStatus: PropTypes.func,
      getPluginActions: PropTypes.func,
      linkNote: PropTypes.func,
      mountPluginEmbed: PropTypes.func,
      openAttachment: PropTypes.func,
      openNoteLink: PropTypes.func,
      startAttachmentUpload: PropTypes.func,
      selectMedia: PropTypes.func,
      startMediaUpload: PropTypes.func,
      suggestNotes: PropTypes.func,
      suggestTags: PropTypes.func,
    }).isRequired,
  };

  _containerRef = React.createRef();
  _editorState = null;
  _editorView = null;
  _hasBeenFocused = false;
  _inDispatch = false;
  _lastDispatchAt = 0;
  _timer = null;
  _unmounted = false;

  // --------------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this._editorState = this._createEditorState(props.document);
  }

  // --------------------------------------------------------------------------
  componentDidMount() {
    if (this.props.onInitialized) {
      this.props.onInitialized(this);
    }

    this._timer = setTimeout(this._onTimerTick, 1000);
  }

  // --------------------------------------------------------------------------
  componentWillUnmount() {
    this._unmounted = true;

    clearTimeout(this._timer);

    this._destroyEditorView();
  }

  // --------------------------------------------------------------------------
  closePopups() {
    if (this._editorView) closePopups(this._editorView);
  }

  // --------------------------------------------------------------------------
  deleteSelection() {
    this.dispatchTransaction(this._editorState.tr.deleteSelection());
  }

  // --------------------------------------------------------------------------
  dispatchTransaction = transaction => {
    if (this.props.readonly) {
      // As of ProseMirror 1.9.0, readonly editors must handle transactions to update the selection, so we can't block
      // these. Instead we just have to be careful not to make any changes - or let any document changes through.
      if (transaction.docChanged) return;

      // Allows appendTransaction functions in plugins to understand that they shouldn't make changes
      transaction.setMeta(TRANSACTION_META_KEY.READONLY, true);
    }

    if (shouldSkipIosEnterTransaction(this._editorView, transaction)) {
      return;
    }

    const wasFocused = this._editorView !== null ? this._editorView.hasFocus() : false;
    transaction.setMeta(TRANSACTION_META_KEY.HAS_FOCUS, wasFocused);

    const { doc, selection } = this._editorState;

    let isAutomaticDocumentChange = !!transaction.getMeta(TRANSACTION_META_KEY.AUTOMATIC);

    let editorState;

    // In some cases, we may want to dispatch a transaction from an onBlur handler, but that blur itself might be
    // in response to a click event (or some other event), which would result in the blur potentially happening in
    // the view's updateState below. We'll simply drop any transactions dispatched in those cases, as the user
    // _probably_ doesn't want them (loosely indicated by interacting with something else that causes a transaction to
    // cause the blur).
    if (this._inDispatch) {
      transaction.setMeta(TRANSACTION_META_KEY.REJECTED, true);
      return;
    }
    this._inDispatch = true;
    try {
      this._lastDispatchAt = currentTimestamp();

      const { state: newState, transactions } = this._editorState.applyTransaction(transaction);
      editorState = newState;

      if (transactions.length > 1 && !transaction.docChanged) {
        // Plugins may use appendTransaction to piggyback changes on this transaction, but we want to always consider
        // those "automatic" changes if the user hadn't done anything to actually change the document in the transaction
        // that was actually dispatched.
        isAutomaticDocumentChange = true;
      }

      // This is mostly intended for the test environment, where rapid mount+unmount of an editor can result in some
      // deferred transactions happening on teardown. In theory this is fine in non-test environments, but there's
      // no great reason to push an automatic change through if the editor is unmounting (it should be applied again in
      // the future).
      if (isAutomaticDocumentChange && this._unmounted) {
        return;
      }

      if (this._editorView !== null) {
        this._editorView.updateState(editorState);
      }

      this._editorState = editorState;
    } catch (error) {
      if (error instanceof RangeError) {
        // When the transaction was built with an out-of-date state (using a document that doesn't match the current
        // state's document), ProseMirror raises a RangeError "Applying a mismatched transaction". As of 10/2022 it's
        // unclear exactly how this happens, as it happens in the wild in a few different locations where `editorView`
        // handles are used, so they should have the matching state, and we're not in a recursive call.
        transaction.setMeta(TRANSACTION_META_KEY.REJECTED, true);
        return;
      } else {
        throw error;
      }
    } finally {
      this._inDispatch = false;
    }

    // This is a workaround for what appears to be a Chrome 80+ bug, wherein some commands that replace the node the
    // current selection is in with a different node (e.g. when completing a check-list-item via keyboard command, or
    // undoing that same completion - both of which replace the node that the selection is in) will un-focus the
    // contentEditable node.
    if (wasFocused && transaction.getMeta(TRANSACTION_META_KEY.RETAIN_EDITOR_FOCUS)) {
      if (this._editorView && !this._editorView.hasFocus()) this._editorView.focus();
    }

    if (!editorState.doc.eq(doc) && !transaction.getMeta(TRANSACTION_META_KEY.SILENT)) {
      this.props.onDocumentChange(editorState.doc, isAutomaticDocumentChange);
    }

    if (!editorState.selection.eq(selection)) {
      this.props.onSelectionChange(editorState.selection);
    }

    if (this.props.onTransactionDispatched) {
      this.props.onTransactionDispatched();
    }

    const noteLink = transaction.getMeta(TRANSACTION_META_KEY.OPEN_NOTE_LINK);
    if (noteLink) {
      const { hostApp: { openNoteLink } } = this.props;
      if (openNoteLink) openNoteLink(noteLink);
    }
  };

  // --------------------------------------------------------------------------
  domElementForFindMatch() {
    return domElementForFindMatch(this._editorView);
  }

  // --------------------------------------------------------------------------
  // Given `callback` will be called when the current match index or total matches count change
  find(query, callback = null, { currentIndex = null } = {}) {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      dispatch(setFindPluginQuery(state.tr, callback, query, { currentIndex }));
      return true
    }

    return false;
  }

  // --------------------------------------------------------------------------
  findNext() {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      dispatch(setFindPluginIndexDelta(state.tr, 1));
      return true;
    }

    return false;
  }

  // --------------------------------------------------------------------------
  // Clears out the current match so no particular match is highlighted as the "current" match
  findNone() {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      dispatch(setFindPluginIndex(state.tr, -1));
      return true;
    }

    return false;
  }

  // --------------------------------------------------------------------------
  findPrevious() {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      dispatch(setFindPluginIndexDelta(state.tr, -1));
      return true;
    }

    return false;
  }

  // --------------------------------------------------------------------------
  focus({ atEnd = false, ensureLeadingBlankLine = false } = {}) {
    return this.setSelection(null, true, { atEnd, ensureLeadingBlankLine });
  }

  // --------------------------------------------------------------------------
  getContainer() {
    const { current: container } = this._containerRef;
    return container;
  }

  // --------------------------------------------------------------------------
  getDraggedSlice() {
    if (this._editorView && this._editorView.dragging) {
      return this._editorView.dragging.slice;
    }
    return null;
  }

  // --------------------------------------------------------------------------
  getDocument() {
    return this._editorState ? this._editorState.doc : null;
  }

  // --------------------------------------------------------------------------
  getEditorView() {
    return this._editorView;
  }

  // --------------------------------------------------------------------------
  getSelection() {
    return this._editorState ? this._editorState.selection : null;
  }

  // --------------------------------------------------------------------------
  hasCursorAtEdge = isTopEdge => {
    if (!this.hasFocus() || this._isArrowCapturingMenuOpen()) return;

    const document = this.getDocument();
    const selection = this.getSelection();
    if (!document || !selection || !selection.empty) return;

    const edgePos = isTopEdge ? Selection.atStart(document).from : Selection.atEnd(document).to;

    // Note that escaping a code block at the beginning of the document by pressing up will place the selection at
    // 0 (with a gap cursor), while the edge position will be 1.
    let atEdgePos = isTopEdge ? selection.from <= edgePos : selection.to >= edgePos;

    // There's a degenerate case where the cursor is at the start of a link node at the start of a document, but
    // ProseMirror insists that the first _text_ position in the document is the open position of the link node's
    // _parent_ node, not the opening position of the link node
    if (!atEdgePos && isTopEdge && selection.from === edgePos + 1) {
      const edgeNode = document.nodeAt(edgePos);
      if (edgeNode && edgeNode.type.name === "link") atEdgePos = true;
    }

    return atEdgePos;
  };

  // --------------------------------------------------------------------------
  hasFocus = () => {
    return this._editorView !== null && this._editorView.hasFocus();
  };

  // --------------------------------------------------------------------------
  highlightListItem = uuid => {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      buildHighlightListItem(uuid)(state, dispatch);

      return true;
    }

    return false;
  };

  // --------------------------------------------------------------------------
  insertNodes = (nodes, { atEnd = false, keepUUIDs = false, section = null } = {}) => {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      return buildInsertNodes(nodes, { atEnd, keepUUIDs, section })(state, dispatch);
    }

    return false;
  };

  // --------------------------------------------------------------------------------
  render = () => {
    return (
      <div
        className={ this._buildClassName() }
        ref={ this._containerRef }
        onBlur={ this.props.onBlur }
        onClick={ this._onClick }
        onDrop={ this._onDrop }
        onKeyDown={ this._onKeyDown }
        onKeyUp={ this._onKeyUp }
        onMouseMove={ this._onMouseMove }
        onPaste={ this._onPaste }
        onFocus={ this._onFocus }
      >
        <div ref={ this._createEditorView } />
      </div>
    );
  };

  // --------------------------------------------------------------------------
  replaceDocument = newDocument => {
    const wasFocused = this._editorView ? this._editorView.hasFocus() : false;

    // We know we aren't in dispatch when this is called (because it comes from an external call, which should be
    // triggered by an external system, not dispatching a transaction in the editor) and we need to make sure no
    // transactions are dispatched while calling EditorView#updateState, or ProseMirror will get very confused, using
    // the wrong state (Applying mismatched transaction) or thinking the DOM has been updated externally (RangeError).
    // This mainly happens when our `_onEditorBlur` handler - which dispatches a transaction - is called because the
    // DOM for the focused element is being re-created, un-focusing the element.
    this._inDispatch = true;
    try {
      this._lastDispatchAt = currentTimestamp();

      const editorState = replaceDocument(this._editorState, newDocument);

      if (this._editorView) {
        this._editorView.updateState(editorState);
      }

      this._editorState = editorState;
    } finally {
      this._inDispatch = false;
    }

    if (wasFocused && this._editorView) this._editorView.focus();
  };

  // --------------------------------------------------------------------------
  replaceLocalFileURLs = urlByUUID => {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      updateLocalFileURLs(urlByUUID)(state, dispatch);

      return true;
    }

    return false;
  };

  // --------------------------------------------------------------------------
  replaceLocalNoteURLs = (localUUID, remoteUUID) => {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      updateNoteURLs(localUUID, remoteUUID)(state, dispatch);

      return true;
    }

    return false;
  };

  // --------------------------------------------------------------------------
  replaceNodes = (nodes, section = null) => {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      return buildReplaceNodes(nodes, section)(state, dispatch);
    }

    return false;
  };

  // --------------------------------------------------------------------------
  removeNodes = uuids => {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      buildRemoveNodes(uuids)(state, dispatch);
      return true;
    }

    return false;
  };

  // --------------------------------------------------------------------------
  // Note that this only returns false if it's not possible to even _try_ to remove the tasks
  removeTasks = taskUUIDs => {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      deleteTasks(taskUUIDs)(state, dispatch);
      return true;
    }

    return false;
  };

  // --------------------------------------------------------------------------
  setSelection(selectionJSON, focus, { atEnd = false, ensureLeadingBlankLine = false } = {}) {
    if (selectionJSON) {
      let selection;
      try {
        selection = Selection.fromJSON(this._editorState.doc, selectionJSON);
      } catch (_error) {
        // Likely "RangeError: Position N out of range"
        return false;
      }
      const transaction = this._editorState.tr;
      transaction.setSelection(selection);
      transaction.scrollIntoView();
      transaction.setMeta(TRANSACTION_META_KEY.AUTOMATIC, true);

      this.dispatchTransaction(transaction);
    } else if (atEnd) {
      const { doc } = this._editorState;

      const transaction = this._editorState.tr;
      transaction.setSelection(Selection.atEnd(doc));
      transaction.scrollIntoView();
      transaction.setMeta(TRANSACTION_META_KEY.AUTOMATIC, true);

      this.dispatchTransaction(transaction);

    } else if (ensureLeadingBlankLine) {
      const { doc } = this._editorState;

      if (!doc.firstChild || doc.firstChild.type !== schema.nodes.paragraph || doc.firstChild.textContent) {
        const transaction = this._editorState.tr;
        const insertPos = insertPoint(doc, 0, schema.nodes.paragraph);
        transaction.insert(insertPos, schema.nodes.paragraph.createAndFill());

        const $insertPos = transaction.doc.resolve(insertPos);
        transaction.setSelection(TextSelection.between($insertPos, $insertPos));
        transaction.scrollIntoView();
        transaction.setMeta(TRANSACTION_META_KEY.AUTOMATIC, true);

        this.dispatchTransaction(transaction);
      }
    }

    if (focus) {
      if (!this._editorView) return false;

      this._editorView.focus();

      if (isFirefox) {
        // When this is called in some contexts in Firefox (e.g. from a keydown handler - even if it calls
        // preventDefault - in an input that is in a popup) the browser selection can get set in such a way that it sees
        // the DOM element the selection is in as the extents of the editable region, even if there are other siblings
        // in the contentEditable parent. This results in the arrow up/down keys not moving to other nodes until the
        // view is blurred and re-focused.
        this._editorView.dom.blur();
        this._editorView.focus();
      }
    }

    return true;
  }

  // --------------------------------------------------------------------------
  setSelectionAfterHeading(headingName) {
    if (!this._editorView) return false;

    const { state: { doc } } = this._editorView;

    let afterHeadingPos = null;
    doc.descendants((node, nodePos) => {
      if (node.type.name !== "heading") return;

      const anchorName = anchorNameFromHeadingText(node.textContent);
      if (anchorName === headingName) {
        afterHeadingPos = nodePos + node.nodeSize;
      }

      if (afterHeadingPos !== null) return false;
    });

    if (afterHeadingPos === null) return false;

    const $afterHeadingPos = doc.resolve(afterHeadingPos);
    const selection = TextSelection.between($afterHeadingPos, $afterHeadingPos);

    const transaction = this._editorState.tr;
    transaction.setSelection(selection);
    transaction.scrollIntoView();
    transaction.setMeta(TRANSACTION_META_KEY.AUTOMATIC, true);
    this.dispatchTransaction(transaction);

    return true;
  }

  // --------------------------------------------------------------------------
  // ProseMirror manages the rendering entirely on it's own, so we never want to re-render
  shouldComponentUpdate() {
    return false;
  }

  // --------------------------------------------------------------------------
  updateImage({ index, src }, updates) {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      buildUpdateImage({ index, src }, updates)(state, dispatch);
      return true;
    }

    return false;
  }

  // --------------------------------------------------------------------------
  updateSerializedVersion(serializedVersion) {
    const { doc } = this._editorState;

    const transaction = this._editorState.tr
      .setMeta(TRANSACTION_META_KEY.AUTOMATIC, true)
      .setMeta(TRANSACTION_META_KEY.SILENT, true);

    transaction.step(new SetDocumentAttrsStep(this._editorState.schema, { ...doc.attrs, serializedVersion }));
    this.dispatchTransaction(transaction);
  }

  // --------------------------------------------------------------------------
  updateStorage(name, value) {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;

      dispatch(setDocumentStorageTransform(state.tr.setMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY, false), name, value));

      return true;
    }

    return false;
  }

  // --------------------------------------------------------------------------
  updateTask(uuid, updates) {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;

      return updateTask(uuid, updates)(state, transaction => {
        transaction.setMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY, false);
        dispatch(transaction);
      });
    }

    return false;
  }

  // --------------------------------------------------------------------------
  _allowAutomatedChanges() {
    const { allowAutomatedChanges } = this.props;
    return allowAutomatedChanges ? allowAutomatedChanges() : true;
  }

  // --------------------------------------------------------------------------
  _buildClassName = () => {
    let className = "ample-editor";

    const { initialClassName, readonly } = this.props;

    if (initialClassName) className += ` ${ initialClassName }`;
    if (readonly) className += " readonly";

    return className;
  };

  // --------------------------------------------------------------------------
  _createEditorState = document => {
    const { placeholder, readonly } = this.props;

    return EditorState.create({
      doc: document,
      plugins: buildPlugins(this.props),
      placeholder: readonly ? null : placeholder,
    });
  };

  // --------------------------------------------------------------------------
  _createEditorView = element => {
    if (!element) return;

    // The only time this component gets re-rendered is when HMR is enabled, which is when this is called with an
    // extant view. In that case, we need to fully destroy the view, as HMR may have created a new dom node to use,
    // while prosemirror doesn't provide a way to update the EditorView's dom node.
    if (this._editorView && module.hot) {
      const hadFocus = this._editorView.hasFocus();

      this._editorView.destroy();
      this._editorView = null;

      // EditorState#reconfigure can handle updates to the plugins, but updates to the schema cause the document to
      // break because it was built using the old schema. If the schema has changed we'll just round-trip the document
      // through JSON so we can apply the new schema to it and create the state from that.
      if (this._editorState.schema === schema) {
        this._editorState = this._editorState.reconfigure({ plugins: buildPlugins(this.props) });
      } else {
        const document = Node.fromJSON(schema, this._editorState.doc.toJSON());
        this._editorState = this._createEditorState(document);
      }

      if (hadFocus) defer(() => this._editorView.focus());
    }

    // Used for both scroll margin and threshold
    const scrollConfig = this.props.hideToolbar
      // Account for sticky toolbar overlapping document when scrolled down. When the scrollbar is hidden, this is
      // problematic because prosemirror's default implementation of the optional `handleScrollToSelection` handler
      // (`scrollRectIntoView` in prosemirror-view) will scroll _every_ possible parent by the scroll margin, so if
      // the editor is on a page with another editor that uses the DOM document's body for scrolling, it will get
      // scrolled by the margin when placing the cursor/typing at the end of this editor. This can be observed by
      // opening a long note in the primary editor (scrolled to top); opening a long note in the node editor sidebar;
      // scrolling to bottom of sidebar note; and typing at the end of the sidebar note - the main note editor will
      // scroll down by scrollMargin on each character / cursor movement on the last line.
      ? 0
      : {
        bottom: DEFAULT_SCROLL_MARGIN,
        left: DEFAULT_SCROLL_MARGIN,
        right: DEFAULT_SCROLL_MARGIN,
        top: DEFAULT_SCROLL_MARGIN + (this.props.initialAdditionalTopScrollMargin || 0),
      };

    const getView = () => this._editorView;

    const editorProps = {
      state: this._editorState,
      dispatchTransaction: this.dispatchTransaction,
      nodeViews: nodeViews(),
      editable: () => !this.props.readonly,

      attributes: {
        class: `ample-editor-editor${ isFirefox ? " firefox" : "" }`,

        // Disables integration of the Grammarly extension within the editor (see
        // https://github.com/facebook/draft-js/issues/616 for additional context) - ideally we would contact
        // Grammarly to blacklist our domains, as they will still inject fonts and other cruft into the page
        "data-gramm": "false"
      },

      // Custom clipboard handling
      clipboardParser,
      clipboardSerializer,
      clipboardTextParser: buildClipboardTextParser(getView),

      handleDrop,
      handleDOMEvents: {
        blur: this._onEditorBlur,
        dragstart: editorDragStart,
        drop: this._onEditorDrop,
      },
      handlePaste,

      clipboardTextSerializer: slice => {
        // By default, prosemirror-view adds two newlines between each block in the output, but we'd prefer one
        return slice.content.textBetween(0, slice.content.size, "\n");
      },

      // ProseMirror uses mutation observers to learn about selection changes and other dom changes using shared
      // code, but this means that our custom widgets - like task details - that are inline in the document will
      // trigger selection changes that prosemirror tries to handle. Unfortunately, prosemirror will resolve a
      // selection at the end of the _text_ inside the paragraph inside a check-list-item (or similar) to the end
      // of the paragraph, so any change to the selection inside task details (e.g. to a date input) will result in
      // the selection being changed from 41 (for example, that being the last text position in the check-list-item)
      // to 42 (the closing position of the paragraph inside the check-list-item). That _would_ be fine, except for
      // some specific code in prosemirror-view to select the bias:
      //    var bias = origin == "pointer" || (view.state.selection.head < $head.pos && !inWidget) ? 1 : -1;
      // In this code, `view.state.selection.head` would have been 41, while the new calculated `$head.pos` would be
      // 42, so we get a positive bias, resulting in final selection of the _next_ node (e.g. at pos 44). The next
      // time the selection changes, the bias will be -1 (since we still resolve to a `$head.pos` of 42), moving the
      // final selection back to 41. This back-and-forth repeats on each selection change, with the very unfortunate
      // side effect of moving the focus around on short screens (mobile) so the content below the task detail is
      // visible on screen.
      // To add to the unfortunate pieces above, while ProseMirror gives us a hook to control this behavior (and all
      // selection behavior), it's undocumented, and doesn't receive the bias argument. So, we'll just see if
      // prosemirror is trying to set a collapsed selection at the end of a check list item and always apply a
      // negative bias under the assumption that the selection is "in" the task detail section.
      createSelectionBetween: (view, $anchor, $head) => {
        if ($anchor.pos === $head.pos && $anchor.parent && $anchor.parent.type === schema.nodes.check_list_item) {
          // Make sure we're at the end of the check-list-item node (i.e. in the Task Detail)
          if ($anchor.pos === $anchor.end($anchor.depth)) {
            return TextSelection.between($anchor, $head, -1);
          }
        }

        // This results in the default behavior (`return TextSelection.between($anchor, $head, bias)`), but since
        // we don't have the actual `bias` here, we can't just inline that code
        return null;
      },

      // Settings that are not expected to change
      completedTasksNow: this.props.completedTasksNow,
      initialEditorTab: this.props.initialEditorTab,
      interactiveHeadingAnchors: this.props.interactiveHeadingAnchors,
      taskCompletionEffectLevelEm: this.props.taskCompletionEffectLevelEm,

      // Callbacks to interact with the hosting app
      hostApp: this.props.hostApp,

      scrollMargin: scrollConfig,
      scrollThreshold: scrollConfig,

      transformPastedHTML,
      transformPastedText,

      // Uncomment this to get some useful info on what is incoming during paste operations. Alternatively, if you
      // are on linux, you can run `xclip -selection clipboard -o -t text/html` to see the text/html content in the
      // clipboard.
      // transformPastedText: text => {
      //   console.log("transformPastedText", text);
      //   return text;
      // },
    };

    if (this.props.handleScrollToSelection) {
      editorProps.handleScrollToSelection = this.props.handleScrollToSelection;
    }

    const view = new EditorView(element, editorProps);

    if (this.props.onEditorViewCreated) {
      this.props.onEditorViewCreated(view);
    }

    this._editorView = view;
  };

  // --------------------------------------------------------------------------
  _destroyEditorView = () => {
    if (this._editorView) {
      this._editorView.destroy();
      this._editorView = null;
    }
  };

  // --------------------------------------------------------------------------
  _dispatchAutomaticTransaction = (allowAutomatedChanges = null) => {
    if (allowAutomatedChanges === null) allowAutomatedChanges = this._allowAutomatedChanges();

    const transaction = this._editorState.tr;

    transaction.setMeta(TRANSACTION_META_KEY.AUTOMATIC, true);
    transaction.setMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY, false);

    // Tells appendTransaction functions in plugins not to make changes
    if (!allowAutomatedChanges) transaction.setMeta(TRANSACTION_META_KEY.READONLY, true);

    this.dispatchTransaction(transaction);
  };

  // --------------------------------------------------------------------------
  _isArrowCapturingMenuOpen() {
    if (this._editorView) {
      const arrowCapturingMenuCheckFunctions = [
        "isDateSuggestionMenuOpen",
        "isExpressionMenuOpen",
        "isNoteLinkMenuOpen",
      ];

      for (let i = 0; i < arrowCapturingMenuCheckFunctions.length; i++) {
        const menuCheckFunctionName = arrowCapturingMenuCheckFunctions[i];

        let isOpen = false;

        this._editorView.someProp(menuCheckFunctionName, menuCheckFunction => {
          isOpen = menuCheckFunction();
          return true;
        });

        if (isOpen) return true;
      }
    }

    return false;
  }

  // --------------------------------------------------------------------------
  _onClick = event => {
    if (event.target === this.getContainer() && this._editorView && !this._editorView.hasFocus()) {
      this._editorView.focus();
    }

    const { onClick } = this.props;
    if (onClick) onClick(event);
  };

  // --------------------------------------------------------------------------
  _onDrop = event => {
    if (event.isDefaultPrevented()) return;

    handleDropFiles(this._editorView, event);
  };

  // --------------------------------------------------------------------------
  // This is ProseMirror's internal editor blur (DOM) event, which we don't otherwise find out about in plugins, since
  // the selection doesn't change on blur
  _onEditorBlur = () => {
    this.dispatchTransaction(this._editorState.tr.setMeta(TRANSACTION_META_KEY.HAS_FOCUS, false));
  };

  // --------------------------------------------------------------------------
  // Inlined from prosemirror-view `editHandlers.drop`, modified to allow the handleDrop to receive:
  //  1. the transaction that will eventually be used to insert the slice in the document
  //  2. the insertion position
  // and to allow for a callback to the outer component, so it knows what exactly was dropped in the editor
  _onEditorDrop = (view, event) => {
    const slice = dispatchDropTransform(view, event);

    if (slice) {
      const { onEditorDrop } = this.props;
      if (onEditorDrop) onEditorDrop(slice);
    }
  };

  // --------------------------------------------------------------------------
  _onFocus = event => {
    if (!this._hasBeenFocused) {
      this._hasBeenFocused = true;

      // Dispatch a transaction so plugin views can change state based on the focus change - note that we only want to
      // do this on the first focus of the editor, or we risk stepping on the state of the editor when various popups
      // change the focus.
      // In Firefox, if a transaction has already been dispatched (e.g. an automatic transaction on document load), this
      // will result in the focus-based selection not being set if we dispatch this transaction immediately. We need to
      // let the focus event complete, _then_ we can dispatch the transaction.
      // Unfortunately, on iOS this needs to happen immediately, or the cursor _will_ jump to the beginning of the
      // document.
      if (isIOS) this._dispatchAutomaticTransaction();
      else defer(this._dispatchAutomaticTransaction);
    }

    if (this.props.onFocus) this.props.onFocus(event);
  };

  // --------------------------------------------------------------------------
  _onKeyDown = event => {
    const container = this.getContainer();
    if (container) {
      container.className = this._buildClassName() + " keyboard-interaction";
    }

    const { onKeyDown } = this.props;
    if (onKeyDown) onKeyDown(event);
  };

  // --------------------------------------------------------------------------
  _onKeyUp = event => {
    if (this.hasFocus()) {
      this._editorView.someProp("handleKeyUp", f => f(this._editorView, event));
    }
  };

  // --------------------------------------------------------------------------
  _onMouseMove = () => {
    const container = this.getContainer();
    if (container) {
      container.className = this._buildClassName() + " mouse-interaction";
    }
  };

  // --------------------------------------------------------------------------
  _onPaste = event => {
    if (event.isPropagationStopped()) return;

    if (event.target) {
      // There are a couple different ways (As of 7/2020) that a ProseMirror instance can be nested inside the dom node
      // that this is handler is installed on: Rich Footnote description editors; tasks-editors (i.e. hidden tasks).
      // In those cases, even if those nodes have onPaste handlers that call any/all of:
      //    event.stopPropagation();
      //    event.nativeEvent.stopImmediatePropagation();
      //    event.nativeEvent.stopPropagation();
      // none of these can be detected when this handler is later called, nor do they stop it from being called. It's
      // unclear why this is (possibly due to being separate react roots under the same dom element?), and may vary
      // from browser to browser (tested in Chrome on macOS). To work around this, we'll check that the closest
      // ProseMirror ancestor is actually this editor instance.
      if (findProseMirrorAncestor(event.target) !== this._editorView.dom) return;
    }

    handlePasteFiles(this._editorView, event);
  };

  // --------------------------------------------------------------------------
  // Ensure that a transaction is dispatched at least once per minute or so, giving plugins a chance to update by
  // implementing appendTransaction to update any state that can change over time (e.g. due dates, reminders, etc)
  _onTimerTick = () => {
    // The point of this dispatch is to make automated changes, so if we're not going to allow automated changes,
    // we don't need to bother dispatching.
    const allowAutomatedChanges = this._allowAutomatedChanges();

    // If this auto dispatch was disallowed, we want to check fairly soon for it to be allowed
    let timeout = 1000;

    if (allowAutomatedChanges) {
      const secondsSinceLastDispatch = currentTimestamp() - this._lastDispatchAt;
      if (secondsSinceLastDispatch > 50) {
        this._dispatchAutomaticTransaction(allowAutomatedChanges);
      }

      timeout = 60000;
    }

    this._timer = setTimeout(this._onTimerTick, timeout);
  };
}
