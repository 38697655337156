import { DOMParser, DOMSerializer, Fragment, Schema } from "prosemirror-model"

import { isSafari } from "lib/ample-editor/lib/client-info"
import { LANGUAGE, languageFromText } from "lib/ample-editor/lib/code-block/code-block-util"
import { CHILDREN_COLLAPSED_CLASS, VISIBLE_LIST_ITEM_CLASS } from "lib/ample-editor/lib/collapsible-defines"
import { hexColorFromCompoundColor, hexColorFromCycleColor, hexColorFromString, isCycleVarColor, numberFromCycleColor } from "lib/ample-editor/lib/color-util"
import { hrefFromAttribute, targetFromURL } from "lib/ample-editor/lib/link-util"
import { indentFromListElement } from "lib/ample-editor/lib/list-item-util"
import tableNodes from "lib/ample-editor/lib/table/table-schema"
import { DEFAULT_ATTRIBUTES_BY_NODE_NAME } from "lib/ample-util/default-node-attributes"
import { derivedUUIDFromUUID, isDoneFromTask, TASK_ATTRIBUTES } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
// Code commonly works on all list item types, but may make assumptions about what
// content is valid for a list item. This is mostly extracted to a constant to enshrine
// the assumption that list items all have the same valid content.
const LIST_ITEM_CONTENT = "paragraph|code_block";

// --------------------------------------------------------------------------
function getBulletListItemAttrs(dom) {
  const attrs = getListItemAttrs(dom);

  Object.keys(dom.dataset).forEach(dataAttributeName => {
    const dataAttributeValue = dom.dataset[dataAttributeName];

    switch (dataAttributeName) {
      // Integer
      case "scheduledAt":
        attrs.scheduledAt = parseInt(dataAttributeValue, 10);
        break;

      // String
      case "duration":
      case "notify":
      case "repeat":
        attrs[dataAttributeName] = dataAttributeValue;
        break;

      default:
        break;
    }
  });

  return attrs;
}

// --------------------------------------------------------------------------
function getCheckListItemAttrs(dom) {
  const attrs = getListItemAttrs(dom);

  TASK_ATTRIBUTES.forEach(attributeName => {
    const attributeValue = dom.getAttribute(`data-${ attributeName }`);
    if (typeof(attributeValue) === "undefined" || attributeValue === null) return;

    switch (attributeName) {
      // Integer
      case "createdAt":
      case "completedAt":
      case "crossedOutAt":
      case "dismissedAt":
      case "due":
      case "pointsUpdatedAt":
      case "startAt":
        attrs[attributeName] = parseInt(attributeValue, 10);
        break;

      // Number
      case "points": {
        const number = Number(attributeValue);
        if (!isNaN(number)) attrs[attributeName] = number;
      }
      break;

      // String
      case "dueDayPart":
      case "duration":
      case "flags":
      case "repeat":
      case "startRule":
        attrs[attributeName] = attributeValue;
        break;

      default:
        break;
    }
  });

  return attrs;
}

// --------------------------------------------------------------------------
function getListItemAttrs(dom) {
  const attrs = {
    collapsed: dom.classList.contains(CHILDREN_COLLAPSED_CLASS),
    indent: indentFromListElement(dom) || 0,
  };

  // We don't want to use the exact same UUID, but it's convenient to be able to work backwards to the uuid that
  // the node came from in cases of drag+drop or cut+paste
  const uuid = dom.getAttribute("data-uuid");
  if (typeof(uuid) !== "undefined" && uuid !== null) {
    attrs.uuid = derivedUUIDFromUUID(uuid);
  }

  return attrs;
}

// --------------------------------------------------------------------------
function getNumberListItemAttrs(dom) {
  const attrs = getListItemAttrs(dom);

  const match = (dom.className || "").match(/\boffset-(\d+)/);
  if (match) attrs.offset = parseInt(match[1], 10);

  return attrs;
}

// --------------------------------------------------------------------------
// Attributes to set on the DOM element
function mediaDOMAttrsFromNode(node) {
  const { attrs: { align, src, text, width } } = node;

  const domAttributes = { src };

  if (width !== null && typeof(width) !== "undefined" && !isNaN(width)) {
    domAttributes["data-width"] = width;
    domAttributes.style = `width: ${ width }px;`;
  }

  if (align) {
    domAttributes["data-align"] = align;
  }

  if (text) {
    domAttributes["data-text"] = text;
  }

  return domAttributes;
}

// --------------------------------------------------------------------------
// Attributes to set on the ProseMirror node
function mediaNodeAttrsFromDOM(dom) {
  const attrs = { src: dom.getAttribute("src") };

  const align = dom.getAttribute("data-align");
  if (align) attrs.align = align;

  const width = dom.getAttribute("data-width");
  if (typeof(width) !== "undefined" && width !== null && !isNaN(width)) {
    attrs.width = parseInt(width, 10);
  }

  const text = dom.getAttribute("data-text");
  if (text) attrs.text = text;

  return attrs;
}

// --------------------------------------------------------------------------
function setCheckListItemAttrs(node, dom) {
  [ ...TASK_ATTRIBUTES, "uuid" ].forEach(attributeName => {
    const attributeValue = node.attrs[attributeName];

    if (typeof(attributeValue) !== "undefined" && attributeValue !== null) {
      dom.setAttribute(`data-${ attributeName }`, attributeValue);
    }
  });
}

// --------------------------------------------------------------------------
// :: Object
// [Specs](#model.NodeSpec) for the nodes defined in this schema.
export const nodes = {
  // --------------------------------------------------------------------------
  // :: NodeSpec The top level document node.
  doc: {
    attrs: {
      // Shape of each entry
      //  {
      //    // UUID of the task that was completed
      //    uuid: String,
      //    // Unix timestamp when task was completed
      //    checkedAt: Integer,
      //    // When the task was originally created
      //    createdAt: Integer,
      //    // The task points the task had at completion, potentially adjusted (e.g. halved if task is dismissed)
      //    value: Float,
      //
      //    // The following attributes are only present in recently completed tasks
      //
      //    // The content of the single paragraph node child of the original check_list_item
      //    p: Array?,
      //    // Only present if the task was dismissed
      //    dismissed: Boolean,
      //
      //    // Additionally, any check_list_item attributes not set to the default value (including uuid and createdAt,
      //    // which are listed above, but those two attributes aren't pruned in tasks completed a long time ago)
      //  }
      //
      // Additionally, there's one entry (typically the first entry) that indicates how many completed tasks have been
      // removed from this list (because they were completed so long ago), which is shaped as:
      //  {
      //    removedCount: Integer,
      //  }
      //
      // NOTE: as of 2/204 we no longer trim/remove old completed tasks, but documents in the wild may have this entry.
      //
      completedTasks: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["doc"]["completedTasks"] },

      // Each entry is a check_list_item node that has been toJSON-ed
      hiddenTasks: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["doc"]["hiddenTasks"] },

      // This is treated as pass-through data, allowing a document to be associated with the version of the serialized
      // data it was originally (deserialized and-) loaded from
      serializedVersion: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["doc"]["serializedVersion"] },

      // This is an object for arbitrary data storage in the doc. This is intended for properties about the document
      // that should apply for all users with access to the note, with relatively low cardinality
      //
      // As of 4/2024, known keys are:
      //  - "defaultTaskCompletionMode" - a value from TASK_COMPLETION_MODE indicating the default completion method
      //    the user prefers for this note - with `null`/`undefined` indicating `TASK_COMPLETION_MODE.NORMAL`
      //  - "maxOpenTasks" - an integer indicating the maximum number of open tasks that the user hopes to have in
      //    this note
      storage: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["doc"]["storage"] }
    },
    content: "(block|doc_child)+"
  },

  // --------------------------------------------------------------------------
  // Paragraph must be first after doc so it's the default block node type
  paragraph: {
    content: "inline*",
    group: "block",
    parseDOM: [
      // If font size is given as 17px or larger, we'll match header instead of paragraph
      { tag: "p", getAttrs: dom => (dom.style.fontSize.match(/(1[7-9]|[23][0-9])p[xt]/) ? false : null) },
      // We want <br> to paste as an empty paragraph, not a hard_break node which will get wrapped in a paragraph,
      // resulting in _two_ newlines for each pasted <br> - note that this relies on this node spec being defined
      // before the nodespec for hard_break in this array.
      { tag: "br", context: "doc/paragraph/" },
      // Slack uses a span with this class to demarcate when a user has left an empty blank line
      { tag: "span", getAttrs: dom => (dom.classList.contains("c-mrkdwn__br") ? null : false) }
    ],
    toDOM() {
      return [
        "p",
        // We don't include margin in paragraphs, but when copying to paste in other applications we need to let
        // them know that there are no margins on our paragraphs (e.g. when pasting in GMail, this will cause the
        // paragraphs in GMail to be single-spaced).
        { style: "margin:0" },
        0
      ]
    }
  },

  // --------------------------------------------------------------------------
  // :: NodeSpec A blockquote (`<blockquote>`) wrapping one or more blocks.
  blockquote: {
    content: "block+",
    group: "block",
    defining: true,
    parseDOM: [ { tag: "blockquote" } ],
    toDOM() {
      return [ "blockquote", 0 ]
    }
  },

  // --------------------------------------------------------------------------
  // :: NodeSpec A horizontal rule (`<hr>`).
  horizontal_rule: {
    group: "block",
    parseDOM: [ { tag: "hr" } ],
    toDOM() {
      return [ "hr", { class: "horizontal-rule" } ]
    }
  },

  // --------------------------------------------------------------------------
  // :: NodeSpec A heading textblock, with a `level` attribute that
  // should hold the number 1 to 6. Parsed and serialized as `<h1>` to
  // `<h6>` elements.
  heading: {
    attrs: {
      collapsed: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["heading"]["collapsed"] },
      level: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["heading"]["level"] },
    },
    content: "inline*",
    group: "block",
    defining: true,
    parseDOM: [
      { tag: "h1", attrs: { level: 1 } },
      { tag: "div, p, span", getAttrs: dom => (dom.style.fontSize.match(/(2[3-9]|3[0-9])p[xt]/) ? { level: 1 } : false) },
      { tag: "h2", attrs: { level: 2 } },
      { tag: "div, p, span", getAttrs: dom => (dom.style.fontSize.match(/2[0-2]p[xt]/) ? { level: 2 } : false) },
      // Substack defaults paragraph size to 19px, which is interpreted as h3, which is OK since it leaves space between
      // paragraphs that would otherwise be crunched. But if paragraph=h3, then their wimpy h3 headings need to be bigger, so
      // their esoteric fontSize of "1.375em" is deemed sufficient to merit larger-heading designation
      { tag: "h3", getAttrs: dom => (dom.style.fontSize.match("1.375em") ? { level: 2 } : false) },
      { tag: "h3", attrs: { level: 3 } },
      { tag: "div, p, span", getAttrs: dom => (dom.style.fontSize.match(/1[7-9]p[xt]/) ? { level: 3 } : false) },
      { tag: "h4", attrs: { level: 3 } },
      { tag: "h5", attrs: { level: 3 } },
      { tag: "h6", attrs: { level: 3 } }
    ],
    toDOM(node) {
      return [ "h" + Math.min(node.attrs.level, 3), { class: "heading" }, 0 ]
    }
  },

  // --------------------------------------------------------------------------
  // :: NodeSpec A code listing. Disallows marks or non-text inline
  // nodes by default. Represented as a `<pre>` element with a
  // `<code>` element inside of it.
  code_block: {
    attrs: {
      language: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["code_block"]["language"] },
    },
    content: "text*",
    marks: "",
    group: "block",
    code: true,
    defining: true,
    parseDOM: [
      {
        getAttrs: dom => {
          let language = Object.values(LANGUAGE).find(key => key === dom.getAttribute("data-language"));
          if (!language) language = languageFromText(dom.innerHTML.replace(/<br>/g, "\n"));

          return { language };
        },
        preserveWhitespace: "full",
        tag: "pre"
      }
    ],
    toDOM(node) {
      return [ "pre", { class: `code-block language-${ node.attrs.language || "unknown" }`, spellcheck: "false", "data-language": node.attrs.language },
        [ "div", { class: "code-block-background" },
          [ "code", 0 ]
        ]
      ];
    }
  },

  // --------------------------------------------------------------------------
  bullet_list_item: {
    attrs: {
      collapsed: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["bullet_list_item"]["collapsed"] },

      // ?Integer (unix timestamp) time at which the bullet list item was crossed out
      crossedOutAt: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["bullet_list_item"]["crossedOutAt"] },

      // String? ISO 8601 duration string indicating how long the item scheduled for
      duration: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["bullet_list_item"]["duration"] },

      // String? flags-string indicating flags enabled.
      flags: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["bullet_list_item"]["flags"] },

      indent: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["bullet_list_item"]["indent"] },

      // String? ISO 8601 duration describing how long before `scheduledAt` a notification should be presented
      notify: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["bullet_list_item"]["notify"] },

      // ?String Indicates how the item repeats, which can be one of:
      //
      //    1. RFC 5545 RRULE for recurrence, starting from `due`
      //
      //      e.g. "RRULE:FREQ=DAILY"
      //            means "every day"
      //
      //      e.g. "DTSTART:20120201T093000Z\nRRULE:FREQ=WEEKLY;INTERVAL=5;BYDAY=MO,FR"
      //            means: "every 5 weeks on Monday, Friday" relative to 2012/02/01 09:30:00
      //
      //    2. ISO 8601 duration string (see https://en.wikipedia.org/wiki/ISO_8601#Durations), indicating how long
      //       after being completed that this item should repeat. If the duration has non-time (i.e. after the "T")
      //       components, it's assumed that the duration starts at the beginning of the day on the completion date, so
      //       a value of "P2MT11H" would mean "2 months after completed, at 11 am", regardless of what time of the day
      //       the item was completed at. If the duration has _only_ time components, then the time is considered to be
      //       relative.
      //
      //      e.g. "P2MT11H" means "2 months after completed, at 11 am"
      //
      //      e.g. "PT11H" means "11 hours after completed"
      //
      // Note that empty-ish values can be used to denote the intent to use this attribute, e.g. "RRULE:" (for the
      // intent to use the first format) or "" (for the intent to use the second format).
      repeat: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["bullet_list_item"]["repeat"] },

      // ?Integer (unix timestamp) when item is scheduled to begin
      scheduledAt: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["bullet_list_item"]["scheduledAt"] },

      uuid: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["bullet_list_item"]["uuid"] },
    },
    content: LIST_ITEM_CONTENT,
    defining: true,
    draggable: true,
    group: "block",
    isListItem: true,
    parseDOM: [
      {
        tag: "ul li:not([role='checkbox'])",
        getAttrs: getBulletListItemAttrs
      },
      {
        tag: "div.bullet-list-item",
        getAttrs: getListItemAttrs,
        contentElement: node => node.querySelector(`.${ VISIBLE_LIST_ITEM_CLASS }`) || node,
      },
    ],
    toDOM(node) {
      const { attrs, attrs: { collapsed, indent } } = node;

      const domAttributes = {
        class: `bullet-list-item indent-${ indent }${ collapsed ? ` ${ CHILDREN_COLLAPSED_CLASS }` : "" }`,
      };

      [ "duration", "notify", "repeat", "scheduledAt", "uuid" ].forEach(attributeName => {
        const attributeValue = attrs[attributeName];
        if (typeof(attributeValue) !== "undefined" && attributeValue !== null) {
          if (attributeName === "scheduledAt") attributeName = "scheduled-at";
          domAttributes[`data-${ attributeName }`] = attributeValue;
        }
      });

      // Historically Android Chrome - and as of 2/2021 some versions of normal Chrome - has some issues with backspace
      // and enter when we use real <ul><li> nodes:
      //  1. Backspace on an empty bullet-list-item deletes the item _above_ the one the cursor is on
      //  2. Enter at the end of a bullet-list-item with content when the autocomplete underline is showing on the last
      //     word will visually insert a new item, but prosemirror doesn't see it, resulting in an inconsistent state
      // To work around these, we'll use divs.
      return [ "div", domAttributes, [ "div", { class: VISIBLE_LIST_ITEM_CLASS }, 0 ] ];
    }
  },

  // --------------------------------------------------------------------------
  check_list_item: {
    attrs: {
      uuid: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["uuid"] },

      indent: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["indent"] },

      // Bool for whether the node's children are hidden
      collapsed: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["collapsed"] },

      // ?Integer (unix timestamp) temporary attribute; setting to non-null value will complete the task, as of whatever
      // specific timestamp the attribute is set to.
      completedAt: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["completedAt"] },

      // ?Integer (unix timestamp) when item was first created in the document
      createdAt: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["createdAt"] },

      // ?Integer (unix timestamp) temporary attribute; setting to non-null value with cross out the task
      crossedOutAt: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["crossedOutAt"] },

      // ?Integer (unix timestamp) the time by which the task needs to be completed - this is distinct from the `due`
      // attribute, which indicates when a task is scheduled to start.
      deadline: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["deadline"] },

      // ?Integer (unix timestamp) temporary attribute; setting to non-null value will dismiss the task, as of whatever
      // specific timestamp the attribute is set to.
      dismissedAt: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["dismissedAt"] },

      // ?Integer (unix timestamp) when item is next due
      due: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["due"] },

      // String? the fuzzy day part (`DAY_PARTS` keys) that the exact due time can be re-scheduled within. This only
      // applies to the next instance, for non-recurring tasks.
      dueDayPart: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["dueDayPart"] },

      // String? ISO 8601 duration describing how long before the `due` datetime a notification should be presented
      notify: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["notify"] },

      // ?String Indicates how the item repeats, which can be one of:
      //
      //    1. RFC 5545 RRULE for recurrence, starting from `due`
      //
      //      e.g. "RRULE:FREQ=DAILY"
      //            means "every day"
      //
      //      e.g. "DTSTART:20120201T093000Z\nRRULE:FREQ=WEEKLY;INTERVAL=5;BYDAY=MO,FR"
      //            means: "every 5 weeks on Monday, Friday" relative to 2012/02/01 09:30:00
      //
      //    2. ISO 8601 duration string (see https://en.wikipedia.org/wiki/ISO_8601#Durations), indicating how long
      //       after being completed that this item should repeat. If the duration has non-time (i.e. after the "T")
      //       components, it's assumed that the duration starts at the beginning of the day on the completion date, so
      //       a value of "P2MT11H" would mean "2 months after completed, at 11 am", regardless of what time of the day
      //       the item was completed at. If the duration has _only_ time components, then the time is considered to be
      //       relative.
      //
      //      e.g. "P2MT11H" means "2 months after completed, at 11 am"
      //
      //      e.g. "PT11H" means "11 hours after completed"
      //
      // Note that empty-ish values can be used to denote the intent to use this attribute, e.g. "RRULE:" (for the
      // intent to use the first format) or "" (for the intent to use the second format).
      repeat: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["repeat"] },

      // ?String | Integer
      //  If due is non-null:
      //    ISO 8601 duration string indicating how long _before_ the next due date the item will start showing up at
      //    the top level of the note
      //
      //  If due is null:
      //    unix timestamp indicating when the item should show up at the top level of the note
      //
      startAt: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["startAt"] },

      // String? ISO 8601 duration string indicating how long _before_ the next due date the item will start showing up
      // at the top level of the note. Like the duration option used in relative repeat rules, how this is interpreted
      // can vary depending on the duration of the repeat rule:
      //
      //    1. Repeat rules that recur at least daily - whether fixed or relative repeat - evaluate the non-time
      //       component of this duration to be a duration before the next due date, however the time is set to the
      //       beginning of that day, and any time components of the duration are evaluated by advancing to that time.
      //       In that case "P1DT9H" means "1 day before due, at 9 am", and "PT4H" means "the due day, at 4 am".
      //
      //    2. Repeat rules that recur less than daily (e.g. "every 4 hours" or "1 hour after complete") result in this
      //       being evaluated strictly as a duration before the next due date to set startAt to.
      //
      startRule: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["startRule"] },

      // String? ISO 8601 duration string indicating how long the task is estimated to take
      duration: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["duration"] },

      // String? flags-string indicating flags enabled. e.g. "I" for important and "U" for urgent. Note that these are
      // not mutually exclusive, and the order doesn't matter: "IU", "UI", "I", and "U" are all valid
      flags: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["flags"] },

      // ?Float additional points that have been assigned to this item, beyond what can be calculated from the other
      // TIC values (e.g. added each time the note is opened with this item visible).
      points: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["points"] },

      // ?Integer (unix timestamp) the last day additional points were assigned to this item to increment`points`
      pointsUpdatedAt: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"]["pointsUpdatedAt"] },
    },
    content: LIST_ITEM_CONTENT,
    defining: true,
    draggable: true,
    group: "doc_child",
    isListItem: true,
    parseDOM: [
      // Copy from Evernote web, which comes through as:
      //    <div style="font-family:gotham, helvetica, arial, sans-serif;font-size:14px;">
      //      <input type="checkbox">
      //      <span style="font-family: gotham, helvetica, arial, sans-serif; font-size: 14px;">text here</span>
      //    </div>
      // OR:
      //    <div style="font-family:gotham, helvetica, arial, sans-serif;font-size:14px;">
      //      <input type="checkbox">&nbsp;text here
      //    </div>
      // OR:
      //    <div style="font-family:gotham, helvetica, arial, sans-serif;font-size:14px;">
      //      <input type="checkbox">nested
      //    </div>
      // OR
      //    <div style="font-family:&quot;Helvetica Neue&quot;, Arial, sans;font-size:16px;">
      //      <span style="font-family: gotham, helvetica, arial, sans-serif; font-size: 14px;">
      //        <input type="checkbox">blah
      //      </span>
      //    </div>
      {
        tag: 'div:not(.check-list-item) > input[type="checkbox"], span > input[type="checkbox"]',
        getContent: (node, schema) => {
          const parent = node.parentElement;

          if (parent.childNodes.length > 1 && parent.childNodes[0].nodeName === "#text") {
            parent.innerHTML = parent.innerHTML.replace(/^(?:&nbsp;\s*)+/, "");
          }

          // Note that we're actually modifying the incoming DOM node here, which is necessary so the span containing
          // the input element that is matched is not itself matched by another parse rule, causing it to be double
          // inserted.
          const child = parent.querySelector('input[type="checkbox"]');
          parent.removeChild(child);

          const parser = DOMParser.fromSchema(schema);
          const parsed = parser.parseSlice(parent);
          return parsed.content;
        },
        getAttrs: dom => {
          const parent = dom.parentElement;

          let indent = 0;

          // Evernote mostly uses `&nbsp;`s to indent the items
          if (parent.childNodes.length > 1 && parent.childNodes[0].nodeName === "#text") {
            const spaces = parent.childNodes[0].wholeText.match(/\s/g).length;
            // Evernote believes in 5-space indents, mixing &nbsp; with " " (wholeText normalizes to all be spaces)
            indent = Math.floor(spaces / 5);
          }

          return { indent };
        }
      },

      // Copy from Evernote app, which comes through as:
      //    <div style="-en-clipboard:true;">
      //      <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="en-todo"/>
      //      <span style="font-size: 14px; font-family: gotham, helvetica, arial, sans-serif;">text here</span>
      //    </div>
      // OR:
      //    <div>
      //      <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="en-todo"/>
      //      text here
      //    </div>
      {
        tag: "div > .en-todo",
        getContent: (node, schema) => {
          const parser = DOMParser.fromSchema(schema);
          const parsed = parser.parseSlice(node.nextSibling || node);
          return parsed.content;
        }
      },

      // Copy from Google Docs, which comes through as:
      // <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
      //   <li dir="ltr" style="list-style-type:disc;font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1" role="checkbox" aria-checked="false">
      //     <p dir="ltr" style="line-height:1.38;margin-top:0pt;margin-bottom:0pt;" role="presentation"><span style="font-size:11pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Get lit</span></p>
      //   </li>
      // </ul>
      {
        tag: "ul li[role='checkbox']",
        getContent: (node, schema) => {
          const parser = DOMParser.fromSchema(schema);
          const parsed = parser.parseSlice(node);
          return parsed.content;
        }
      },

      // Need to ignore the version with the span so it isn't double-inserted
      {
        tag: "div > .en-todo + span",
        ignore: true
      },

      {
        tag: ".check-list-item",
        getAttrs: getCheckListItemAttrs,
        getContent: (node, schema) => {
          const parser = DOMParser.fromSchema(schema);
          try {
            const parsed = parser.parseSlice(node.lastChild || node);
            return parsed.content;
          } catch (error) {
            // `TypeError: Cannot read properties of null (reading `firstChild`)` in `ParseContext.addAll
            return Fragment.empty;
          }
        }
      },

      // Basic fall-back rule, not great, but it's something
      { tag: 'input[type="checkbox"] + *' },
    ],
    toDOM(node) {
      const { attrs, attrs: { collapsed, indent } } = node;

      // DOMOutputSpec arrays have to have the content hole (`0`) as the only child of the parent node,
      // but we want to output a minimal HTML structure to make copy-paste as simple as possible, so we
      // will build the DOM element manually.

      const serializer = DOMSerializer.fromSchema(schema);
      const content = serializer.serializeFragment(node.content);

      const container = document.createElement("div");
      container.className = `check-list-item indent-${ indent }${ collapsed ? ` ${ CHILDREN_COLLAPSED_CLASS }` : "" }`;

      const checkbox = document.createElement("input");
      checkbox.setAttribute("type", "checkbox");
      if (isDoneFromTask(attrs)) checkbox.setAttribute("checked", "true");
      container.appendChild(checkbox);

      content.className = "content-container";
      container.appendChild(content);

      setCheckListItemAttrs(node, container);

      return container;
    }
  },

  // --------------------------------------------------------------------------
  number_list_item: {
    attrs: {
      collapsed: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["number_list_item"]["collapsed"] },
      indent: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["number_list_item"]["indent"] },
      offset: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["number_list_item"]["offset"] },
      uuid: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["number_list_item"]["uuid"] },
    },
    content: LIST_ITEM_CONTENT,
    defining: true,
    draggable: true,
    group: "block",
    isListItem: true,
    parseDOM: [
      {
        tag: "ol li",
        getAttrs: getNumberListItemAttrs,
      },
      {
        tag: "div.number-list-item",
        getAttrs: getNumberListItemAttrs,
        contentElement: node => node.querySelector(`.${ VISIBLE_LIST_ITEM_CLASS }`) || node,
      },
    ],
    toDOM(node) {
      const { attrs: { collapsed, indent, offset, uuid } } = node;

      let className = `number-list-item indent-${ indent } offset-${ offset }`;
      if (collapsed) className += ` ${ CHILDREN_COLLAPSED_CLASS }`;
      if (isSafari) className += " use-counter-reset";

      const domAttributes = {
        class: className,
        style: `counter-increment: number-list-items-${ indent } ${ offset }`,
      };
      if (uuid) domAttributes["data-uuid"] = uuid;

      // Historically Android Chrome - and as of 2/2021 some versions of normal Chrome - has some issues with
      // backspace and enter when we use real <ul><li> nodes:
      //  1. Backspace on an empty bullet-list-item deletes the item _above_ the one the cursor is on
      //  2. Enter at the end of a bullet-list-item with content when the autocomplete underline is showing on the
      //     last word will visually insert a new item, but prosemirror doesn't see it, resulting in an inconsistent
      //     state
      // To work around these, we'll use divs.
      return [ "div", domAttributes, [ "div", { class: VISIBLE_LIST_ITEM_CLASS }, 0 ] ];
    }
  },

  // --------------------------------------------------------------------------
  text: {
    group: "inline"
  },

  // --------------------------------------------------------------------------
  attachment: {
    attrs: {
      // Roughly corresponds to the object[data] HTML attribute, this is a pseudo-URL for
      // the attachment. When uploading, this will be `local://LOCAL_FILE_UUID` and once
      // uploaded, will be `attachment://ATTACHMENT_UUID`
      data: {},

      expanded: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["attachment"]["expanded"] },

      // The filename of the attachment, including the extension
      name: {},

      // Text that can be used to index the attachment content in search
      text: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["attachment"]["text"] },

      // The content-type of the attachment, corresponding to the object[type] HTML attribute
      type: {},
    },
    draggable: true,
    group: "block",
    selectable: true,

    parseDOM: [
      {
        tag: 'object[type="application/pdf"]',
        getAttrs: dom => {
          const data = dom.getAttribute("data");
          const type = dom.getAttribute("type") || "application/pdf";

          const nameDataAttribute = dom.getAttribute("data-name");

          let name;
          if (nameDataAttribute) {
            name = nameDataAttribute;
          } else {
            const namePieces = (data || "file.pdf").split("/");
            name = namePieces[namePieces.length - 1];
          }

          return { data, name, type };
        }
      }
    ],
    toDOM(node) {
      const { data, name, type } = node.attrs;

      return [
        "object",
        {
          "data-name": name,
          data,
          type,
        }
      ];
    },
  },

  // --------------------------------------------------------------------------
  embed: {
    attrs: {
      aspectRatio: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["embed"]["aspectRatio"] },
      // String value, the meaning of which is dictated by the protocol, e.g. "plugin://123-abc"
      src: {},
    },
    draggable: true,
    group: "block",
    parseDOM: [
      {
        // JSDOM doesn't support the `:has()` selector, so in tests can't ensure the desired structure
        // eslint-disable-next-line no-process-env,no-undef
        tag: process.env.NODE_ENV !== "test" ? ".embed:has(> object[data])" : ".embed",
        getAttrs: dom => {
          const object = dom.querySelector("object[data]");
          const attrs = { src: object.getAttribute("data") };

          if (object.hasAttribute("data-aspect-ratio")) {
            const aspectRatio = parseFloat(object.getAttribute("data-aspect-ratio"), 10);
            if (!Number.isNaN(aspectRatio)) attrs.aspectRatio = aspectRatio;
          }

          return attrs;
        }
      }
    ],
    selectable: true,
    toDOM(node) {
      const { attrs: { aspectRatio, src } } = node;

      const containerDOMAttributes = { class: "embed" };

      if (aspectRatio !== DEFAULT_ATTRIBUTES_BY_NODE_NAME["embed"]["aspectRatio"]) {
        containerDOMAttributes.style = `paddingBottom: ${ (1 / aspectRatio) * 100 }%`;
      }

      return [
        "div",
        containerDOMAttributes,
        [ "object", { data: src, "data-aspect-ratio": aspectRatio } ]
      ];
    },
  },

  // --------------------------------------------------------------------------
  image: {
    attrs: {
      // Alignment for image display - the default is left-aligned, which is an inline display, while the value
      // of "center" results in a block display that takes the full width of the parent
      align: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["image"]["align"] },

      // While this mimics the <img> tag's src attribute, we support some special values:
      //
      //  local://<uuid>
      //    this is used to indicate that the image refers to some local file, which is likely
      //    in the process of being uploaded (potentially on the current client, but also potentially on a different
      //    client.
      //
      //  data:...
      //    we don't actually support these directly, as a large payload here can have significant impact
      //    on the performance of the editor, but we make an effort to strip out data: urls to convert them to uploaded
      //    images
      src: {},

      // Text that can be indexed for search
      text: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["image"]["text"] },

      // The desired display width of the image, in px
      width: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["image"]["width"] },
    },
    content: "paragraph?",
    draggable: true,
    group: "inline",
    inline: true,
    selectable: true,
    parseDOM: [
      {
        contentElement: ".content-container",
        getAttrs: domNode => {
          const image = domNode.querySelector(".image img");
          return image ? mediaNodeAttrsFromDOM(image) : {};
        },
        tag: "div.image-view",
      },
      {
        getAttrs: mediaNodeAttrsFromDOM,
        tag: "img[src]",
      }
    ],
    toDOM(node) {
      const { attrs: { align } } = node;

      const hasContent = node.childCount > 0
      const isCenterAligned = align === "center";

      if (hasContent || isCenterAligned) {
        let className = "image-view";
        if (hasContent) className += " has-content";
        if (isCenterAligned) className += " center-aligned";

        return [
          "div", { class: className },
          [ "div", { class: "image" }, [ "img", { ...mediaDOMAttrsFromNode(node) } ] ],
          [ "div", { class: "content-container" }, 0 ],
        ];
      } else {
        return [ "img", { ...mediaDOMAttrsFromNode(node), class: "image" } ];
      }
    }
  },

  // --------------------------------------------------------------------------
  hard_break: {
    inline: true,
    group: "inline",
    selectable: false,
    toDOM() {
      return [ "br" ]
    },
    parseDOM: [
      { tag: "br" },
      // In some views (depending on how the message was entered?), Slack web will use a span instead of <br> tags for
      // hard breaks:
      //  <span class="c-mrkdwn__br" data-stringify-type="paragraph-break" .../></span>
      { tag: 'span.c-mrkdwn__br[data-stringify-type="paragraph-break"]', context: "doc/paragraph/" },
    ],
  },

  // --------------------------------------------------------------------------
  link: {
    attrs: {
      description: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["link"]["description"] },
      href: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["link"]["href"] },
      // Object|null formatted as:
      //  {
      //    url: String <URL of media file>
      //    text: String|undefined <text recognized in media at URL>
      //    type: String|undefined <content type of media at URL - defaults to "image">
      //  }
      //
      //  The following content types are supported:
      //    image/*
      //    video/*
      media: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["link"]["media"] },
    },
    inline: true,
    content: "(hard_break|image|text)*",
    defining: true,
    marks: "code em highlight strong strikethrough",
    group: "inline",
    toDOM(node) {
      const { description, href, media } = node.attrs;

      const attrs = { class: "link" };

      if (href && href.length > 0) {
        attrs.href = hrefFromAttribute(href);
        attrs.rel = "noopener noreferrer";
        attrs.target = targetFromURL(href);
      }
      if (description && description.length > 0) {
        if (typeof description === "string") {
          attrs.description = description;
        } else {
          attrs.description = JSON.stringify(description);
        }
      }
      if (media) {
        attrs.media = JSON.stringify(media);
      }

      return [ "a", attrs, 0 ];
    },
    parseDOM: [
      // Ignore the link icon in public notes when pasted
      {
        tag: "a.icon.material-icons",
        ignore: true
      },
      // Link from ample-web notes-list component
      {
        tag: "a.notes-list-item",
        contentElement: ".title",
        getAttrs: dom => {
          return { href: dom.getAttribute("href"), description: "", media: null };
        },
      },
      // Link from backlinks section
      {
        tag: "a.note-link",
        contentElement: ".note-name",
        getAttrs: dom => {
          return { href: dom.getAttribute("href"), description: "", media: null };
        },
      },
      {
        tag: "a",
        contentElement: dom => {
          return dom.querySelector(".note-name") || dom.querySelector(".title") || dom;
        },
        getAttrs(dom) {
          let description = dom.hasAttribute("description") ? dom.getAttribute("description") : "";
          if (description && description.startsWith('[{"type":') && description.endsWith("}]")) {
            try {
              description = JSON.parse(description);
            } catch (_error) {
              // Ignore, it's probably just a string that looks like a doc
            }
          }

          let media = null;
          if (dom.hasAttribute("media")) {
            try {
              media = JSON.parse(dom.getAttribute("media"));
            } catch (_error) {
              // Ignore failures
            }
          }

          return {
            description,
            href: dom.hasAttribute("href") ? dom.getAttribute("href") : "",
            media,
          };
        }
      },
    ]
  },

  // --------------------------------------------------------------------------
  table: tableNodes.table,
  table_row: tableNodes.table_row,
  table_cell: tableNodes.table_cell,

  // --------------------------------------------------------------------------
  video: {
    attrs: {
      src: {},

      // The desired display width of the video, in px
      width: { default: DEFAULT_ATTRIBUTES_BY_NODE_NAME["video"]["width"] },
    },
    draggable: true,
    group: "inline",
    inline: true,
    parseDOM: [
      {
        tag: "video",
        getAttrs(video) {
          const source = video.querySelector("source");
          return {
            ...mediaNodeAttrsFromDOM(video),
            src: (source || video).getAttribute("src"),
          };
        },
      },
    ],
    selectable: true,
    toDOM(node) {
      return [
        "video",
        {
          ...mediaDOMAttrsFromNode(node),
          class: "video",
          controls: "",
          playsInline: "",
          preload: "metadata",
        }
      ];
    },
  },
};

// --------------------------------------------------------------------------
export const marks = {
  // --------------------------------------------------------------------------
  // :: MarkSpec Code font mark. Represented as a `<code>` element.
  code: {
    parseDOM: [
      { tag: "code" },
      { tag: ".pasted-code" },
    ],
    toDOM() {
      return [ "code", { class: "code", spellcheck: "false" } ];
    }
  },

  // --------------------------------------------------------------------------
  // :: MarkSpec An emphasis mark. Rendered as an `<em>` element.
  // Has parse rules that also match `<i>` and `font-style: italic`.
  em: {
    parseDOM: [ { tag: "i" }, { tag: "em" }, { style: "font-style=italic" } ],
    toDOM() {
      return [ "em" ];
    }
  },

  // --------------------------------------------------------------------------
  highlight: {
    attrs: {
      backgroundColor: { default: null },
      color: { default: null },
    },
    parseDOM: [
      {
        getAttrs(dom) {
          const attrs = {};

          // Note that the `style` value is a CSSStyleDeclaration, so colors will get normalized to rgb/rgba
          const { backgroundColor, color } = dom.style;
          const styleColors = { backgroundColor, color };

          for (const colorKey of Object.keys(styleColors)) {
            const inlineAttr = { color: "data-text-color", backgroundColor: "data-background-color" }[colorKey];
            const styleColorHex = hexColorFromString(styleColors[colorKey]);
            if (dom.getAttribute(inlineAttr)) {
              const cycleColorString = `cycle-color-${ dom.getAttribute(inlineAttr) }`;
              const cycleHex = hexColorFromCycleColor(cycleColorString) || styleColorHex;
              attrs[colorKey] = `${ cycleColorString }${ cycleHex ? `/${ cycleHex }` : "" }`;
            } else if (styleColorHex) {
              attrs[colorKey] = styleColorHex;
            }
          }

          return attrs;
        },
        tag: "mark",
      }
    ],
    toDOM(node) {
      const elementAttrs = {};
      let style = "";

      const { attrs: { backgroundColor, color } } = node;
      const attrColors = { backgroundColor, color };
      for (const attrColorKey of Object.keys(attrColors)) {
        const attrColorValue = attrColors[attrColorKey]
        if (attrColorValue) {
          const styleName = { color: "color", backgroundColor: "background-color" }[attrColorKey];
          if (isCycleVarColor(attrColorValue)) {
            const inlineAttrName = { color: "data-text-color", backgroundColor: "data-background-color" }[attrColorKey];
            elementAttrs[inlineAttrName] = numberFromCycleColor(attrColorValue);
            const hex = hexColorFromCycleColor(attrColorValue) || hexColorFromCompoundColor(attrColorValue);
            if (hex) style += `${ styleName }: ${ hex };`;
          } else {
            style += `${ styleName }: ${ attrColorValue };`;
          }
        }
      }
      if (style) elementAttrs["style"] = style;
      return [ "mark", elementAttrs ];
    }
  },

  // --------------------------------------------------------------------------
  strikethrough: {
    // Since there's no UI exposed for this, it's best relegated to inputrules and copy/paste
    inclusive: false,
    parseDOM: [ { tag: "s" }, { style: "text-decoration=line-through" } ],
    toDOM() {
      return [ "s" ];
    }
  },

  // --------------------------------------------------------------------------
  // :: MarkSpec A strong mark. Rendered as `<strong>`, parse rules
  // also match `<b>` and `font-weight: bold`.
  strong: {
    parseDOM: [
      { tag: "strong" },
      // This works around a Google Docs misbehavior where
      // pasted content will be inexplicably wrapped in `<b>`
      // tags with a font-weight normal.
      { tag: "b", getAttrs: node => node.style.fontWeight !== "normal" && null },
      { style: "font-weight", getAttrs: value => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null },
    ],
    toDOM() {
      return [ "b" ];
    }
  },
};

// --------------------------------------------------------------------------
export const schema = new Schema({ nodes, marks });
