// --------------------------------------------------------------------------
const VECTOR_ICON_PATHS = {
  // https://pictogrammers.com/library/mdi/icon/alert-octagon-outline/
  "alert-octagon-outline": "M8.27,3L3,8.27V15.73L8.27,21H15.73C17.5,19.24 21,15.73 21,15.73V8.27L15.73,3M9.1,5H14.9L19,9.1V14.9L14.9,19H9.1L5,14.9V9.1M11,15H13V17H11V15M11,7H13V13H11V7",
  // Custom icon provided for AMPLENOTE-1540
  "align-center": "M9 9h6v6H9V9M3 19h18v2H3v-2M3 3h18v2H3V3",
  // Custom icon provided for AMPLENOTE-1540
  "align-left": "M3 7h6v6H3V7m0-4h18v2H3V3m18 8v2H11v-2h10M3 15h18v2H3v-2m0 4h18v2H3v-2Z",
  // https://materialdesignicons.com/icon/arrow-expand
  "arrow-expand": "M10,21V19H6.41L10.91,14.5L9.5,13.09L5,17.59V14H3V21H10M14.5,10.91L19,6.41V10H21V3H14V5H17.59L13.09,9.5L14.5,10.91Z",
  // https://materialdesignicons.com/icon/arrow-expand-horizontal
  "arrow-expand-horizontal": "M9,11H15V8L19,12L15,16V13H9V16L5,12L9,8V11M2,20V4H4V20H2M20,20V4H22V20H20Z",
  // https://pictogrammers.com/library/mdi/icon/calculator-variant-outline/
  "calculator-variant-outline": "M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M19 19H5V5H19V19M6.2 7.7H11.2V9.2H6.2V7.7M13 15.8H18V17.3H13V15.8M13 13.2H18V14.7H13V13.2M8 18H9.5V16H11.5V14.5H9.5V12.5H8V14.5H6V16H8V18M14.1 10.9L15.5 9.5L16.9 10.9L18 9.9L16.6 8.5L18 7.1L16.9 6L15.5 7.4L14.1 6L13 7.1L14.4 8.5L13 9.9L14.1 10.9Z",
  // Custom icon provided for AMPLENOTE-1540
  "caption-icon": "M21 4v12H3V4h18Zm-2 2H5v8h14V6ZM6 19h12v2H6v-2",
  // https://materialdesignicons.com/icon/checkbox-marked-outline
  "checkbox-marked-outline": "M19,19H5V5H15V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V11H19M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z",
  // https://materialdesignicons.com/icon/clock-check-outline
  "clock-check-outline": "M23.5 17L18.5 22L15 18.5L16.5 17L18.5 19L22 15.5L23.5 17M13.1 19.9C12.7 20 12.4 20 12 20C7.6 20 4 16.4 4 12S7.6 4 12 4 20 7.6 20 12C20 12.4 20 12.7 19.9 13.1C20.6 13.2 21.2 13.4 21.8 13.7C21.9 13.1 22 12.6 22 12C22 6.5 17.5 2 12 2S2 6.5 2 12C2 17.5 6.5 22 12 22C12.6 22 13.2 21.9 13.7 21.8C13.4 21.3 13.2 20.6 13.1 19.9M15.6 14.1L12.5 12.3V7H11V13L14.5 15.1C14.8 14.7 15.2 14.4 15.6 14.1Z",
  // https://materialdesignicons.com/icon/xml
  "code-formatting": "M12.89,3L14.85,3.4L11.11,21L9.15,20.6L12.89,3M19.59,12L16,8.41V5.58L22.42,12L16,18.41V15.58L19.59,12M1.58,12L8,5.58V8.41L4.41,12L8,15.58V18.41L1.58,12Z",
  // https://materialdesignicons.com/icon/file-document-box
  "file-document-box": "M14,17H7V15H14M17,13H7V11H17M17,9H7V7H17M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z",
  // https://materialdesignicons.com/icon/file-document-outline
  "file-document-outline": "M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z",
  // https://materialdesignicons.com/icon/file-move-outline
  "file-move-outline": "M14 2H6C4.9 2 4 2.9 4 4V20C4 20.41 4.12 20.8 4.34 21.12C4.41 21.23 4.5 21.33 4.59 21.41C4.95 21.78 5.45 22 6 22H13.53C13 21.42 12.61 20.75 12.35 20H6V4H13V9H18V12C18.7 12 19.37 12.12 20 12.34V8L14 2M18 23L23 18.5L20 15.8L18 14V17H14V20H18V23Z",
  // https://materialdesignicons.com/icon/format-header-1
  "format-header-1": "M3,4H5V10H9V4H11V18H9V12H5V18H3V4M14,18V16H16V6.31L13.5,7.75V5.44L16,4H18V16H20V18H14Z",
  // https://materialdesignicons.com/icon/format-header-2
  "format-header-2": "M3,4H5V10H9V4H11V18H9V12H5V18H3V4M21,18H15A2,2 0 0,1 13,16C13,15.47 13.2,15 13.54,14.64L18.41,9.41C18.78,9.05 19,8.55 19,8A2,2 0 0,0 17,6A2,2 0 0,0 15,8H13A4,4 0 0,1 17,4A4,4 0 0,1 21,8C21,9.1 20.55,10.1 19.83,10.83L15,16H21V18Z",
  // https://materialdesignicons.com/icon/format-header-3
  "format-header-3": "M3,4H5V10H9V4H11V18H9V12H5V18H3V4M15,4H19A2,2 0 0,1 21,6V16A2,2 0 0,1 19,18H15A2,2 0 0,1 13,16V15H15V16H19V12H15V10H19V6H15V7H13V6A2,2 0 0,1 15,4Z",
  // https://materialdesignicons.com/icon/format-text-variant
  "format-text-variant": "M9.6,14L12,7.7L14.4,14M11,5L5.5,19H7.7L8.8,16H15L16.1,19H18.3L13,5H11Z",
  // From Liz per AMPLENOTE-1628
  "graph-view": "M8.5 1.75a3 3 0 0 1 2.693 4.324l2.972 3.566a4.5 4.5 0 1 1-1.002 7.603l-2.418 1.613.005.144a2.25 2.25 0 1 1-1.253-2.017l2.327-1.552a4.488 4.488 0 0 1-.324-1.681 4.48 4.48 0 0 1 .905-2.707l-2.89-3.469A3 3 0 1 1 8.5 1.75ZM4 12.25a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm13.5-9a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z",
  // https://materialdesignicons.com/icon/keyboard-esc
  "keyboard-esc": "M1 7H7V9H3V11H7V13H3V15H7V17H1V7M11 7H15V9H11V11H13C14.11 11 15 11.9 15 13V15C15 16.11 14.11 17 13 17H9V15H13V13H11C9.9 13 9 12.11 9 11V9C9 7.9 9.9 7 11 7M19 7H21C22.11 7 23 7.9 23 9V10H21V9H19V15H21V14H23V15C23 16.11 22.11 17 21 17H19C17.9 17 17 16.11 17 15V9C17 7.9 17.9 7 19 7Z",
  // Custom
  "literal-formatting": "M20 2a2 2 0 0 1 1.995 1.85L22 4v16c0 1.06-.81 1.919-1.85 1.995L20 22H4a2 2 0 0 1-1.995-1.85L2 20V4c0-1.06.81-1.919 1.85-1.995L4 2h16Zm0 2H4v16h16V4Zm-6.794 1.674 4.005 10.606h.358c.229 0 .49.042.685.217a.763.763 0 0 1 .262.593.781.781 0 0 1-.255.587c-.163.15-.375.205-.574.219l-.118.004h-3.057c-.233 0-.503-.041-.7-.223a.78.78 0 0 1-.256-.587.763.763 0 0 1 .262-.593c.164-.148.378-.2.577-.213l.118-.004h.985l-.823-2.198H9.841l-.809 2.198h.998c.229 0 .49.042.685.217a.763.763 0 0 1 .262.593.782.782 0 0 1-.256.587c-.162.15-.374.205-.573.219l-.118.004H6.963c-.23 0-.495-.043-.69-.223a.78.78 0 0 1-.256-.587.763.763 0 0 1 .262-.593c.162-.146.37-.2.568-.213l.117-.004h.356l3.326-8.985H8.8c-.188 0-.399-.026-.576-.13l-.109-.078a.798.798 0 0 1-.262-.603.763.763 0 0 1 .262-.593c.162-.146.37-.2.568-.213l.117-.004h4.406Zm-.981 1.958-1.778 4.83h3.612l-1.834-4.83Z",
  // https://materialdesignicons.com/icon/message-image-outline
  "message-image-outline": "M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2M20 16H5.2L4 17.2V4H20V16M5 14L8.5 9.5L11 12.5L14.5 8L19 14",
  // https://materialdesignicons.com/icon/message-text-outline
  "message-text-outline": "M20,2A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H6L2,22V4C2,2.89 2.9,2 4,2H20M4,4V17.17L5.17,16H20V4H4M6,7H18V9H6V7M6,11H15V13H6V11Z",
  // https://materialdesignicons.com/icon/progress-clock
  "progress-clock": "M13,2.03V2.05L13,4.05C17.39,4.59 20.5,8.58 19.96,12.97C19.5,16.61 16.64,19.5 13,19.93V21.93C18.5,21.38 22.5,16.5 21.95,11C21.5,6.25 17.73,2.5 13,2.03M11,2.06C9.05,2.25 7.19,3 5.67,4.26L7.1,5.74C8.22,4.84 9.57,4.26 11,4.06V2.06M4.26,5.67C3,7.19 2.25,9.04 2.05,11H4.05C4.24,9.58 4.8,8.23 5.69,7.1L4.26,5.67M2.06,13C2.26,14.96 3.03,16.81 4.27,18.33L5.69,16.9C4.81,15.77 4.24,14.42 4.06,13H2.06M7.1,18.37L5.67,19.74C7.18,21 9.04,21.79 11,22V20C9.58,19.82 8.23,19.25 7.1,18.37M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z",
  // Custom via Liz
  "select-column": "M20 3a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16ZM4 5v3h4V5H4m16 3V5h-4v3h4M4 10v3h4v-3H4m0 8h4v-3H4v3m16 0v-3h-4v3h4m0-8h-4v3h4v-3Z",
  // Custom via Liz
  "select-row": "M20 3a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16ZM4 5v3h4V5H4m6 0v3h4V5h-4m10 3V5h-4v3h4M4 18h4v-3H4v3m6 0h4v-3h-4v3m10 0v-3h-4v3h4",
  // https://materialdesignicons.com/icon/sort
  "sort": "M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V7H1.5L5,3.5L8.5,7H6V17Z",
  // https://pictogrammers.com/library/mdi/icon/table-lock/
  "table-lock": "M21 16V15.5C21 14.12 19.88 13 18.5 13S16 14.12 16 15.5V16C15.45 16 15 16.45 15 17V21C15 21.55 15.45 22 16 22H21C21.55 22 22 21.55 22 21V17C22 16.45 21.55 16 21 16M20 16H17V15.5C17 14.67 17.67 14 18.5 14S20 14.67 20 15.5V16M13 18H12V14H14.26C14.54 13.21 15.04 12.5 15.69 12H12V8H18V11.05C18.17 11.03 18.33 11 18.5 11C19.03 11 19.53 11.1 20 11.26V6C20 4.89 19.11 4 18 4H4C2.9 4 2 4.89 2 6V18C2 19.11 2.9 20 4 20H13V18M10 18H4V14H10V18M10 12H4V8H10V12Z",
  // https://materialdesignicons.com/icon/table-split-cell
  "table-split-cell": "M19 14H21V20H3V14H5V18H19V14M3 4V10H5V6H19V10H21V4H3M11 11V13H8V15L5 12L8 9V11H11M16 11V9L19 12L16 15V13H13V11H16Z",
  // https://materialdesignicons.com/icon/table-column-plus-before
  "table-column-before-plus": "M13,2A2,2 0 0,0 11,4V20A2,2 0 0,0 13,22H22V2H13M20,10V14H13V10H20M20,16V20H13V16H20M20,4V8H13V4H20M9,11H6V8H4V11H1V13H4V16H6V13H9V11Z",
  // https://materialdesignicons.com/icon/table-column-remove
  "table-column-remove": "M4,2H11A2,2 0 0,1 13,4V20A2,2 0 0,1 11,22H4A2,2 0 0,1 2,20V4A2,2 0 0,1 4,2M4,10V14H11V10H4M4,16V20H11V16H4M4,4V8H11V4H4M17.59,12L15,9.41L16.41,8L19,10.59L21.59,8L23,9.41L20.41,12L23,14.59L21.59,16L19,13.41L16.41,16L15,14.59L17.59,12Z",
  // https://materialdesignicons.com/icon/table
  "table-outlined": "M5,4H19A2,2 0 0,1 21,6V18A2,2 0 0,1 19,20H5A2,2 0 0,1 3,18V6A2,2 0 0,1 5,4M5,8V12H11V8H5M13,8V12H19V8H13M5,14V18H11V14H5M13,14V18H19V14H13Z",
  // https://materialdesignicons.com/icon/table-row-plus-before
  "table-row-before-plus": "M22,14A2,2 0 0,0 20,12H4A2,2 0 0,0 2,14V21H4V19H8V21H10V19H14V21H16V19H20V21H22V14M4,14H8V17H4V14M10,14H14V17H10V14M20,14V17H16V14H20M11,10H13V7H16V5H13V2H11V5H8V7H11V10Z",
  // https://materialdesignicons.com/icon/table-row-remove
  "table-row-remove": "M9.41,13L12,15.59L14.59,13L16,14.41L13.41,17L16,19.59L14.59,21L12,18.41L9.41,21L8,19.59L10.59,17L8,14.41L9.41,13M22,9A2,2 0 0,1 20,11H4A2,2 0 0,1 2,9V6A2,2 0 0,1 4,4H20A2,2 0 0,1 22,6V9M4,9H8V6H4V9M10,9H14V6H10V9M16,9H20V6H16V9Z",
};
export default VECTOR_ICON_PATHS;
