import { isAfter } from "date-fns"
import { TextSelection } from "prosemirror-state"
import React from "react"
import ReactDOM from "react-dom"
import tippy from "tippy.js"

import InlineDetails, { createInlineDetails } from "lib/ample-editor/components/check-list-item/inline-details"
import { createSwipeableRowButtonsWrapper } from "lib/ample-editor/components/check-list-item/swipeable-row-buttons"
import WidgetPopup from "lib/ample-editor/components/check-list-item/widget-popup"
import { createWidgetsBar, updateWidgetsBar } from "lib/ample-editor/components/check-list-item/widgets-bar"
import TaskDetail from "lib/ample-editor/components/task-detail"
import { buildSetTaskDetailQuickAdjustPopup } from "lib/ample-editor/commands/check-list-item-commands"
import {
  deviceSupportsHover,
  isApplePlatform,
  isFirefox,
  modKeyName,
} from "lib/ample-editor/lib/client-info"
import { redoWithRetainEditorFocus, undoWithRetainEditorFocus } from "lib/ample-editor/lib/commands"
import { buildCompleteListItems, buildListItemsHideUntil } from "lib/ample-editor/lib/list-item-commands"
import { listItemClassName, onListItemClick, onListItemTouchStart } from "lib/ample-editor/lib/list-item-view-util"
import { deleteTasks, updateTaskAttributes } from "lib/ample-editor/lib/task-commands"
import {
  getCheckListItemPluginState,
  getExpandedTaskUUID,
  getQuickAdjustPopup,
  setExpandedTaskUUID,
  setQuickAdjustPopup,
} from "lib/ample-editor/plugins/check-list-item-plugin"
import { setOpenLinkPosition } from "lib/ample-editor/plugins/link-plugin"
import scheduleTask from "lib/ample-util/schedule-task"
import {
  bucketFromTaskValue,
  calculateTaskValue,
  DAY_PART,
  flagsObjectFromFlagsString,
  flagsStringFromFlagsObject,
  hourRangeFromDayPart,
  isDoneFromTask,
  isHiddenFromTask,
  prioritizeHourFromDayPart,
  TASK_COMPLETION_MODE,
} from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
const ALT_DOWN_CLASS_NAME = "alt";
const SHIFT_DOWN_CLASS_NAME = "shift";

// --------------------------------------------------------------------------
function getTaskAttribute(view, getPos, attributeName) {
  const { node } = getTaskNode(view, getPos);
  return (node && node.attrs) ? node.attrs[attributeName] : null;
}

// --------------------------------------------------------------------------
function getTaskNode(view, getPos) {
  const { state: { doc, schema } } = view;

  const nodePos = getPos();
  if (typeof(nodePos) === "undefined") return {};

  let node = null;
  try {
    node = doc.nodeAt(nodePos);
  } catch (_error) {
    // RangeError: Position N outside of fragment
  }
  if (!node || node.type !== schema.nodes.check_list_item) return {};

  return { node, nodePos };
}

// --------------------------------------------------------------------------
function isMDCChipElement(element) {
  if (!element) return false;

  while (element) {
    if (element.className && element.className.includes) {
      if (element.className.includes("mdc-chip")) return true;

      // If we make it up to the check list item, we aren't in the TIC, so don't bother going all the way up to the root
      if (element.className.includes("check-list-item")) return false;
    }

    element = element.parentElement;
  }

  return false;
}

// --------------------------------------------------------------------------
function isSwipeableRowButtonElement(element) {
  return element && element.closest && element.closest(".swipeable-row-buttons-wrapper") !== null;
}

// --------------------------------------------------------------------------
// The task-action-button is only added in the embed build, in certain circumstances
function isTaskActionButtonElement(container, element) {
  const taskActionButton = container.querySelector(".task-action-button");

  return taskActionButton && (
    element === taskActionButton || (element.closest && element.closest(".task-action-button") !== null)
  );
}

// --------------------------------------------------------------------------
// Returns true if the given DOM element is anywhere inside a TIC container
function isTICElement(element) {
  if (!element) return false;

  // This happens when the datepicker popup is opened
  if (element.className && element.className.includes && element.className.includes("react-datepicker")) return true;

  while (element) {
    if (element.className && element.className.includes) {
      if (element.className.includes("details-container")) return true;

      // If we make it up to the check list item, we aren't in the TIC, so don't bother going all the way up to the root
      if (element.className.includes("check-list-item")) return false;
    }

    element = element.parentElement;
  }

  return false;
}

// --------------------------------------------------------------------------
function quickAdjustAttributeNameFromPluginState(view, pluginState, uuid) {
  if (!view.editable || !pluginState || !pluginState.quickAdjustPopup) return null;

  if (pluginState.quickAdjustPopup.uuid !== uuid) return null;

  return pluginState.quickAdjustPopup.attributeName;
}

// --------------------------------------------------------------------------
function shouldEnableSwipeableRow() {
  return window.isAmplenoteEmbedEditor && !deviceSupportsHover && !window.disableCheckListItemSwipe;
}

// --------------------------------------------------------------------------
export default class CheckListItemView {
  // We need to track these globally, as a new view might get created when checking off an existing view, in which
  // case we need to know what the initial state of the modifier keys are.
  static _initialAltKeyDown = false;
  static _initialShiftKeyDown = false;

  _altKeyDown = false;
  _checkbox = null;
  _destroySwipeableRowButtonsWrapper = null;
  _detailExpander = null;
  // If the editor had focus when this item was expanded, we'd like to re-focus the editor when it closes (since the
  // Task Detail inputs will steal focus) - but we _don't_ want to re-focus if the editor didn't have focus, to let
  // users check off items on mobile without triggering the keyboard (i.e. without focusing the document)
  _focusEditorOnClose = false;
  // If the editor had focus on the last mousedown event in this view, which we can use to determine if we should
  // re-focus the editor after a focus-stealing click (or leave it un-focused).
  _hadFocusOnMousedown = false;
  _inlineDetails = null;
  _inputContainer = null;
  _mouseOverInputContainer = false;
  _shiftKeyDown = false;
  _styledCheckbox = null;
  _wasExpanded = false;
  _widgetPopupContainer = null;
  _widgetsBar = null;

  // --------------------------------------------------------------------------
  constructor(view, getPos, node) {
    this._getTaskNode = getTaskNode.bind(this, view, getPos);
    this._getTaskAttribute = getTaskAttribute.bind(this, view, getPos);

    this._altKeyDown = CheckListItemView._initialAltKeyDown;
    this._shiftKeyDown = CheckListItemView._initialShiftKeyDown;

    const { props: { disableCheckListItemWidgetsBar, inlineCheckListItemDetailsType } } = view;

    this.destroy = this.destroy.bind(this, view);
    this.stopEvent = this.stopEvent.bind(this, view);
    this.update = this.update.bind(this, view, getPos);

    this._closeTaskDetail = this._closeTaskDetail.bind(this, view);
    this._closeWidgetPopup = this._closeWidgetPopup.bind(this, view);
    this._dismissTask = this._dismissTask.bind(this, view, getPos);
    this._onAttrChange = this._onAttrChange.bind(this, view, getPos);
    this._onCheckboxChange = this._onCheckboxChange.bind(this, view, getPos);
    this._onCrossOutClick = this._onCrossOutClick.bind(this, view, getPos);
    this._onDeleteClick = this._onDeleteClick.bind(this, view);
    this._onDetailExpanderClick = this._onDetailExpanderClick.bind(this, view);
    this._onInputContainerClick = this._onInputContainerClick.bind(this, view, getPos);
    this._onRedo = this._onRedo.bind(this, view);
    this._onUndo = this._onUndo.bind(this, view);

    this.dom = document.createElement("div");
    this.dom.addEventListener("click", onListItemClick.bind(null, view, getPos));
    this.dom.addEventListener("touchstart", onListItemTouchStart.bind(null, view, getPos));

    const rowWrapper = document.createElement("div");
    rowWrapper.className = "row-wrapper";
    this.dom.appendChild(rowWrapper);

    if (shouldEnableSwipeableRow()) {
      this._destroySwipeableRowButtonsWrapper = createSwipeableRowButtonsWrapper(
        rowWrapper,
        // canStartSwipe callback - it makes moving the cursor hard if it swipes the row while moving the cursor in
        // the node, so we'll only allow swipe when the selection isn't in the node
        () => {
          if (!view.hasFocus()) return true;

          const { state: { selection: { from, to } } } = view;
          const { node: { nodeSize }, nodePos: nodeStartPos } = this._getTaskNode();
          const nodeEndPos = nodeStartPos + nodeSize;
          return (from < nodeStartPos && to < nodeStartPos) || (from > nodeEndPos && to > nodeEndPos);
        },
        // completeTask
        taskCompletionMode => {
          this._completeInCompletionMode(taskCompletionMode, view, getPos);
        }
      );
    }

    // Actual checkbox input, which will be hidden
    this._checkbox = document.createElement("input");
    this._checkbox.setAttribute("type", "checkbox");
    this._checkbox.tabIndex = -1;
    this._checkbox.checked = false;
    this._checkbox.disabled = !view.editable;
    this._checkbox.addEventListener("change", this._onCheckboxChange);
    this._checkbox.addEventListener("click", this._onCheckboxClick);

    // Styled checkbox, which the user will actually see
    this._styledCheckbox = document.createElement("span");
    this._styledCheckbox.className = "checkbox material-icons";
    this._styledCheckbox.textContent = "check_box_outline_blank";

    // Wrapper for checkbox and checkbox span - needs to be a label so styled checkbox changes checkbox state
    this._inputContainer = document.createElement("label");
    this._inputContainer.className = "input-container";
    this._inputContainer.contentEditable = "false";
    this._inputContainer.appendChild(this._checkbox);
    this._inputContainer.appendChild(this._styledCheckbox);
    rowWrapper.appendChild(this._inputContainer);

    if (view.editable) {
      this._inputContainer.addEventListener("mouseleave", this._onInputContainerMouseLeave);
      this._inputContainer.addEventListener("mouseover", this._onInputContainerMouseOver);
    }

    if (isFirefox) {
      this._inputContainer.addEventListener("click", this._onInputContainerClick)
    }

    // Container for prosemirror to put the actual node content
    this.contentDOM = document.createElement("div");
    this.contentDOM.className = "content-container";
    rowWrapper.appendChild(this.contentDOM);

    const isDone = isDoneFromTask(node.attrs);

    if (view.editable && !isDone) {
      if (!disableCheckListItemWidgetsBar) {
        const [ widgetsBar, widgetPopupContainer ] = createWidgetsBar(this._onWidgetBarClick.bind(this, view));
        this._widgetsBar = widgetsBar;
        this._widgetPopupContainer = widgetPopupContainer;
        rowWrapper.appendChild(this._widgetsBar);
      }

      document.addEventListener("keydown", this._onDocumentKeyDown);
      document.addEventListener("keyup", this._onDocumentKeyUp);
      document.addEventListener("mouseenter", this._onDocumentMouseEnter);
    }

    const { props: { renderTaskExpander } } = view;
    if (renderTaskExpander) {
      this._detailExpander = document.createElement("div");
      this._detailExpander.className = "task-expander-container";
    } else {
      this._detailExpander = document.createElement("i");
      this._detailExpander.className = "expander material-icons";
      // This is necessary for ProseMirror/browsers to understand how to move the cursor from after the check list item
      // back into the item (e.g. using the left arrow key), informing them that this element is effectively zero-width
      this._detailExpander.innerHTML = "&#65279;";
      this._detailExpander.addEventListener("click", this._onDetailExpanderClick);
    }
    this._detailExpander.contentEditable = "false";
    rowWrapper.appendChild(this._detailExpander);

    if (inlineCheckListItemDetailsType) {
      this._inlineDetails = createInlineDetails(rowWrapper, view);
    }

    // Details container
    const detailsContainer = document.createElement("div");
    detailsContainer.className = "details-container";
    detailsContainer.contentEditable = "false";
    this.dom.appendChild(detailsContainer);

    // Tooltips have historically been annoying (due to their implementations) on mobile
    if (deviceSupportsHover && !isDone) {
      const dismissHotkey = (isApplePlatform
        ? "Cmd+option+space to dismiss"
        : "Ctrl+shift+d to dismiss"
      );

      const tooltipContent = "Click to mark task as complete\n" +
        "Ctrl+space to mark task as complete\n" +
        "Ctrl+shift+space to cross out task\n" +
        dismissHotkey + "\n" +
        "Hold shift while clicking to cross out the task\n" +
        `Hold ${ isApplePlatform ? "Option" : "Alt" } while clicking to dismiss it`;
      tippy(this._inputContainer, {
        content: tooltipContent,
        delay: [ 2000, 100 ],
        theme: "check-list-item-checkbox",
        touch: false,
      });

      if (!renderTaskExpander) {
        tippy(this._detailExpander, { content: `Shortcut: ${ modKeyName }+.`, delay: [ 1000, 150 ], touch: false });
      }
    }

    this.update(node);
  }

  // --------------------------------------------------------------------------
  destroy(_view) {
    document.removeEventListener("keydown", this._onDocumentKeyDown);
    document.removeEventListener("keyup", this._onDocumentKeyUp);
    document.removeEventListener("mouseenter", this._onDocumentMouseEnter);

    if (this.dom) {
      const detailsContainer = this.dom.querySelector(".details-container");
      if (detailsContainer) ReactDOM.unmountComponentAtNode(detailsContainer);

      if (this._inputContainer && this._inputContainer._tippy) this._inputContainer._tippy.destroy();

      Array.from(this.dom.querySelectorAll(".widgets-bar .widget")).forEach(widget => {
        if (widget._tippy) widget._tippy.destroy();
      });
    }

    if (this._destroySwipeableRowButtonsWrapper) {
      this._destroySwipeableRowButtonsWrapper();
      this._destroySwipeableRowButtonsWrapper = null;
    }

    if (this._detailExpander) {
      ReactDOM.unmountComponentAtNode(this._detailExpander);
      if (this._detailExpander._tippy) this._detailExpander._tippy.destroy();
    }

    this._hideWidgetPopup();

    if (this._inlineDetails) {
      ReactDOM.unmountComponentAtNode(this._inlineDetails);
    }

    this._checkbox = null;
    this._inlineDetails = null;
    this._styledCheckbox = null;
    this._widgetPopupContainer = null;

    this.dom.remove();
  }

  // --------------------------------------------------------------------------
  ignoreMutation(event) {
    if (event.type === "selection") {
      // The buttons in the swipeable row will cause a selection mutation when tapped in Safari, moving the
      // selection to the item that was pressed, but that's not desirable
      if (isSwipeableRowButtonElement(event.target)) return true;

      return;
    }

    return !event.target || !this.contentDOM || !this.contentDOM.contains(event.target);
  }

  // --------------------------------------------------------------------------
  stopEvent(view, event) {
    switch (event.type) {
      // Don't prevent drag+drop of files if user happens to hit a checkbox
      case "drop":
      case "dragenter":
      case "dragover":
        return false;

      case "mousedown":
        this._hadFocusOnMousedown = view.hasFocus();

        if (this._shouldStopMouseDownEvent(event)) event.preventDefault();
        // Intentional fall-through

      // eslint-disable-next-line no-fallthrough
      default: {
        // Don't let input events on the checkbox or date-picker propagate up to the ProseMirror view
        if (event.target && (
          event.target.nodeName === "INPUT" ||
          this._isCheckboxElement(event.target) ||
          isTaskActionButtonElement(this.dom, event.target) ||
          isTICElement(event.target)
        )) {
          // Firefox will highlight all text in the TIC when clicking a MDC Chip unless we prevent default here
          if (event.type === "mousedown" && isMDCChipElement(event.target)) {
            event.preventDefault();
          }

          return true;
        }

        // Clicks in the popup can result in the selection changing in the document (bouncing between the selection
        // being _in_ the check list item and the selection being the node itself), but there's also no reason to let
        // any of the events through from the popup
        if (this._widgetPopupContainer && this._widgetPopupContainer.contains(event.target)) return true;
        // Similarly, we don't want a click in the widgets bar (i.e. on a button) to place the selection at the
        // end of the node, as this will be the start of the _next_ check list item on mobile (iOS, specifically)
        if (this._widgetsBar && this._widgetsBar.contains(event.target)) return true;
        // ...and ditto for the expander button
        if (this._detailExpander && event.target === this._detailExpander) return true;

        return false;
      }
    }
  }

  // --------------------------------------------------------------------------
  update(view, getPos, node) {
    if (node.type.name !== "check_list_item") return false;
    if (!this._checkbox) return false; // Have been destroyed (observed in the wild via exception report, 4/2022)

    const { props: { hostApp: { getTaskNote, openNoteLink }, renderTaskExpander } } = view;
    const {
      attrs,
      attrs: {
        dismissedAt,
        due,
        flags,
        startAt,
        uuid,
      },
    } = node;

    const pluginState = getCheckListItemPluginState(view.state);

    const isExpanded = pluginState.expandedTaskUUID && pluginState.expandedTaskUUID === uuid && !renderTaskExpander;
    if (!this._wasExpanded && isExpanded) this._focusEditorOnClose = view.hasFocus();
    this._wasExpanded = isExpanded;

    const isHighlighted = pluginState.highlightedTaskUUID && pluginState.highlightedTaskUUID === uuid;
    const isSelected = view.editable && pluginState.selectedTaskUUID && pluginState.selectedTaskUUID === uuid;
    const quickAdjustAttributeName = quickAdjustAttributeNameFromPluginState(view, pluginState, uuid);

    const dueDate = due ? new Date(due * 1000) : null;
    const isOverdue = dueDate && !isAfter(dueDate, Date.now());

    if (this._widgetsBar) {
      updateWidgetsBar(this._widgetsBar, dueDate, startAt, flags, quickAdjustAttributeName);

      if (quickAdjustAttributeName === null) {
        this._hideWidgetPopup();
      } else {
        this._renderWidgetPopup(view, this._widgetsBar, quickAdjustAttributeName);
      }
    }

    const isDone = isDoneFromTask(attrs);
    this._checkbox.checked = isDone;
    this._checkbox.disabled = !view.editable;
    this._styledCheckbox.textContent = isDone ? "check_box" : "check_box_outline_blank";

    let className = "check-list-item";
    className += ` value-${ bucketFromTaskValue(calculateTaskValue(attrs)) }`;
    if (isExpanded) className += " expanded";
    if (isHighlighted) className += " highlighted";
    if (isOverdue) className += " overdue";
    if (isSelected) className += " selected";
    if (dismissedAt) className += " dismissed";
    if (isHiddenFromTask(attrs)) className += " hidden";
    if (isDone) className += " done";

    className += listItemClassName(view.state, node, getPos);

    this.dom.className = className;

    this.dom.setAttribute("data-uuid", uuid);

    if (this._inlineDetails) {
      const { props: { inlineCheckListItemDetailsType } } = view;

      const note = getTaskNote ? getTaskNote(uuid) : null;

      ReactDOM.render(
        <InlineDetails
          attrs={ attrs }
          note={ note }
          openNoteLink={ openNoteLink }
          type={ inlineCheckListItemDetailsType }
          uuid={ uuid }
        />,
        this._inlineDetails
      );
    }

    const detailsContainer = this.dom.querySelector(".details-container");
    if (detailsContainer) {
      if (isExpanded) {
        ReactDOM.render(
          <TaskDetail
            { ...attrs }

            autoFocus
            close={ this._closeTaskDetail }
            note={ getTaskNote ? getTaskNote(uuid) : null }
            openNoteLink={ openNoteLink }
            onAttrChange={ this._onAttrChange }
            onCrossOutClick={ this._onCrossOutClick }
            onDeleteClick={ this._onDeleteClick }
            onDismissClick={ this._dismissTask }
            readonly={ !view.editable || isDone }
            redo={ this._onRedo }
            undo={ this._onUndo }
          />,
          detailsContainer
        );
      } else {
        ReactDOM.unmountComponentAtNode(detailsContainer);
      }
    }

    if (renderTaskExpander) {
      ReactDOM.render(renderTaskExpander(node), this._detailExpander);
    } else if (this._detailExpander) {
      ReactDOM.unmountComponentAtNode(this._detailExpander);
    }

    return true;
  }

  // --------------------------------------------------------------------------
  _closeTaskDetail(view, { focus = false } = {}) {
    const transaction = setExpandedTaskUUID(view.state.tr, null);

    // Place the cursor at the end of the item that TIC was open for
    const { node, nodePos } = this._getTaskNode();
    if (node && nodePos) {
      transaction.setSelection(TextSelection.near(transaction.doc.resolve(nodePos + node.nodeSize), -1));
    }

    // Make sure the selection is visible
    transaction.scrollIntoView();

    view.dispatch(transaction);
    if (focus || this._focusEditorOnClose) view.focus();
  }

  // --------------------------------------------------------------------------
  _closeWidgetPopup = view => {
    const uuid = this._getTaskAttribute("uuid");
    if (!uuid) return;

    const quickAdjustPopup = getQuickAdjustPopup(view.state);
    if (quickAdjustPopup && quickAdjustPopup.uuid === uuid) {
      buildSetTaskDetailQuickAdjustPopup(null, null)(view.state, view.dispatch);
    }
  };

  // --------------------------------------------------------------------------
  _dismissTask(view, getPos) {
    this._completeInCompletionMode(TASK_COMPLETION_MODE.DISMISS, view, getPos);
  }

  // --------------------------------------------------------------------------
  _completeInCompletionMode(completionMode, view, getPos) {
    const uuid = this._getTaskAttribute("uuid");
    if (!uuid) return;

    const completeListItems = buildCompleteListItems({
      listItemPos: getPos(),
      taskCompletionMode: completionMode,
    });

    if (completeListItems(view.state, view.dispatch)) {
      if (this._focusEditorOnClose) view.focus();
    }
  }

  // --------------------------------------------------------------------------
  _hideWidgetPopup = () => {
    if (this._widgetPopupContainer) {
      ReactDOM.unmountComponentAtNode(this._widgetPopupContainer);
    }
  };

  // --------------------------------------------------------------------------
  _isCheckboxElement = element => {
    if (!this._inputContainer) return false;

    return element === this._inputContainer ||
      // Could be either one of the two `.checkbox` children
      element.parentElement === this._inputContainer;
  };

  // --------------------------------------------------------------------------
  _onAttrChange(view, getPos, newAttrs, closeWidgetPopup = false) {
    const { node } = this._getTaskNode();
    if (!node) return;

    const { attrs: { dueDayPart: dueDayPartWas, uuid } } = node;
    if (!uuid) return;

    if ("dueDayPart" in newAttrs || (dueDayPartWas && "duration" in newAttrs)) {
      const dueDayPart = "dueDayPart" in newAttrs ? newAttrs.dueDayPart : dueDayPartWas;

      const hourRange = hourRangeFromDayPart(dueDayPart);
      if (hourRange) {
        const [ firstHour, lastHour ] = hourRange;

        const { state: { doc } } = view;
        const attrs = { ...node.attrs, ...newAttrs };
        const newDue = scheduleTask(doc.toJSON(), attrs, firstHour, lastHour, prioritizeHourFromDayPart(dueDayPart));
        if (newDue) newAttrs.due = newDue;
      }
    }

    const dispatch = closeWidgetPopup
      ? transaction => {
        const quickAdjustPopup = getQuickAdjustPopup(view.state);
        if (quickAdjustPopup && quickAdjustPopup.uuid === uuid) {
          setQuickAdjustPopup(transaction, null);
        }
        view.dispatch(transaction);
      }
      : view.dispatch;

    const { startAt, ...updatedAttrs } = newAttrs;
    if (startAt) {
      buildListItemsHideUntil({ hideUntil: startAt, listItemPos: getPos() })(view.state, dispatch);
    }

    if (updatedAttrs && Object.keys(updatedAttrs).length) {
      updateTaskAttributes(uuid, updatedAttrs)(view.state, dispatch);
    }
  }

  // --------------------------------------------------------------------------
  _onCheckboxChange(view, getPos, _event) {
    const uuid = this._getTaskAttribute("uuid");
    if (!uuid) return;

    const { dispatch, state } = view;

    const checked = this._checkbox.checked;
    if (checked) {
      let taskCompletionMode = TASK_COMPLETION_MODE.NORMAL;

      if (this._inputContainer.classList.contains(ALT_DOWN_CLASS_NAME)) {
        taskCompletionMode = TASK_COMPLETION_MODE.DISMISS;
      } else if (this._inputContainer.classList.contains(SHIFT_DOWN_CLASS_NAME)) {
        taskCompletionMode = TASK_COMPLETION_MODE.CROSS_OUT;
      }

      buildCompleteListItems({ listItemPos: getPos(), taskCompletionMode })(state, dispatch);
    } else {
      // We only perform this from tasksSchema editors - so this is handled different than it would be in a
      // standard schema document (i.e. by calling `buildRestoreCompletedTask`)
      updateTaskAttributes(uuid, { completedAt: null, crossedOutAt: null, dismissedAt: null })(state, dispatch);
    }

    if (this._hadFocusOnMousedown) view.focus();
  }

  // --------------------------------------------------------------------------
  _onCheckboxClick = event => {
    // On iOS, as of somewhere around iOS 13, Safari will emit a keydown event for the shift key when the keyboard
    // is in upper-case mode, including when auto-capitalizing at the beginning of a line. If we use detection of
    // the document shift key, that results in a click on a checkbox thinking the shift key is down, when it really
    // isn't. The MouseEvent on click has a true setting, so we can use that instead to kick out of shift mode.
    // See https://github.com/ProseMirror/prosemirror/issues/982 for some discussion of general behavior, and note that
    // ProseMirror's own `view.input.shiftKey` doesn't always match our own `SHIFT_DOWN_CLASS_NAME` being present, nor
    // does it match what is on the click event here.
    if (!event.shiftKey && this._inputContainer.classList.contains(SHIFT_DOWN_CLASS_NAME)) {
      this._inputContainer.classList.remove(SHIFT_DOWN_CLASS_NAME);
    }
  };

  // --------------------------------------------------------------------------
  _onCrossOutClick(view, getPos) {
    this._completeInCompletionMode(TASK_COMPLETION_MODE.CROSS_OUT, view, getPos);
  }

  // --------------------------------------------------------------------------
  _onDeleteClick(view) {
    const uuid = this._getTaskAttribute("uuid");
    if (!uuid) return;

    if (deleteTasks([ uuid ])(view.state, view.dispatch)) {
      if (this._focusEditorOnClose) view.focus();
    }
  }

  // --------------------------------------------------------------------------
  _onDetailExpanderClick(view, event) {
    event.preventDefault();

    const { state } = view;

    const uuid = this._getTaskAttribute("uuid");
    if (!uuid) return;

    // When opening TaskDetail, close any RichFootnote popups that may be open
    const expandedTaskUUID = uuid === getExpandedTaskUUID(state) ? null : uuid;

    const transaction = expandedTaskUUID !== null ? setOpenLinkPosition(state.tr, null) : state.tr;
    setExpandedTaskUUID(transaction, expandedTaskUUID);

    if (expandedTaskUUID === null) {
      const { node, nodePos } = this._getTaskNode();
      if (node && nodePos) {
        // Closing the TIC Place the cursor at the end of the item that TaskDetail was open for
        transaction.setSelection(TextSelection.create(transaction.doc, nodePos + node.nodeSize - 2));
      }
    }

    view.dispatch(transaction);
    if (expandedTaskUUID === null && this._focusEditorOnClose) view.focus();
  }

  // --------------------------------------------------------------------------
  _onDocumentKeyDown = event => {
    const { altKey, shiftKey } = event;

    if (altKey !== this._altKeyDown) {
      if (this._mouseOverInputContainer && altKey) {
        this._inputContainer.classList.add(ALT_DOWN_CLASS_NAME);
      }

      this._altKeyDown = altKey;
    }
    CheckListItemView._initialAltKeyDown = altKey;

    if (shiftKey !== this._shiftKeyDown) {
      if (this._mouseOverInputContainer && shiftKey) {
        this._inputContainer.classList.add(SHIFT_DOWN_CLASS_NAME);
      }

      this._shiftKeyDown = shiftKey;
    }
    CheckListItemView._initialShiftKeyDown = shiftKey;
  };

  // --------------------------------------------------------------------------
  _onDocumentKeyUp = event => {
    const { altKey, shiftKey } = event;

    if (altKey !== this._altKeyDown) {
      if (this._mouseOverInputContainer && !altKey) {
        this._inputContainer.classList.remove(ALT_DOWN_CLASS_NAME);
      }

      this._altKeyDown = altKey;
    }
    CheckListItemView._initialAltKeyDown = altKey;

    if (shiftKey !== this._shiftKeyDown) {
      if (this._mouseOverInputContainer && !shiftKey) {
        this._inputContainer.classList.remove(SHIFT_DOWN_CLASS_NAME);
      }

      this._shiftKeyDown = shiftKey;
    }
    CheckListItemView._initialShiftKeyDown = shiftKey;
  };

  // --------------------------------------------------------------------------
  // If user moves mouse out of window but has shift or alt held, we won't get a keyup
  // if they release it in a different window, we'll re-sync the state of the keys when
  // the mouse re-enters the window.
  _onDocumentMouseEnter = event => {
    // When clicking on an element that gets removed from the DOM - e.g. a checkbox for a task - Chrome (and possibly
    // other browsers) emits a document mouseenter with the relatedTarget set to the DOM element that was removed -
    // and it erroneously reports no modifier keys as being pressed
    if (event.relatedTarget === null) {
      this._onDocumentKeyUp(event);
    }
  };

  // --------------------------------------------------------------------------
  // Only necessary on Firefox, where a click event won't fire on a checkbox or styled fake checkbox inside a label if
  // the shift or alt key is held. Instead, we need to handle click on the label itself, rather than relying on the
  // change event of the checkbox.
  // See https://bugzilla.mozilla.org/show_bug.cgi?id=559506
  _onInputContainerClick(view, getPos, event) {
    if (this._checkbox) {
      this._checkbox.checked = !this._checkbox.checked;

      this._onCheckboxChange(event);
    }
  }

  // --------------------------------------------------------------------------
  _onRedo(view) {
    redoWithRetainEditorFocus(view.state, view.dispatch);
  }

  // --------------------------------------------------------------------------
  _onInputContainerMouseLeave = () => {
    this._mouseOverInputContainer = false;

    this._inputContainer.classList.remove(ALT_DOWN_CLASS_NAME);
    this._inputContainer.classList.remove(SHIFT_DOWN_CLASS_NAME);
  };

  // --------------------------------------------------------------------------
  _onInputContainerMouseOver = () => {
    this._mouseOverInputContainer = true;

    if (this._altKeyDown) this._inputContainer.classList.add(ALT_DOWN_CLASS_NAME);
    if (this._shiftKeyDown) this._inputContainer.classList.add(SHIFT_DOWN_CLASS_NAME);
  };

  // --------------------------------------------------------------------------
  _onUndo(view) {
    undoWithRetainEditorFocus(view.state, view.dispatch);
  }

  // --------------------------------------------------------------------------
  _onWidgetBarClick(view, event) {
    if (!this._widgetPopupContainer || this._widgetPopupContainer.contains(event.target)) return;

    event.preventDefault();

    if (!this._widgetsBar) return;

    if (event.target === this._widgetsBar) {
      // On mobile the widgets bar is expanded to the full width of the check-list-item, leaving a fair bit of space
      // to the right of the widgets. To ease touch interaction with imprecise fingers, we'll let any click (/touch) on
      // the empty space of the widgets bar place the selection at the end of the content of the check-list-item

      // Place the cursor at the end of the item that TIC was open for
      const { node, nodePos } = this._getTaskNode();
      if (node && nodePos) {
        const transform = view.state.tr;
        transform.setSelection(TextSelection.near(transform.doc.resolve(nodePos + node.nodeSize), -1));
        view.dispatch(transform);
        view.focus();
      }

      return;
    }

    const { target: widget } = event;

    const attributeName = widget.dataset.attribute;
    if (!attributeName) return;

    const uuid = this._getTaskAttribute("uuid");
    if (!uuid) return;

    // Need to prevent the click handler in the popup itself from triggering from this click
    event.stopImmediatePropagation();

    const quickAdjustPopup = getQuickAdjustPopup(view.state);
    if (quickAdjustPopup && quickAdjustPopup.uuid === uuid && quickAdjustPopup.attributeName === attributeName) {
      buildSetTaskDetailQuickAdjustPopup(null, null)(view.state, view.dispatch);
    } else {
      buildSetTaskDetailQuickAdjustPopup(uuid, attributeName)(view.state, view.dispatch);
    }
  }

  // --------------------------------------------------------------------------
  _renderWidgetPopup = (view, widgetsBar, attributeName) => {
    if (!this._widgetPopupContainer) return;

    ReactDOM.render(
      <WidgetPopup
        attributeName={ attributeName }
        close={ this._closeWidgetPopup }
        dismissTask={ this._dismissTask }
        getAttribute={ this._getTaskAttribute }
        key={ attributeName }
        setAttribute={ this._setAttributeFromWidgetPopup }
      />,
      this._widgetPopupContainer
    );

    // Line up with widget element's right edge
    const widget = widgetsBar.querySelector(".widget.active");
    if (widget) {
      const offset = widget.parentNode.getBoundingClientRect().right - widget.getBoundingClientRect().right;
      this._widgetPopupContainer.setAttribute("style", `right: ${ offset }px;`);
    }

    this._widgetPopupContainer.className = `widget-popup-container ${ attributeName }`;
  };

  // --------------------------------------------------------------------------
  _setAttributeFromWidgetPopup = (attributeName, attributeValue) => {
    const changes = { [attributeName]: attributeValue };

    if (attributeName === "due") {
      const { node } = this._getTaskNode();
      if (node) {
        const { attrs: { dueDayPart, due } } = node;
        if (!dueDayPart && !due) changes.dueDayPart = DAY_PART.MORNING;
      }
    } else if (attributeName === "priority") {
      const { important, urgent } = flagsObjectFromFlagsString(attributeValue);

      delete changes["priority"];

      const { node } = this._getTaskNode();
      if (node) {
        const { attrs: { flags: flagsString } } = node;
        const existingFlags = flagsObjectFromFlagsString(flagsString);

        changes.flags = flagsStringFromFlagsObject({ ...existingFlags, important, urgent });
      } else {
        changes.flags = flagsStringFromFlagsObject({ important, urgent });
      }
    }

    this._onAttrChange(changes, true);
  };

  // --------------------------------------------------------------------------
  // mousedown events on buttons within the view will focus the ProseMirror editor if they
  // are allowed to bubble up. In a number of cases, we only want to focus the editor if it
  // was focused already (where "already" could be a while before the mousedown event, e.g.
  // when the task detail was opened, but this is a mousedown on a button in the task detail).
  // This is mainly intended to make mobile keep the keyboard closed while the user interacts
  // with task buttons.
  _shouldStopMouseDownEvent = event => {
    const { target } = event;
    if (!target) return false;

    if (this._isCheckboxElement(target)) return true;

    if (target === this.dom.querySelector(".expander")) return true;
    if (target === this.dom.querySelector(".expand-collapse-toggle-icon")) return true;

    // TaskDetail bottom-bar buttons

    if (target === this.dom.querySelector(".close-button")) return true;
    if (target === this.dom.querySelector(".close-button > .extra-words")) return true;

    if (target === this.dom.querySelector(".action")) return true;
    if (target === this.dom.querySelector(".action > .extra-words")) return true;

    return isSwipeableRowButtonElement(event.target);
  };
}
