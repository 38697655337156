import { dequal } from "dequal/lite"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { List, ListItem, ListItemGraphic, ListItemText } from "@rmwc/list"
import Tippy from "@tippyjs/react"

import ColorSelectionMenu from "lib/ample-editor/components/color-selection-menu"
import { altKeyName, deviceSupportsHover } from "lib/ample-editor/lib/client-info"
import { isCycleVarColor } from "lib/ample-editor/lib/color-util"
import { preventEventDefault } from "lib/ample-editor/lib/event-util"
import {
  CELL_ALIGN_ICON_ORDER,
  CELL_SELECTION_DIRECTION,
  CELL_SELECTION_SUB_MENU as SUB_MENU,
  CLEAR_BORDER_COMMAND_NAME,
  MIXED_TABLE_SELECTION_VALUE
} from "lib/ample-editor/lib/table/table-constants"
import VECTOR_ICON_PATHS from "lib/ample-editor/lib/vector-icon-paths"

// --------------------------------------------------------------------------
function VectorIcon({ icon, ...options }) {
  return (<svg className="vector-icon" viewBox="0 0 24 24" { ...options }><path d={ VECTOR_ICON_PATHS[icon] }/></svg>);
}

// --------------------------------------------------------------------------
function AlignSubMenu({ align, apply }) {
  const alignOptions = [];
  CELL_ALIGN_ICON_ORDER.forEach(alignment => {
    alignOptions.push(
      <ListItem
        className={ `align-${ alignment }-menu-item selectable-option` }
        data-mixed-selection={ align === MIXED_TABLE_SELECTION_VALUE ? "true" : "" }
        key={ alignment }
        onClick={ apply.bind(null, "align", alignment) }
        onMouseDown={ preventEventDefault }
        selected={ align === alignment }
      >
        <ListItemGraphic icon={ `format_align_${ alignment }` } />
        <ListItemText>Align { alignment }</ListItemText>
      </ListItem>
    );
  });

  return (
    <List className="align-menu">
      { alignOptions }
    </List>
  )
}

// --------------------------------------------------------------------------
function ExpandSelectionSubMenu({ apply }) {
  const expandSelectionOptions = [];
  Object.values(CELL_SELECTION_DIRECTION).forEach(selectionType => {
    expandSelectionOptions.push(
      <ListItem
        className={ `select-${ selectionType }-menu-item selectable-option` }
        key={ selectionType }
        onClick={ apply.bind(null, selectionType) }
        onMouseDown={ preventEventDefault }
      >
        <ListItemGraphic icon={ <VectorIcon icon={ `select-${ selectionType }` } /> } />
        <ListItemText>Select { selectionType }</ListItemText>
      </ListItem>
    );
  });

  return (
    <List className="expand-selection-menu">
      { expandSelectionOptions }
    </List>
  )
}

// --------------------------------------------------------------------------
function BorderSubMenu({ apply, borderBottom, borderLeft, borderRight, borderTop }) {
  const borders = { borderBottom, borderLeft, borderRight, borderTop };
  const borderOptions = [];
  Object.keys(borders).forEach(borderKey => {
    const borderDirection = borderKey.replace("border", "").toLowerCase();
    borderOptions.push(
      <Tippy
        content={ <React.Fragment><div>Toggle { borderDirection } border</div><div className="light-text">{ deviceSupportsHover ? `Hold "${ altKeyName }" to apply border only to the outer boundary of selection` : "" }</div></React.Fragment> }
        delay={ 500 }
        key={ borderKey }
        touch={ false }
      >
        <ListItem
          data-mixed-selection={ borders[borderKey] === MIXED_TABLE_SELECTION_VALUE ? "true" : "" }
          onClick={ event => apply(borderKey, !borders[borderKey], event.altKey) }
          onMouseDown={ preventEventDefault }
          selected={ borders[borderKey] }
        >
          <ListItemGraphic icon={ `border_${ borderDirection }` } />
          <ListItemText>Border { borderDirection }</ListItemText>
        </ListItem>
      </Tippy>
    );
  });

  return (
    <List className="border-menu-options">
      { borderOptions }
      <ListItem
        onClick={ () => apply(CLEAR_BORDER_COMMAND_NAME, true, false) }
        onMouseDown={ preventEventDefault }
      >
        <ListItemGraphic icon="border_clear" />
        <ListItemText>Clear borders</ListItemText>
      </ListItem>
    </List>
  )
}

// --------------------------------------------------------------------------
export default function CellSelectionMenu({
  align,
  apply,
  backgroundColor,
  borderBottom,
  borderLeft,
  borderRight,
  borderTop,
  clearFormatting,
  dismiss,
  expandSelection,
  renderedExtents,
  resizeColumnToContentWidth,
  submenuPositionAbove,
  textColor,
}) {
  const [ subMenuOpen, setSubMenuOpen ] = useState(null);
  const [ renderedSelectionRect, setRenderedSelectionRect ] = useState(renderedExtents);

  // Ensure that subMenu is closed iff the range being rendered has changed
  useEffect(() => {
    if (!dequal(renderedExtents, renderedSelectionRect)) {
      setSubMenuOpen(null);
      setRenderedSelectionRect(renderedExtents);
    }
  }, [ renderedExtents, renderedSelectionRect ]);

  // --------------------------------------------------------------------------
  const expandSelectionAndCloseMenu = useCallback(selectionDirection => {
    setSubMenuOpen(null);
    expandSelection(selectionDirection);
  }, [ expandSelection ]);

  // --------------------------------------------------------------------------
  const applyAndCloseSubMenu = useCallback((name, value) => {
    setSubMenuOpen(null);
    apply(name, value);
  }, [ apply ]);

  // --------------------------------------------------------------------------
  const renderColorOption = (optionClass, color, colorIcon) => {
    const dataColor = isCycleVarColor(color) ? color.replace("cycle-color-", "") : "";
    const colorStyle = dataColor ? {} : { color };
    const subMenu = optionClass === "text-color" ? SUB_MENU.TEXT_COLOR : SUB_MENU.BACKGROUND_COLOR;
    return (
      <div
        className={ `menu-line selectable-option color-option ${ subMenuOpen === optionClass ? "is-selected" : "" }` }
        key={ `option-${ optionClass }` }
        onClick={ () => setSubMenuOpen(prevSubMenu => prevSubMenu === subMenu ? null : subMenu) }
        onMouseDown={ preventEventDefault }
      >
        <div className="material-icons" data-text-color={ dataColor } style={ colorStyle }>{ colorIcon }</div>
      </div>
    );
  }

  // --------------------------------------------------------------------------
  // Deduce what submenu to render, if any
  let applySelectedColor, cellAttr, currentColor, popoutDom;
  switch (subMenuOpen) {
    case SUB_MENU.ALIGN:
      popoutDom = (
        <AlignSubMenu align={ align } apply={ applyAndCloseSubMenu } />
      );
      break;
    case SUB_MENU.BACKGROUND_COLOR:
    case SUB_MENU.TEXT_COLOR:
      cellAttr = subMenuOpen === SUB_MENU.BACKGROUND_COLOR ? "backgroundColor" : "color";
      currentColor = subMenuOpen === SUB_MENU.BACKGROUND_COLOR ? backgroundColor : textColor;
      applySelectedColor = selectedColorString => applyAndCloseSubMenu(cellAttr, selectedColorString);
      popoutDom = (
        <ColorSelectionMenu
          allowCustom
          applySelectedColor={ applySelectedColor }
          selectedColor={ (typeof currentColor === "string") ? currentColor.replace("#", "") : "" }
          noFillButtonAvailable
          noGradientColorsAvailable={ subMenuOpen === SUB_MENU.TEXT_COLOR }
        />
      );
      break;
    case SUB_MENU.BORDER:
      popoutDom = (
        <BorderSubMenu apply={ apply } borderBottom={ borderBottom } borderLeft={ borderLeft } borderRight={ borderRight } borderTop={ borderTop } />
      )
      break;
    case SUB_MENU.EXPAND_SELECTION:
      popoutDom = (
        <ExpandSelectionSubMenu apply={ expandSelectionAndCloseMenu } />
      );
      break;
    default:
      popoutDom = null;
  }

  let submenuContent;
  if (popoutDom) {
    const classList = [
      `sub-menu-${ subMenuOpen }`,
      `sub-menu${ popoutDom ? " visible" : "" }`,
      submenuPositionAbove ? "pop-above" : ""
    ];
    submenuContent = (
      <div className={ classList.join(" ") }>
        { popoutDom }
      </div>
    );
  }

  // --------------------------------------------------------------------------
  return (
    <div className="cell-selection-menu">
      <div className="cell-selection-options">
        <div className="menu-line">
          <div
            className={ `expand-selection-menu-item selectable-option${ subMenuOpen === SUB_MENU.EXPAND_SELECTION ? " is-selected" : "" }` }
            onClick={ () => setSubMenuOpen(prevSubMenu => prevSubMenu === SUB_MENU.EXPAND_SELECTION ? null : SUB_MENU.EXPAND_SELECTION) }
            onMouseDown={ preventEventDefault }
          >
            <VectorIcon icon="select-column" />
          </div>
          <div className="border-divider">&nbsp;</div>
          <div className={ `align-items-menu-item selectable-option${ subMenuOpen === SUB_MENU.ALIGN ? " is-selected" : "" }` }
           onClick={ () => setSubMenuOpen(prevSubMenu => prevSubMenu === SUB_MENU.ALIGN ? null : SUB_MENU.ALIGN) }
           onMouseDown={ preventEventDefault }
          >
            <div className="material-icons">{ `format_align_${ align && align !== MIXED_TABLE_SELECTION_VALUE ? align : "left" }` }</div>
          </div>
          <div
            className={ `border-menu-item material-icons selectable-option selectable-icon ${ subMenuOpen === SUB_MENU.BORDER ? "is-selected" : "" }` }
            onClick={ () => setSubMenuOpen(prevSubMenu => prevSubMenu === SUB_MENU.BORDER ? null : SUB_MENU.BORDER) }
            onMouseDown={ preventEventDefault }
          >
            border_clear
          </div>
          <Tippy
            content="Resize column width so that the widest cell in selection range can be displayed with minimal wrapping"
            delay={ 1000 }
            touch={ [ "hold", 1000 ] }
          >
            <div className="vector-icon-container">
              <VectorIcon
                icon="arrow-expand-horizontal"
                className="column-fit-menu-item selectable-icon vector-icon"
                onClick={ resizeColumnToContentWidth }
                onMouseDown={ preventEventDefault }
              />
            </div>
          </Tippy>
          <Tippy
            content={ clearFormatting ? "Clear cell formatting" : "Selection has no formatting to clear" }
            delay={ 1000 }
            touch={ [ "hold", 1000 ] }
          >
            <div
              className={ `clear-formatting-icon material-icons selectable-icon${ clearFormatting ? "" : " disabled" }` }
              onClick={ clearFormatting }
              onMouseDown={ preventEventDefault }
            >format_color_reset</div>
          </Tippy>
          <div className="border-divider">&nbsp;</div>
          { renderColorOption("text-color", textColor, "format_color_text") }
          { renderColorOption("background-color", backgroundColor, "format_color_fill") }
          <Tippy
            content={ `Dismiss cell selection menu. You can also press the Escape key or ${ deviceSupportsHover ? "click" : "tap" } the cell again` }
            delay={ 1000 }
            touch={ [ "hold", 1000 ] }
          >
            <div
              className="dismiss-icon material-icons"
              onClick={ dismiss }
              onMouseDown={ preventEventDefault }
            >close</div>
          </Tippy>
        </div>
      </div>
      { submenuContent }
    </div>
  );
}

// --------------------------------------------------------------------------
// Most properties will have a number passed (e.g., `MIXED_TABLE_SELECTION_VALUE`) if there are a mixture of values across
// the selection range
CellSelectionMenu.propTypes = {
  align: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  apply: PropTypes.func.isRequired,
  backgroundColor: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  borderBottom: PropTypes.oneOfType([ PropTypes.bool, PropTypes.number ]),
  borderLeft: PropTypes.oneOfType([ PropTypes.bool, PropTypes.number ]),
  borderRight: PropTypes.oneOfType([ PropTypes.bool, PropTypes.number ]),
  borderTop: PropTypes.oneOfType([ PropTypes.bool, PropTypes.number ]),
  clearFormatting: PropTypes.func,
  dismiss: PropTypes.func.isRequired,
  expandSelection: PropTypes.func.isRequired,
  renderedExtents: PropTypes.object.isRequired,
  resizeColumnToContentWidth: PropTypes.func.isRequired,
  submenuPositionAbove: PropTypes.bool,
  textColor: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
};
